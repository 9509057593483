<!--suppress JSUnresolvedVariable, DuplicatedCode-->
<template>
  <div class="page dashboard mb-4 pb-4">
    <app-header
      child-header-class="container-fluid"
      :child-header-navigation="dashboardSubNavigation"
      is-child-header-left-actions
    >
      <template v-slot:right>
        <div
          class="page-settings--menu"
        >
          <button
            class="button button--secondary text-size-xs size-sm button--icon"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            @mouseover.prevent="showReportDropDown = true"
            @mouseleave="closeReportDropDown"
          >
            <icon-container
              name="downloadfull"
              class="icon-svg"
              width="13"
              height="14"
              :is-icon-class="false"
            >
              <icon-download />
            </icon-container>
            {{ $t("reports") }}
            <icon-container
              name="down"
              view-box="0 0 11 8"
              class="icon-dropdown"
              color="#fffff"
              :is-icon-class="false"
            >
              <icon-down />
            </icon-container>
          </button>
          <transition name="dropdown">
            <div
              class="page-settings__dropdown-menu"
              @mouseleave="showReportDropDown = false"
              @mouseover.prevent="holdBulkMenu"
              :class="{ active: showReportDropDown }"
              v-if="showReportDropDown"
            >
              <ul class="page-settings__dropdown-menu-nav">
                <li class="page-settings__dropdown-menu-item">
                  <a
                    href="#"
                    class="page-settings__dropdown-menu-link"
                    :title="$t('group_activity_report')"
                    @click="reportDownload('groupactivity')"
                  >
                    <icon-container
                      name="flying"
                      width="19"
                      height="12"
                    >
                      <icon-flying />
                    </icon-container>
                    {{ $t('group_activity_report') }}
                  </a>
                </li>
                <li class="page-settings__dropdown-menu-item">
                  <a
                    href="#"
                    class="page-settings__dropdown-menu-link"
                    :title="$t('group_journey_report')"
                    @click="reportDownload('groupjourney')"
                  >
                    <icon-container
                      name="flying"
                      width="19"
                      height="12"
                    >
                      <icon-flying />
                    </icon-container>
                    {{ $t('group_journey_report') }}
                  </a>
                </li>
              </ul>
            </div>
          </transition>
        </div>
      </template>
    </app-header>
    <div class="container-fluid">
      <div class="usergroup-overview--title">
        <a @click="$router.go(-1)">
          <icon-container
            :width="40"
            :height="18"
            name="Go Back"
            color="#727d92"
          >
            <icon-goback />
          </icon-container>
        </a><small>{{ $route.params.userGroupName }} {{ $t('usergroup_overview') }}</small>
      </div>
      <div class="row dboard-top-row dboard-top-main-row">
        <!--region left-->
        <div
          class="col dboard-top-box"
          id="dboard-top-box-left"
        >
          <div class="col dboard-bg-white">
            <div style="width:20px;">
              <span
                class="vtooltip info-icon-left"
                v-tooltip.right="$t('info_general_completion')"
              >
                <icon-container
                  name="infocircle"
                  view-box="0 0 512 512"
                  :is-icon-class="false"
                >
                  <icon-infocircle />
                </icon-container>
              </span>
            </div>
            <h5>{{ $t("completion_rate").toUpperCase() }}</h5>
            <div class="dboard-top-box-inside dboard-top-box-inside-inner">
              <div class="dboard-box-vertical">
                <div class="row dboard-top-row">
                  <div class="col d-flex justify-content-center">
                    <span class="percent">{{
                      $t("penetration_rate", { rate: this.completionRate })
                    }}</span>
                    <span
                      v-if="completionRateArrow === 'arrow-up'"
                      class="percent d-flex align-items-center"
                    >
                      <span
                        id="arrowup"
                      >
                        <icon-container
                          name="arrowup"
                          :width="25"
                          :height="25"
                          color="#2dcf85"
                          view-box="0 0 448 512"
                          :is-icon-class="false"
                        >
                          <icon-arrowup />
                        </icon-container>
                      </span>
                    </span>
                    <span
                      v-else-if="completionRateArrow === 'arrow-down'"
                      class="percent d-flex align-items-center"
                    >
                      <span
                        id="arrowdown"
                      >
                        <icon-container
                          name="arrowdown"
                          :width="25"
                          :height="25"
                          color="#ea0505"
                          view-box="0 0 448 512"
                          :is-icon-class="false"
                        >
                          <icon-arrowdown />
                        </icon-container>
                      </span>
                    </span>
                    <span
                      v-else
                      class="percent d-flex align-items-center"
                    ><span
                      id="dash"
                    >
                      <icon-container
                        name="arrowhorizontal"
                        :width="25"
                        :height="25"
                        color="#727D92"
                        view-box="0 0 512 512"
                        :is-icon-class="false"
                      >
                        <icon-arrowhorizontal />
                      </icon-container>
                    </span>
                    </span>
                  </div>
                </div>
                <div
                  class="row"
                  style="margin-top:15px"
                >
                  <p v-if="completionRateArrow === 'arrow-up'">
                    {{
                      $t("completion_rate_up_sub_msg", {
                        rate: this.completionRateCompareToPrevPercent
                      })
                    }}
                  </p>
                  <p v-else-if="completionRateArrow === 'arrow-down'">
                    {{
                      $t("completion_rate_down_sub_msg", {
                        rate: this.completionRateCompareToPrevPercent
                      })
                    }}
                  </p>
                  <p v-else>
                    {{
                      $t("completion_rate_none_sub_msg", {
                        rate: this.completionRateCompareToPrevPercent
                      })
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--endregion-->
        <!--region right-->
        <div
          class="col dboard-top-box"
          id="dboard-top-box-right"
        >
          <div class="col dboard-bg-white">
            <h5>{{ $t("actionable_analytics").toUpperCase() }}</h5>
            <div class="dboard-top-box-inside dboard-top-box-inside-inner">
              <!--region left-->
              <div id="dboard-top-box-right-left">
                <div class="dboard-top-row">
                  <div class="col-6 text-left">
                    <a
                      href="javascript:void(0)"
                      v-tooltip.right="$t('info_notification_enabled')"
                      class="info-icon-inside d-inline-flex"
                    >
                      <h6>{{ $t("notification_enabled") }}</h6>
                      <icon-container
                        name="infocircle"
                        view-box="0 0 512 512"
                        :is-icon-class="false"
                      >
                        <icon-infocircle />
                      </icon-container>
                    </a>
                  </div>
                  <div class="col-6">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        :style="
                          notificationEnabledPercent === 0
                            ? 'width:' +
                              notificationEnabledPercent +
                              '%;background-color:transparent;color:#000'
                            : 'width:' +
                              notificationEnabledPercent +
                              '%;color:#000'
                        "
                        :aria-valuenow="notificationEnabledPercent"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {{
                          "‎‎‎‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎" +
                            notificationEnabledPercent +
                            "%"
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="dboard-top-row">
                  <div class="col-6 text-left">
                    <a
                      href="javascript:void(0)"
                      v-tooltip.right="$t('info_mobile_download_penetration')"
                      class="info-icon-inside d-inline-flex"
                    >
                      <h6>{{ $t("mobile_download_penetration") }}</h6>
                      <icon-container
                        name="infocircle"
                        view-box="0 0 512 512"
                        :is-icon-class="false"
                      >
                        <icon-infocircle />
                      </icon-container>
                    </a>
                  </div>
                  <div class="col-6">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        :style="
                          mobileDownloadPenetrationPercent === 0
                            ? 'width:' +
                              mobileDownloadPenetrationPercent +
                              '%;background-color:transparent;color:#000'
                            : 'width:' +
                              mobileDownloadPenetrationPercent +
                              '%;color:#000'
                        "
                        :aria-valuenow="mobileDownloadPenetrationPercent"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {{
                          "‎‎‎‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎" +
                            mobileDownloadPenetrationPercent +
                            "%"
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="dboard-top-row">
                  <div class="col-6 text-left">
                    <a
                      href="javascript:void(0)"
                      v-tooltip.right="$t('info_active_users')"
                      class="info-icon-inside d-inline-flex"
                    >
                      <h6>{{ $t("active_users") }}</h6>
                      <icon-container
                        name="infocircle"
                        view-box="0 0 512 512"
                        :is-icon-class="false"
                      >
                        <icon-infocircle />
                      </icon-container>
                    </a>
                  </div>
                  <div class="col-6">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        :style="
                          activeUsersPercent === 0
                            ? 'width:' +
                              activeUsersPercent +
                              '%;background-color:transparent;color:#000'
                            : 'width:' + activeUsersPercent + '%;color:#000'
                        "
                        :aria-valuenow="activeUsersPercent"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {{ "‎‎‎‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎" + activeUsersPercent + "%" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--endregion-->
              <!--region right-->
              <div id="dboard-top-box-right-right">
                <div class="dboard-top-row">
                  <div
                    class="col"
                    id="download-excel-button"
                  >
                    <a
                      type="button"
                      class="button button--grey"
                      @click="reportDownload('disengaged')"
                    >
                      <icon-container
                        name="flying"
                        class="mx-2"
                        width="19"
                        height="12"
                        :is-icon-class="true"
                      >
                        <icon-flying />
                      </icon-container>

                      {{ $t("export_to_excel") }}
                    </a>
                    <p>{{ $t("download_excel_sub_msg") }}</p>
                  </div>
                </div>
              </div>
              <!--endregion-->
            </div>
          </div>
        </div>
        <!--endregion-->
      </div>
      <!--usage graphic-->
      <div class="row dboard-top-row dboard-top-main-row">
        <!--region left-->
        <div class="col-12 col-md-6 dboard-top-box mb-4">
          <div class="chart_holder">
            <div style="width:20px;">
              <span
                class="vtooltip info-icon-left"
                v-tooltip.right="$t('info_total_item_assigned')"
              >
                <icon-container
                  name="infocircle"
                  view-box="0 0 512 512"
                  :is-icon-class="false"
                >
                  <icon-infocircle />
                </icon-container>
              </span>
            </div>
            <h5>{{ $t("total_item_assigned").toUpperCase() }}</h5>
            <highcharts
              class="chart"
              :options="totalItemAssignedCharts"
              :update-args="updateArgs"
            />
          </div>
        </div>
        <!--endregion-->
        <!--region right-->
        <div class="col-12 col-md-6 dboard-top-box mb-4">
          <div class="chart_holder">
            <div style="width:20px;">
              <span
                class="vtooltip info-icon-left"
                v-tooltip.right="$t('info_completed_item_count')"
              >
                <icon-container
                  name="infocircle"
                  view-box="0 0 512 512"
                  :is-icon-class="false"
                >
                  <icon-infocircle />
                </icon-container>
              </span>
            </div>
            <h5>{{ this.$t("completed_item_count").toUpperCase() }}</h5>
            <highcharts
              class="chart"
              :options="completedItemCountCharts"
              :update-args="updateArgs"
            />
          </div>
        </div>
        <!--endregion-->
      </div>
      <!-- usage graphic end-->
      <!--user count graphic-->
      <div class="row dboard-top-row dboard-top-main-row">
        <div class="col-12 dboard-top-box">
          <div class="chart_holder">
            <div style="width:20px;">
              <span
                class="vtooltip info-icon-left"
                v-tooltip.right="{ content: $t('info_user_count_graphic') }"
              >
                <icon-container
                  name="infocircle"
                  view-box="0 0 512 512"
                  :is-icon-class="false"
                >
                  <icon-infocircle />
                </icon-container>
              </span>
            </div>
            <h5>{{ $t("user_count_graphic").toUpperCase() }}</h5>
            <highcharts
              class="chart"
              :options="userCountChartOption"
              :update-args="updateArgs"
            />
          </div>
        </div>
      </div>
      <!-- usercount end-->
      <!--  average time spent weekly and response rate starts here -->
      <div class="row dboard-top-row dboard-top-main-row">
        <!--region left-->
        <div class="col-12 col-md-6 dboard-top-box mb-4">
          <div class="chart_holder">
            <div style="width:20px;">
              <span
                class="vtooltip info-icon-left"
                v-tooltip.right="$t('info_average_time_spent_weekly')"
              >
                <icon-container
                  name="infocircle"
                  view-box="0 0 512 512"
                  :is-icon-class="false"
                >
                  <icon-infocircle />
                </icon-container>
              </span>
            </div>
            <h5>{{ this.$t("average_time_spent_weekly").toUpperCase() }}</h5>
            <highcharts
              class="chart"
              :options="averageTimeSpentRatioCharts"
              :update-args="updateArgs"
            />
          </div>
        </div>
        <!--endregion-->
        <!--region rigth-->
        <div class="col-12 col-md-6 dboard-top-box mb-4">
          <div class="chart_holder">
            <div style="width:20px;">
              <span
                class="vtooltip info-icon-left"
                v-tooltip.right="$t('info_response_rate')"
              >
                <icon-container
                  name="infocircle"
                  view-box="0 0 512 512"
                  :is-icon-class="false"
                >
                  <icon-infocircle />
                </icon-container>
              </span>
            </div>
            <h5>{{ $t("response_rate").toUpperCase() }}</h5>
            <highcharts
              class="chart"
              :options="responseRatioCharts"
              :update-args="updateArgs"
            />
          </div>
        </div>
        <!--endregion-->
      </div>
      <!-- average time spent weekly and response rate ends here -->
      <div class="row dboard-top-row dboard-top-main-row">
        <div class="col-12 dboard-top-box mb-4">
          <div class="chart_holder">
            <div style="width:20px;">
              <span
                class="vtooltip info-icon-left"
                v-tooltip.right="$t('info_content_average_rating')"
              >
                <icon-container
                  name="infocircle"
                  view-box="0 0 512 512"
                  :is-icon-class="false"
                >
                  <icon-infocircle />
                </icon-container>
              </span>
            </div>
            <h5>{{ this.$t("content_average_rating").toUpperCase() }}</h5>
            <table class="table">
              <thead class="thead-light">
                <tr>
                  <th
                    style="width: 40%;"
                    scope="col"
                  >
                    {{ $t("content_name") }}
                  </th>
                  <th
                    class="text-center"
                    style="width: 20%;"
                    scope="col"
                  >
                    {{ $t("avg_rating") }}
                  </th>
                  <th
                    class="text-center"
                    style="width: 20%;"
                    scope="col"
                  >
                    {{ $t("vote_count") }}
                  </th>
                  <th
                    class="text-center"
                    style="width: 20%;"
                    scope="col"
                  >
                    {{ $t("content_type") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in averageRatingsTable"
                  :key="index"
                >
                  <td
                    class="preview-item"
                    @click="previewContentItem(item.id)"
                  >
                    {{ index + 1 + ". " + item.name }}
                  </td>
                  <td class="text-center">
                    {{
                      $t("rate_info", {
                        rate: Math.ceil((item.avg_rating * 100) / 3)
                      })
                    }}
                  </td>
                  <td class="text-center">
                    {{ item.vote_count }}
                  </td>
                  <td class="text-center">
                    {{ $helpers.getSubTypeName(item.content_type) }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td
                    colspan="4"
                    class="text-center"
                  >
                    <router-link
                      :to="{
                        name: 'AverageRatings'
                      }"
                    >
                      {{ $t("all_list") }}
                    </router-link>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <!-- ends here -->
    </div>
  </div>
</template>
<script>
/* eslint-disable no-restricted-globals, no-undef */
import Highcharts from 'highcharts';
import moment from 'moment';
import IconContainer from '@/components/elements/Icon.vue';
import IconInfoCircle from '@/components/icons/InfoCircle.vue';
import IconDownload from '@/components/icons/Download.vue';
import IconArrowUp from '@/components/icons/ArrowUp.vue';
import IconArrowDown from '@/components/icons/ArrowDown.vue';
import IconArrowHorizontal from '@/components/icons/ArrowHorizontal.vue';
import IconFlying from '@/components/icons/Flying.vue';
import IconGoBack from '@/components/icons/GoBack.vue';
import IconDown from '@/components/icons/Down.vue';
import API from '@/services/';
import {
  API_EXPORT_DISENGAGED_USERS, API_REPORTS_GROUP_HISTOGRAM, API_REPORTS_GROUP_USAGE_SUMMARY,
  API_REPORTS_GROUP_RATINGS_DATATABLE, API_EXPORT_GROUP_JOURNEY, API_EXPORT_GROUP_ACTIVITY,
} from '@/helpers/config';
import AppHeader from '@/components/AppHeader.vue';

export default {
  name: 'DashboardOverview',
  components: {
    'app-header': AppHeader,
    'icon-container': IconContainer,
    'icon-infocircle': IconInfoCircle,
    'icon-download': IconDownload,
    'icon-arrowup': IconArrowUp,
    'icon-arrowdown': IconArrowDown,
    'icon-arrowhorizontal': IconArrowHorizontal,
    'icon-goback': IconGoBack,
    'icon-down': IconDown,
    'icon-flying': IconFlying,
  },
  data() {
    return {
      notificationEnabledPercent: 0,
      mobileDownloadPenetrationPercent: 0,
      activeUsersPercent: 0,
      completionRate: 0,
      completionRateIncreasing: 0,
      showReportDropDown: false,
      completionRateArrow: '',
      completionRateCompareToPrevPercent: 0,
      averageRatingsTable: [],
      updateArgs: [true, true, { duration: 2500 }],
      completedItemCountCharts: {
        title: false,
        credits: {
          enabled: false,
        },
        chart: {
          type: 'column',
          borderRadiusTopLeft: 15,
          borderRadiusTopRight: 15,
        },
        xAxis: {
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: '',
          },
        },
        legend: false,

        tooltip: {
          animation: true,
          followPointer: true,
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="padding:5px">{series.name}: </td>'
      + '<td style="padding:5px"><b>{point.y:,.0f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true,
          style: {
            color: '#FFF',
          },
          backgroundColor: {
            linearGradient: [0, 0, 200, 0],
            stops: [
              [0, '#f27299'],
              [1, '#254ffb'],
            ],
          },
          borderWidth: 0,
          borderRadius: 15,
          shadow: false,
        },

        plotOptions: {
          column: {
            pointPadding: 0.1,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 15,
            borderRadiusTopRight: 15,
          },
        },
        series: [],
      },
      totalItemAssignedCharts: {
        title: false,
        credits: {
          enabled: false,
        },
        chart: {
          type: 'column',
          borderRadiusTopLeft: 15,
          borderRadiusTopRight: 15,
        },
        xAxis: {
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: '',
          },
        },
        legend: false,

        tooltip: {
          animation: true,
          followPointer: true,
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="padding:5px">{series.name}: </td>'
      + '<td style="padding:5px"><b>{point.y:,.0f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true,
          style: {
            color: '#FFF',
          },
          backgroundColor: {
            linearGradient: [0, 0, 200, 0],
            stops: [
              [0, '#f27299'],
              [1, '#254ffb'],
            ],
          },
          borderWidth: 0,
          borderRadius: 15,
          shadow: false,
        },

        plotOptions: {
          column: {
            pointPadding: 0.1,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 15,
            borderRadiusTopRight: 15,
          },
        },
        series: [],
      },
      userCountChartOption: {
        title: false,
        credits: {
          enabled: false,
        },
        chart: {
          type: 'column',
        },
        xAxis: {
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: '',
          },
        },
        legend: {
          align: 'center',
          verticalAlign: 'top',
        },

        tooltip: {
          animation: true,
          followPointer: true,
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="padding:5px">{series.name}: </td>'
      + '<td style="padding:5px"><b>{point.y:,.0f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true,
          style: {
            color: '#FFF',
          },
          backgroundColor: {
            linearGradient: [0, 0, 200, 0],
            stops: [
              [0, '#f27299'],
              [1, '#254ffb'],
            ],
          },
          borderWidth: 0,
          borderRadius: 15,
          shadow: false,
        },

        plotOptions: {
          column: {
            pointPadding: 0.1,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 15,
            borderRadiusTopRight: 15,
          },
        },
        series: [],
      },
      averageTimeSpentRatioCharts: {
        title: false,
        chart: {
          type: 'areaspline',
        },
        xAxis: {
          title: false,
          categories: '',
          labels: {
            style: {
              fontFamily: 'Work Sans, sans-serif',
              fontSize: '14px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: '#727D92',
            },
          },
        },
        yAxis: {
          type: 'datetime',
          title: false,
          labels: {
            style: {
              fontFamily: 'Work Sans, sans-serif',
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#727D92',
            },

            formatter() {
              let ret = Highcharts.dateFormat('%H:%M', this.value);
              if (/23:00/.test(ret)) {
                ret = '23:59';
              }
              return ret;
            },
          },
        },
        tooltip: {
          outside: true,
          shared: true,
          style: {
            fontFamily: 'Work Sans, sans-serif',
            fontSize: '14px',
            color: '#727D92',
          },
          formatter() {
            return `${this.y}` === '0' ? `${this.x} - 0` : `${this.x} - ${Math.trunc(moment.duration(this.y).asHours())}:${moment.duration(this.y).minutes()}`;
          },
        },
        plotOptions: {
          areaspline: {
            fillOpacity: 0.15,
            color: '#4355FB',
            dataLabels: {
              enabled: false,
              color: '#4355FB',
            },
            series: {
              connectNulls: true,
            },
            marker: {
              enabled: false,
              symbol: 'circle',
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
          },
        },
        series: [{
          showInLegend: false,
          data: '',
          lineColor: '#4355FB',
          lineWidth: 5,
          cursor: 'pointer',
        }],
        credits: {
          enabled: false,
        },
      },

      responseRatioCharts: {
        title: false,
        chart: {
          type: 'areaspline',
        },
        xAxis: {
          title: false,
          categories: '',
          labels: {
            style: {
              fontFamily: 'Work Sans, sans-serif',
              fontSize: '14px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: '#727D92',
            },
          },
        },
        yAxis: {
          min: 0,
          max: 100,
          title: false,
          labels: {
            style: {
              fontFamily: 'Work Sans, sans-serif',
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#727D92',
            },
            formatter() {
              return `${this.value}` === '0' ? '0' : `%${this.value}`;
            },
          },
        },
        tooltip: {
          outside: true,
          shared: true,
          style: {
            fontFamily: 'Work Sans, sans-serif',
            fontSize: '14px',
            color: '#727D92',
          },
          formatter() {
            return `${this.x}: %${this.y}`;
          },
        },
        plotOptions: {
          areaspline: {
            fillOpacity: 0.15,
            color: '#4355FB',
            dataLabels: {
              enabled: false,
              color: '#4355FB',
            },
            series: {
              connectNulls: true,
            },
            marker: {
              enabled: false,
              symbol: 'circle',
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
          },
        },
        series: [{
          showInLegend: false,
          data: '',
          lineColor: '#4355FB',
          lineWidth: 5,
          cursor: 'pointer',
        }],
        credits: {
          enabled: false,
        },
      },
    };
  },

  mounted() {
    this.getCustomerStats();
  },

  watch: {
    userLang: 'getCustomerStats',
  },

  computed: {

    userLang() {
      if (this.$store.state.auth.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },

    isVibonsAdmin() {
      if (this.$store.state && this.$store.state.auth) {
        return this.$store.state.auth.isVibonsAdmin;
      }
      return false;
    },
    userCustomerId() {
      return this.$store.state.auth.customerId;
    },
    userGroupId() {
      return this.$route.params.userGroupId;
    },

    userGroupName() {
      return this.$route.params.userGroupName;
    },
    isCustomerAdmin() {
      if (this.$store?.state?.auth?.userInfo?.role) return this.$store?.state?.auth?.userInfo?.role === 'customer-admin';
      return false;
    },
    dashboardSubNavigation() {
      let submenu = [
        {
          text: this.$t('overview'),
          url: '/dashboard/overview',
          order: 1,
        },
        {
          text: this.$t('journeys'),
          url: '/dashboard/journeys',
          order: 2,
        },
        {
          text: this.$t('users'),
          url: '/dashboard/users',
          order: 4,
        },
        {
          text: this.$t('quiz_survey'),
          url: '/dashboard/quiz-survey',
          order: 5,
        },
        {
          text: this.$t('report_demands'),
          url: '/dashboard/reportdemands',
          order: 7,
        },
      ];
      if (this.isVibonsAdmin) {
        submenu = submenu.concat([
          {
            text: this.$t('journeys_v2'),
            url: '/dashboard/journeys_v2',
            order: 3,
          },
          {
            text: this.$t('customers_sub'),
            url: '/dashboard/customers',
            order: 6,
          },

        ]);
      }
      if (this.isCustomerAdmin && this.$store.state.auth?.userInfo?.customer?.customizations?.journeyv2) {
        submenu = submenu.concat({
          text: this.$t('journeys_v2'),
          url: '/dashboard/journeys_v2',
          order: 3,
        });
      }

      submenu.sort((a, b) => a.order - b.order);
      return submenu;
    },
  },

  methods: {
    getCustomerStats() {
      this.getCustomerHistogram();
      this.getCustomerUsageSummary();
      this.getContentRatings();
    },

    previewContentItem(itemID) {
      const obj = {
        id: itemID,
        launchedFrom: 'Dashboard',
      };
      this.$store.commit('modal/togglePreviewModal', obj);
    },

    getCustomerHistogram() {
      if (this.userGroupId) {
        const postData = new FormData();
        postData.append('group_id', this.userGroupId);
        postData.append('start_date', '2020-01-01');
        postData.append('end_date', moment().format('YYYY-MM-DD'));

        API.post(`${API_REPORTS_GROUP_HISTOGRAM}`, postData).then((response) => {
          let lastPrev;
          let completionRate = 0;
          let lastPreviousCompletionRate = 0;
          const histogramData = response.data.group_weekly_histogram;

          if (this.dash.every(histogramData, this.dash.isObject)) {
            const lastI = this.dash.last(histogramData);
            const len = this.dash.size(histogramData);
            if (!this.dash.isEmpty(lastI, true) && this.dash.has(lastI, 'completion_rate')) {
              completionRate = parseInt(
                (((lastI.completed_item_count + lastI.opened_item_count * 0.5)
                * lastI.users_registered)
                / (lastI.total_journey_items_assigned * lastI.engaged_users))
                * 100, 10,
              );
            }

            if (len > 1) {
              lastPrev = histogramData[len - 2];
            }

            if (this.dash.has(lastPrev, 'completion_rate')) {
              lastPreviousCompletionRate = parseInt(
                (((lastPrev.completed_item_count + lastPrev.opened_item_count * 0.5)
                * lastPrev.users_registered)
                / (lastPrev.total_journey_items_assigned * lastPrev.engaged_users))
                * 100, 10,
              );
            }
            this.completionRate = completionRate;
            // region Completion Rate Compare to Prev Percent
            const a2b10 = (completionRate - lastPreviousCompletionRate) / completionRate;
            this.completionRateCompareToPrevPercent = Number.isNaN(a2b10) ? 0 : parseInt(a2b10 * 100, 10);

            // region Completion Rate Arrow
            if (completionRate !== lastPreviousCompletionRate) {
              if (completionRate > lastPreviousCompletionRate) {
                this.completionRateArrow = 'arrow-up';
              } else if (completionRate < lastPreviousCompletionRate) {
                this.completionRateArrow = 'arrow-down';
              }
            }

            let weekSlugList = this.dash.map(histogramData, 'week');
            if (this.userLang && weekSlugList) {
              weekSlugList = weekSlugList.map(date => this.$helpers.getUserLangBasedDateTimeFormat(date, this.userLang, 'weekslug'));
            }
            // set Charts xAxis
            if (this.dash.size(weekSlugList) > 0) {
              const intervalX = Math.ceil(this.dash.size(weekSlugList) / 10);
              this.completedItemCountCharts.xAxis.tickInterval = intervalX;
              this.completedItemCountCharts.xAxis.categories = weekSlugList;

              this.totalItemAssignedCharts.xAxis.tickInterval = intervalX;
              this.totalItemAssignedCharts.xAxis.categories = weekSlugList;
            }
            const completedItemCount = [];
            const totalJourneyAssigned = [];
            histogramData.forEach((item) => {
              completedItemCount.push(item.completed_item_count);
              totalJourneyAssigned.push(item.total_journey_items_assigned);
            });
            this.completedItemCountCharts.series = [
              { name: this.$t('completed_item_count'), data: completedItemCount, color: '#4558FB' },
            ];
            this.totalItemAssignedCharts.series = [
              { name: this.$t('total_item_assigned'), data: totalJourneyAssigned, color: '#4558FB' },
            ];
          }
        }).catch((error) => {
          this.$helpers.displayError(error);
        });
      }
      return false;
    },

    getCustomerUsageSummary() {
      return new Promise(async (resolve, reject) => {
        const postData = new FormData();
        postData.append('length', '1000');
        await API.post(`${API_REPORTS_GROUP_USAGE_SUMMARY}/${this.userGroupId}`, postData).then((response) => {
        // region Data
          let data;

          if (!this.dash.isEmpty(response, true)) {
            if (this.dash.has(response, 'data')) {
              if (!this.dash.isEmpty(response.data, true)) {
              // eslint-disable-next-line prefer-destructuring
                data = response.data.data;
                const len = this.dash.size(data);
                if (len > 0) {
                  data.reverse();
                  const lastRow = this.dash.last(data);
                  if (lastRow) {
                    const notifEnabled = Math.round((lastRow.mobile_user_count / lastRow.mobile_user_count) * 100);
                    this.notificationEnabledPercent = isNaN(notifEnabled) ? 0 : notifEnabled;
                    const mobileDownload = Math.round((lastRow.mobile_user_count / lastRow.total_users) * 100);
                    this.mobileDownloadPenetrationPercent = isNaN(mobileDownload) ? 0 : mobileDownload;
                    const activeUsers = Math.round((lastRow.last_month_active_users / lastRow.active_users_since_today) * 100);
                    this.activeUsersPercent = isNaN(activeUsers) ? 0 : activeUsers;
                  }

                  let weekSlugList = this.dash.map(data, 'week_start');
                  if (this.userLang && weekSlugList) {
                    weekSlugList = weekSlugList.map(date => this.$helpers.getUserLangBasedDateTimeFormat(date, this.userLang, 'weekslug'));
                  }
                  // set Charts xAxis
                  if (this.dash.size(weekSlugList) > 0) {
                    const intervalX = Math.ceil(this.dash.size(weekSlugList) / 10);
                    this.userCountChartOption.xAxis.tickInterval = intervalX;
                    this.userCountChartOption.xAxis.categories = weekSlugList;

                    this.averageTimeSpentRatioCharts.xAxis.tickInterval = intervalX;
                    this.averageTimeSpentRatioCharts.xAxis.categories = weekSlugList;

                    this.responseRatioCharts.xAxis.tickInterval = intervalX;
                    this.responseRatioCharts.xAxis.categories = weekSlugList;
                  }
                  // setting chart's yAxis

                  const totalUsers = [];
                  const mobileUsers = [];
                  const lastMonthUsers = [];
                  // const notifEnabledUsers = [];
                  const averageTimeSpentRatioList = [];
                  const responseRateList = [];

                  this.dash.map(data, (item) => {
                    totalUsers.push(parseInt(item.total_users, 10));
                    mobileUsers.push(parseInt(item.mobile_user_count, 10));
                    lastMonthUsers.push(parseInt(item.last_month_active_users, 10));
                    // notifEnabledUsers.push(parseInt(item.mobile_user_count, 10));
                    averageTimeSpentRatioList.push(moment.duration(item.average_time_spent).asMilliseconds());
                    responseRateList.push(parseInt(item.response_rate, 10));
                  });

                  this.userCountChartOption.series = [
                    { name: this.$t('total_num_of_users'), data: totalUsers, color: '#8F9BFD' },
                    { name: this.$t('mobile_user_count'), data: mobileUsers, color: '#4558FB' },
                    // { name: this.$t('notif_enabled_users'), data: notifEnabledUsers, color: '#E1C5FF' },
                    { name: this.$t('num_of_active_user'), data: lastMonthUsers, color: '#B26EFC' },
                  ];

                  if (this.dash.size(averageTimeSpentRatioList) > 0) {
                    this.averageTimeSpentRatioCharts.series[0].data = averageTimeSpentRatioList;
                  }
                  if (this.dash.size(responseRateList) > 0) {
                    this.responseRatioCharts.series[0].data = responseRateList;
                  }
                }
                // setting ends here
              }
            }
          }
          resolve();
        // endregion
        }).catch((error) => {
          this.$helpers.displayError(error);
          reject(error);
        });
      });
    },

    getContentRatings() {
      const formData = new FormData();
      formData.append('length', 5);
      formData.append('group_id', this.userGroupId);
      formData.append('order[0][dir]', 'desc');
      formData.append('order[0][column]', 1);
      API.post(API_REPORTS_GROUP_RATINGS_DATATABLE, formData).then((response) => {
        this.averageRatingsTable = response.data.data;
      }).catch((error) => {
        this.$helpers.displayError(error);
      });
    },

    closeReportDropDown() {
      this.timer = setTimeout(() => {
        this.showReportDropDown = false;
      }, 1000);
    },

    holdBulkMenu() {
      clearTimeout(this.timer);
    },

    reportDownload(type) {
      if (type === 'groupjourney') {
        this.downloadReport(API_EXPORT_GROUP_JOURNEY, this.$t('group_journey_report'), true, true, true);
      } else if (type === 'groupactivity') {
        this.downloadReport(API_EXPORT_GROUP_ACTIVITY, this.$t('group_activity_report'), true, true, true);
      } else if (type === 'disengaged') {
        this.downloadReport(API_EXPORT_DISENGAGED_USERS, this.$t('customer_disengage_filename'));
      }
    },

    downloadReport(apiurl, name, dateOnly = false, reportDownloadHasStartDate = false, reportDownloadHasEndDate = false) {
      const obj = {
        modalType: 'modalReportDownload',
        reportDownloadUrl: `${apiurl}`,
        reportDownloadName: name,
        dateOnly,
        reportDownloadHasStartDate,
        reportDownloadHasEndDate,
        reportDownloadPayload: {
          customer_id: this.userCustomerId,
          group_id: this.userGroupId,
        },
      };
      this.$helpers.toggleModal(obj);
    },
  },
};
</script>

<style lang="scss" scoped>
div#app div.page.dashboard * {
  font-size: 14px;
  outline: 0;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div#app div.page.dashboard {
  :focus {
    outline: 0;
  }

  .chart {
    margin: 0 auto;
  }

  .dboard-bg-white {
    background-color: #fff !important;
    border-radius: 8px;
  }

  .dboard-box-vertical {
    display: table-cell;
    vertical-align: middle;
  }

  .dboard-top-row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .vue-simple-progress,
  .vue-simple-progress-bar {
    border-radius: 5px !important;
  }

  svg.highcharts-root text.highcharts-credits {
    display: none !important;
    visibility: hidden !important;
  }

  .dboard-top-row h5 {
    font-size: 18px;
    color: #727d92;
    text-align: center;
    font-weight: bold;
    margin-bottom: 25px;
    opacity: 0.5;
  }

  .dboard-top-row h6 {
    opacity: 0.5;
    font-size: 18px;
    font-weight: bold;
    color: #394c71;
    letter-spacing: 0;
    text-align: left;
  }

  .dboard-top-row h6 .svg-inline--fa {
    height: 13px;
    width: 13px;
  }

  .dboard-top-row .col,
  .dboard-top-row .col-6 {
    height: auto;
  }

  .dboard-top-box {
    padding: 0 25px 0 25px !important;
    display: flex;
  }

  .dboard-top-box-inside {
    padding: 25px;
  }

  .dboard-top-box-inside-inner {
    display: table;
    height: 100%;
    width: 100%;
    text-align: center;
  }

  #dboard-top-box-left .dboard-bg-white {
    background: url(~@/assets/img/dashboard-top-bg-02.png) right 25px top 25px
        no-repeat,
      url(~@/assets/img/dashboard-top-bg-01.png) left bottom no-repeat;
    padding: 25px;
  }

  #dboard-top-box-left .dboard-bg-white p {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #394c71;
    letter-spacing: 0;
    opacity: 0.5;
  }

  #dboard-top-box-right .dboard-bg-white {
    background: url(~@/assets/img/dashboard-top-bg-03.png) right top 25px
      no-repeat;
    padding: 25px;
  }

  .info-icon-left .svg-inline--fa,
  .info-icon-inside .svg-inline--fa {
    color: #727d92 !important;
  }

  .info-icon-left .svg-inline--fa {
    position: absolute;
    top: 15px;
    left: 15px;
    height: 19px;
    width: 19px;
  }

  .percent {
    font-size: 80px !important;
    color: #394c71 !important;
    font-weight: bold;
    opacity: unset !important;
  }

  .percent .svg-inline--fa {
    position: absolute;
    top: -50%;
    height: 32px;
    width: 32px;
  }

}

.chart {
  height: 331px;
}

.dboard-top-main-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 30px;
}

.percent-small {
  font-size: 40px !important;
  color: #394c71 !important;
  font-weight: bold;
  opacity: unset !important;
}

/*#region Top Box Right*/
#dboard-top-box-right #dboard-top-box-right-left .dboard-top-row {
  width: 100%;
  display: table-row;
  height: 33%;
  border: 1px solid red;
}

#dboard-top-box-right #dboard-top-box-right-left .dboard-top-row .col-6 {
  vertical-align: middle;
  display: table-cell;
  padding: 0;
  margin: 0;
  width: 50%;
}

#dboard-top-box-right
  #dboard-top-box-right-left
  .dboard-top-row:nth-child(2)::after,
#dboard-top-box-right
  #dboard-top-box-right-left
  .dboard-top-row:nth-child(3)::after {
  display: inline-list-item;
  opacity: 0.2;
  background-color: #b9bec9;
  content: "";
  height: 1px;
  left: calc(25% + 12px) !important;
  position: absolute;
  transform: translate(-50%, 0);
  width: calc(50% - 25px);
  margin-top: -6px;
}

#dboard-top-box-right #dboard-top-box-right-left {
  width: 50%;
  display: table;
  padding: 0;
  margin: 0;
  float: left;
  height: 100%;
}

#dboard-top-box-right #dboard-top-box-right-right {
  width: 50%;
  display: flex;
  padding: 0;
  margin: 0;
  height: 100%;
  text-align: center;
}

#dboard-top-box-right #dboard-top-box-right-right .dboard-top-row {
  display: table;
  height: 100%;
  width: 100%;
}

#dboard-top-box-right #dboard-top-box-right-right .dboard-top-row::after {
  content: none !important;
}

#dboard-top-box-right #dboard-top-box-right-right .dboard-top-row .col {
  vertical-align: bottom;
  display: table-cell;
  padding: 0;
  margin: 0;
  text-align: center;
}

#download-excel-button button.button.button--alternative {
  background: #eff0f2;
  border-radius: 4px;
  font-size: 14px;
  color: #142b58;
  letter-spacing: 0.54px;
  text-align: center;
  width: 227px;
  height: 48px;
}

#download-excel-button button.button .svg-inline--fa {
  position: relative;
  top: 3px;
  left: -10%;
}

#download-excel-button p {
  margin-top: 10px;
  text-align: center;
}

#dboard-top-box-right
  div.col.dboard-bg-white
  div.dboard-top-box-inside.dboard-top-box-inside-inner {
  position: relative;
  margin-top: -25px !important;
}

#bottom-right {
  padding: 0 25px 0 25px;
}

#bottom-right h5 {
  text-align: center;
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translate(-50%, -50%);
}

#bottom-right .row.dboard-top-row.dboard-top-main-row {
  height: calc(50% - 15px);
  padding: 0;
  margin: 0;
}

#bottom-right .row.dboard-top-row.dboard-top-main-row:nth-child(2) {
  margin-top: 30px;
}

#bottom-right .right-left {
  padding: 0 15px 0 0;
}

#bottom-right .right-left .dboard-top-box-two-header {
  display: table-row;
  position: absolute;
  top: 25px;
  width: calc(100% - 65px);
  left: 25px;
}

#bottom-right .right-left .dboard-top-box-two-header .col-6 {
  display: table-cell;
}

#bottom-right .right-left .dboard-top-box-two-header .col-6 h5 {
  text-align: center;
  position: relative;
  top: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
}

#bottom-right .right-left .dboard-top-box-two-header-bottom {
  display: table-row;
  height: 50%;
}

#bottom-right .right-left .dboard-top-box-two-header-bottom .col-6 {
  display: table-cell;
  z-index: 2;
}

#bottom-right .right-left .dboard-top-box-two-header-bottom .vertical-line {
  width: 1px;
  background-color: #d8d8d8;
  height: 60%;
  position: absolute;
  left: calc(50% - 4px);
  transform: translate(calc(50% - 4px), -40%);
  vertical-align: center;
  z-index: 1;
}

#bottom-right .right-left .dboard-top-box-two-header-bottom .vertical-line p {
  background-color: #fff;
  position: absolute;
  top: 50%;
  padding: 12px;
  text-align: center;
  transform: translate(-50%, -50%);
  color: #394c71 !important;
}

#bottom-right
  .row.dboard-top-row.dboard-top-main-row:nth-child(2)
  .right-left
  > div {
  background: #fff url(~@/assets/img/dashboard-top-bg-05.png) right bottom
    no-repeat !important;
}

#bottom-right
  .row.dboard-top-row.dboard-top-main-row:nth-child(2)
  .right-left
  .inline-p {
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

#bottom-right
  .row.dboard-top-row.dboard-top-main-row:nth-child(2)
  .right-left
  .inline-p
  p {
  margin-top: 60px;
}

#bottom-right
  .row.dboard-top-row.dboard-top-main-row:nth-child(2)
  .right-left
  .percent-small {
  margin-top: 30px !important;
}

#bottom-right .right-right {
  padding: 0 0 0 15px;
}

#bottom-right
  .row.dboard-top-row.dboard-top-main-row:nth-child(1)
  .right-right
  .button {
  margin-top: 30px !important;
  padding: 0;
}

#bottom-right
  .row.dboard-top-row.dboard-top-main-row:nth-child(2)
  .right-right
  > div {
  background: #fff url(~@/assets/img/dashboard-top-bg-04.png) right bottom
    no-repeat !important;
}

#bottom-right
  .row.dboard-top-row.dboard-top-main-row:nth-child(2)
  .right-right
  .info-icon-left
  .svg-inline--fa {
  left: 30px;
}

#bottom-right
  .row.dboard-top-row.dboard-top-main-row:nth-child(2)
  .right-right
  .percent-small {
  margin-top: 30px !important;
}

#bottom-right
  div.col-6
  .dboard-bg-white.dboard-top-box-inside.dboard-top-box-inside-inner {
  width: 100%;
  display: table !important;
  text-align: center !important;
}

@media (max-width: 1256px) {
  div#app div.page.dashboard {
    #dboard-top-box-left,
    #dboard-top-box-right {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }

    #dboard-top-box-right {
      margin-top: 30px !important;
    }

    .percent-small {
      font-size: 30px !important;
    }
  }
}

@media (max-width: 1200px) {
  div#app div.page.dashboard {
    #bottom-left,
    #bottom-right {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }

    #bottom-right {
      margin-top: 30px !important;
    }
  }
}

@media (max-width: 741px) {
  div#app div.page.dashboard {
    #bottom-right {
      .row.dboard-top-row.dboard-top-main-row {
        height: 100% !important;
      }

      .right-left,
      .right-right {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
        padding: 0;
      }

      .right-right {
        margin-top: 30px !important;
      }

      .row.dboard-top-row.dboard-top-main-row:nth-child(2) .right-right {
        margin-bottom: 25px !important;

        .info-icon-left .svg-inline--fa {
          left: 15px;
        }
      }
    }

    #dboard-top-box-right {
      display: inline;

      #dboard-top-box-right-left,
      #dboard-top-box-right-right {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
        padding: 0;
        height: 100% !important;
      }

      #dboard-top-box-right-left .dboard-top-row:nth-child(2)::after,
      #dboard-top-box-right-left .dboard-top-row:nth-child(3)::after {
        left: 50% !important;
        position: absolute;
        transform: translate(-50%, 0);
        width: 90%;
        margin-top: -3px !important;
      }

      #dboard-top-box-right-right {
        display: inline-table;
        margin-top: 30px !important;
      }
    }
  }
}

@keyframes bouncearrowup {
  0% {
    top: 0;
  }
  50% {
    top: -20px;
  }
  100% {
    top: 0;
  }
}

#arrowup {
  position: relative;
  margin: 0 auto;
  text-align: center;
  animation: bouncearrowup 3s infinite;
  svg {
    transition: 0.3s ease-in-out;
  }
}

@keyframes bouncearrowdown {
  0% {
    top: 0;
  }
  50% {
    top: 20px;
  }
  100% {
    top: 0;
  }
}

#arrowdown {
  position: relative;
  margin: 0 auto;
  text-align: center;
  animation: bouncearrowdown 3s infinite;
  svg {
    transition: 0.3s ease-in-out;
  }
}
@keyframes bouncedash {
  0% {
    left: 0;
  }
  50% {
    left: 20px;
  }
  100% {
    left: 0;
  }
}

#dash {
  position: relative;
  margin: 0 auto;
  text-align: center;
  color: #727d92;
  animation: bouncedash 3s infinite;
  svg {
    transition: 0.3s ease-in-out;
  }
}
.chart_holder {
  background-color: #fff !important;
  border-radius: 8px;
  padding: 25px;
  width: 100%;

  h5 {
    font-size: 16px;
    color: #727d92;
    text-align: center;
    font-weight: bold;
    margin-bottom: 25px;
    opacity: 0.5;
  }
  .info-icon-left .svg-inline--fa {
    left: 40px !important;
  }
}

::v-deep .gradient-back {
  background-image: linear-gradient(to left, #254ffb, #f27299);
  color: #fff;
  background-size: 100% 30px;
  background-repeat: no-repeat;
  background-position: nter;

  a {
    color: #fff!important;
    &:hover {
      font-weight: bolder;
    }
  }
}
.preview-item {
  cursor: pointer;
}
.usergroup-overview--title {
    display: inline-flex;
    font-size: 26px;
    color: #727D92;
    align-items: center;
    font-weight: 500;
    margin-bottom: 20px;
    margin-left: 60px;
    small {
      font-size: 16px;
      margin-left: 20px;
    }
    a {
      margin-right: 10px;
      display: inline-block;
      cursor: pointer;
      svg {
        transition: transform .2s ease-in;
      }
      &:hover {
        svg {
          transform: translateX(-5px);
        }
      }
    }
  }
  .icon-dropdown {
    color: #fff;
    height: 36px;
    margin: -10px 0px -10px 20px;
    padding-left: 11px;
    width: 22px;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
}
a {
  display: flex;
  color:#142B58;
  align-content: center;
  align-items: center;
}
</style>
