<template>
  <div
    class="modal-container"
  >
    <div
      class="modal"
      :class="{'is-loading': formSending }"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div
          v-if="pageIsLoading"
          class="page-loader"
        >
          <div class="page-is-loading" />
        </div>
        <div
          class="modal-content"
          v-if="!pageIsLoading"
        >
          <header class="modal-header">
            <h5 class="modal-title">
              {{ editMode ? $t('edit_journey') : $t('create_new_journey') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>
          <nav class="navigation navigation--secondary">
            <div class="container">
              <ul
                class="navigation__list nav"
                id="contentCreateTab"
                role="tablist"
              >
                <li class="navigation__item">
                  <button
                    class="navigation__link"
                    :class="{ 'active': tabPane === 'mainTab'}"
                    @click="tabNavigation('mainTab')"
                  >
                    {{ $t('main_tab') }}
                  </button>
                </li>
                <li
                  v-if="canBeReschedule"
                  class="navigation__item"
                >
                  <button
                    class="navigation__link"
                    :class="{ 'active': tabPane === 'rescheduleJourneyTab'}"
                    @click="tabNavigation('rescheduleJourneyTab')"
                  >
                    {{ $t('reschedule_tab') }}
                  </button>
                </li>
                <li
                  class="navigation__item"
                  v-if="duplicated_journey_id"
                >
                  <button
                    class="navigation__link"
                    :class="{ 'active': tabPane === 'reminderSettingsTab'}"
                    @click="tabNavigation('reminderSettingsTab')"
                  >
                    {{ $t('reminder_tab') }}
                  </button>
                </li>
                <li class="navigation__item">
                  <button
                    class="navigation__link"
                    :class="{ 'active': tabPane === 'advancedSettingsTab'}"
                    @click="tabNavigation('advancedSettingsTab')"
                  >
                    {{ $t('advanced_tab') }}
                  </button>
                </li>
                <li class="navigation__item">
                  <button
                    class="navigation__link"
                    :class="{ 'active': tabPane === 'assignProfileTab'}"
                    @click="tabNavigation('assignProfileTab')"
                  >
                    {{ $t('assign_profile_tab') }}
                  </button>
                </li>
                <li
                  v-show="tabPane === 'rescheduleJourneyTab' && !showCalander"
                  :class="['panel-detail', showPanel ? 'active': '']"
                >
                  <span
                    class="icon-setting align-items-center"
                    @click="showPanel = !showPanel"
                  >
                    <icon-container
                      name="setting"
                      view-box="0 0 12 12"
                      :widht="20"
                      :height="20"
                      :is-icon-class="false"
                    >
                      <icon-setting />
                    </icon-container>
                  </span>
                </li>
                <li
                  v-show="tabPane === 'rescheduleJourneyTab'"
                  :class="['panel-detail', showCalander ? 'active': '']"
                >
                  <span
                    class="icon-setting align-items-center"
                    @click="showCalander = !showCalander"
                  >
                    <icon-container
                      name="calander"
                      view-box="0 0 20 20"
                      :widht="20"
                      :height="20"
                      :is-icon-class="false"
                    >
                      <icon-calander />
                    </icon-container>
                  </span>
                </li>
              </ul>
            </div>
          </nav>
          <div class="modal-body">
            <div
              class="tab-content"
              :class="{'disabled': pageIsLoading}"
            >
              <div
                class="tab-pane"
                v-show="tabPane === 'mainTab'"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label
                        for="contentTitle"
                        class="form-label"
                      >{{ $t('journey_name_uppercase') }}</label>
                      <input
                        :class="{ 'is-danger': errors.has('journey.name') }"
                        type="text"
                        class="form-control"
                        id="journeyName"
                        v-model="journeyName"
                        data-vv-scope="journey"
                        data-vv-name="name"
                        :data-vv-as="$t('journey_name')"
                        v-validate="'required|max:100'"
                        :placeholder="$t('journey_name_placeholder')"
                        :disabled="canNotEdit || pageIsLoading"
                        @input="updateName($event.target.value)"
                      >
                      <span
                        v-if="errors.has('journey.name')"
                        class="help is-danger"
                      >{{ errors.first('journey.name') }}</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label
                        for="contentTitle"
                        class="form-label"
                      >{{ $t('friendly_name') }}</label>
                      <input
                        :class="{ 'is-danger': errors.has('journey.friendly') }"
                        type="text"
                        class="form-control"
                        id="journeyNameFriendly"
                        v-model="journeyNameFriendly"
                        data-vv-scope="journey"
                        data-vv-name="friendly"
                        :data-vv-as="$t('friendly_name_journey')"
                        v-validate="'max:100'"
                        :placeholder="$t('journey_friendly_placeholder')"
                        :disabled="canNotEdit"
                      >
                      <span
                        v-show="errors.has('journey.friendly')"
                        class="help is-danger"
                      >{{ errors.first('journey.friendly') }}</span>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label
                        for="journeyLanguage"
                        class="form-label"
                      >{{ $t('journey_language') }}</label>
                      <multi-select
                        track-by="name"
                        label="value"
                        clear-on-select=""
                        deselect-label=""
                        select-label=""
                        v-model="selectedLanguage"
                        :options="languageOptions"
                        class="selectbox selectbox--secondary"
                        :disabled="canNotEdit || pageIsLoading"
                      />
                    </div>
                  </div>
                </div>

                <div
                  v-show="errors.items.length > 0"
                  class="form-group"
                >
                  <div class="alert alert--card alert--error">
                    <p>{{ $t('error_msg_title') }}</p>
                    <ul>
                      <li
                        v-for="(error, index) in errors"
                        :key="index"
                      >
                        {{ error.msg }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="button button--primary"
                    :disabled="isPending || canNotEdit"
                    @click="editMode ? updateJourney() : createJourney()"
                  >
                    <span
                      v-if="!isPending"
                    >
                      {{ editMode ? $t('update_button') : $t('create_button') }}
                    </span>
                    <div
                      class="lds-ellipsis"
                      v-if="isPending"
                    >
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </button>
                </div>
              </div>

              <div
                class="tab-pane"
                v-if="tabPane === 'rescheduleJourneyTab'"
              >
                <div>
                  <collapse-transition
                    :duration="500"
                    dimension="height"
                  >
                    <div
                      class="col-12"
                      v-show="showCalander"
                    >
                      <full-calendar
                        v-show="showCalander"
                        :user-lang="userLang"
                        :limit-date="false"
                        :calender-events="calenderEvents"
                        :preview-first-date="firstItemDate"
                        :total-event-days="totalEventDays"
                        @setDateFromDrop="setDateFromDrop"
                        @isDragging="setDragging"
                      />
                    </div>
                  </collapse-transition>
                </div>
                <div>
                  <collapse-transition
                    :duration="500"
                    dimension="height"
                  >
                    <div v-if="!showCalander">
                      <div class="container-fluid">
                        <div class="row justify-content-between row-height">
                          <div class="col-9" />
                          <div
                            class="col-3 d-flex p-0"
                          >
                            <span v-show="showPanel">
                              <span
                                class="swicth-label-head mr-1"
                              >{{ $t('send_email_short') }}</span>
                              <span
                                class="swicth-label-head"
                              >
                                {{ $t('send_push_notif_short') }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <draggable-comp
                        v-model="tempDataList"
                        v-bind="dragOptions"
                        handle=".button-handle"
                        :animation="200"
                        @start="isDragging=true"
                        @end="isDragging=false"
                        @change="cardItemMoved"
                      >
                        <transition-group
                          name="ilist"
                          class="journey-preview-list"
                          tag="ul"
                        >
                          <template v-for="(item, index) in tempDataList">
                            <li
                              v-if="true"
                              class="journey-preview-list__element"
                              :key="index"
                            >
                              <span class="button-handle">
                                <icon-container
                                  name="handle"
                                  view-box="0 0 25 18"
                                  :is-icon-class="false"
                                >
                                  <icon-handle />
                                </icon-container>
                              </span>
                              <template v-if="item && item.type === 'journey_item' && (item.content.sub_type !== 'LIVE_EVENT' && item.content.sub_type !== 'MEETING')">
                                <div class="container-fluid">
                                  <div class="row">
                                    <div
                                      :class="showPanel ? 'col-5 d-flex align-content-center': 'col-6 d-flex align-content-center'"
                                    >
                                      <div class="journey-preview-list__item journey-preview-list__item--primary">
                                        <span class="icon">
                                          <span
                                            class="icon__item"
                                            v-html="$helpers.iconType(item.content.sub_type)"
                                          />
                                        </span>
                                        <span class="title">{{ item.content.name }}</span>
                                      </div>
                                    </div>
                                    <div :class="showPanel ? 'col-4 d-flex align-content-center': 'col-6 d-flex align-content-center'">
                                      <div
                                        class="journey-preview-list__item"
                                        :class="{ 'is-editing': record.id === item.id }"
                                      >
                                        <date-picker
                                          class="date"
                                          v-model="item.content.activation_date"
                                          :ref="`datepicker${index}`"
                                          :lang="userLang"
                                          type="datetime"
                                          value-type="YYYY-MM-DD HH:mm:ss"
                                          :format="userLang === 'en' ? 'MM.DD.YYYY / hh:mm A' : 'DD.MM.YYYY / HH:mm'"
                                          :time-picker-options="{ start: '08:00', step: '00:15', end: '23:45', format: userLang === 'en' ? 'hh:mm A' : 'HH:mm' }"
                                          @change="(date, type) => { edit(index, type, date, 'activation_date') }"
                                          @input="(date) => { edit(index, 'manuel', date, 'activation_date') }"
                                        >
                                          <i slot="icon-calendar" /><i slot="icon-clear" />
                                        </date-picker>
                                        <button
                                          class="button-edit"
                                          @click="showDatePicker(`datepicker${index}`)"
                                        >
                                          <svg
                                            width="12"
                                            height="12"
                                            viewBox="0 0 12 12"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <mask
                                              id="mask0"
                                              mask-type="alpha"
                                              maskUnits="userSpaceOnUse"
                                              x="0"
                                              y="0"
                                              width="12"
                                              height="12"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M0 0H11.9556V11.9291H0V0Z"
                                                fill="white"
                                              />
                                            </mask>
                                            <g mask="url(#mask0)">
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M9.53796 4.53166L7.4203 2.39521L8.48875 1.32687L10.6249 3.44487L9.53796 4.53166ZM1.10072 9.806L2.13215 10.8285H1.10072V9.806ZM3.46698 10.6026L1.33019 8.48453L6.64189 3.17351L8.75966 5.30996L3.46698 10.6026ZM11.7926 3.05238L8.87423 0.159584C8.65981 -0.0547183 8.31238 -0.0521145 8.09808 0.160942L0.161434 8.09668C0.161094 8.09702 0.160981 8.09736 0.160642 8.0977C0.160302 8.09792 0.159962 8.09815 0.159623 8.09849C0.15 8.10823 0.143208 8.11943 0.134377 8.12973C0.11966 8.14694 0.104151 8.16358 0.0918113 8.18238C0.0784528 8.20219 0.0688302 8.22336 0.0586415 8.2443C0.0530943 8.25551 0.0461887 8.26581 0.041434 8.27724C0.0320377 8.30022 0.0261509 8.32411 0.0199245 8.348C0.0169811 8.35887 0.0126792 8.36917 0.0105283 8.38015C0.00543396 8.40619 0.00396226 8.43256 0.00271698 8.45894C0.00226415 8.468 0 8.47694 0 8.48611V11.3788C0 11.6826 0.246113 11.9292 0.550302 11.9292H3.46879C3.50468 11.9292 3.54057 11.9256 3.576 11.9186C3.58098 11.9175 3.58562 11.9154 3.5906 11.9143C3.62049 11.9075 3.65026 11.8993 3.67902 11.8874C3.6806 11.8868 3.68185 11.8857 3.68343 11.8851C3.71796 11.8705 3.75091 11.8519 3.78226 11.8299C3.80887 11.8115 3.83445 11.7914 3.85766 11.7682L11.7944 3.83249C11.8985 3.72879 11.9556 3.58819 11.9556 3.44181C11.9556 3.29532 11.8966 3.15517 11.7926 3.05238Z"
                                                fill="#374163"
                                              />
                                            </g>
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                    <div
                                      v-show="showPanel"
                                      class="col-3"
                                    >
                                      <div class="d-flex justify-content-center aling-items-center mx-4">
                                        <div class="d-flex">
                                          <div class="swicth-checkbox px-2 mt-2">
                                            <input
                                              type="checkbox"
                                              :id="`sendEmail${index}`"
                                              v-model="item.content.is_email_enabled"
                                              @change="edit(index)"
                                              :true-value="1"
                                              :false-value="0"
                                            >
                                            <label
                                              class="swicth-label"
                                              :for="`sendEmail${index}`"
                                            />
                                          </div>
                                          <div class="swicth-checkbox px-2 mt-2">
                                            <input
                                              type="checkbox"
                                              :id="`sendPushNotification${index}`"
                                              v-model="item.content.is_push_enabled"
                                              @change="edit(index)"
                                              :true-value="1"
                                              :false-value="0"
                                            >
                                            <label
                                              class="swicth-label"
                                              :for="`sendPushNotification${index}`"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </template>
                              <template v-if="item && item.type === 'journey_item' && (item.content.sub_type === 'LIVE_EVENT' || item.content.sub_type === 'MEETING')">
                                <div class="container-fluid">
                                  <div class="row">
                                    <div
                                      :class="showPanel ? 'col-5 d-flex align-content-center': 'col-6 d-flex align-content-center'"
                                    >
                                      <div class="journey-preview-list__item journey-preview-list__item--primary">
                                        <span class="icon">
                                          <span
                                            class="icon__item"
                                            v-html="$helpers.iconType(item.content.sub_type)"
                                          />
                                        </span>
                                        <span class="title">{{ item.content.name }}</span>
                                      </div>
                                    </div>
                                    <div :class="showPanel ? 'col-4 d-flex align-content-center': 'col-6 d-flex align-content-center'">
                                      <div
                                        class="journey-preview-list__item"
                                        :class="{ 'is-editing': record.id === item.id }"
                                      >
                                        <date-picker
                                          class="date"
                                          v-model="item.content.starting_date"
                                          :ref="`datepicker${index}`"
                                          :lang="userLang"
                                          type="datetime"
                                          :disabled-date.sync="notBeforeToday"
                                          :disabled-time.sync="notBeforeTime"
                                          value-type="YYYY-MM-DD HH:mm:ss"
                                          :format="userLang === 'en' ? 'MM.DD.YYYY / hh:mm A' : 'DD.MM.YYYY / HH:mm'"
                                          :time-picker-options="{ start: '08:00', step: '00:15', end: '23:45', format: userLang === 'en' ? 'hh:mm A' : 'HH:mm' }"
                                          @change="(date, type) => { edit(index, type, date, 'starting_date') }"
                                          @input="(date) => { edit(index, 'manuel', date, 'starting_date') }"
                                        >
                                          <i slot="icon-calendar" /><i slot="icon-clear" />
                                        </date-picker>
                                        <button
                                          class="button-edit"
                                          @click="showDatePicker(`datepicker${index}`)"
                                        >
                                          <svg
                                            width="12"
                                            height="12"
                                            viewBox="0 0 12 12"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <mask
                                              id="mask0"
                                              mask-type="alpha"
                                              maskUnits="userSpaceOnUse"
                                              x="0"
                                              y="0"
                                              width="12"
                                              height="12"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M0 0H11.9556V11.9291H0V0Z"
                                                fill="white"
                                              />
                                            </mask>
                                            <g mask="url(#mask0)">
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M9.53796 4.53166L7.4203 2.39521L8.48875 1.32687L10.6249 3.44487L9.53796 4.53166ZM1.10072 9.806L2.13215 10.8285H1.10072V9.806ZM3.46698 10.6026L1.33019 8.48453L6.64189 3.17351L8.75966 5.30996L3.46698 10.6026ZM11.7926 3.05238L8.87423 0.159584C8.65981 -0.0547183 8.31238 -0.0521145 8.09808 0.160942L0.161434 8.09668C0.161094 8.09702 0.160981 8.09736 0.160642 8.0977C0.160302 8.09792 0.159962 8.09815 0.159623 8.09849C0.15 8.10823 0.143208 8.11943 0.134377 8.12973C0.11966 8.14694 0.104151 8.16358 0.0918113 8.18238C0.0784528 8.20219 0.0688302 8.22336 0.0586415 8.2443C0.0530943 8.25551 0.0461887 8.26581 0.041434 8.27724C0.0320377 8.30022 0.0261509 8.32411 0.0199245 8.348C0.0169811 8.35887 0.0126792 8.36917 0.0105283 8.38015C0.00543396 8.40619 0.00396226 8.43256 0.00271698 8.45894C0.00226415 8.468 0 8.47694 0 8.48611V11.3788C0 11.6826 0.246113 11.9292 0.550302 11.9292H3.46879C3.50468 11.9292 3.54057 11.9256 3.576 11.9186C3.58098 11.9175 3.58562 11.9154 3.5906 11.9143C3.62049 11.9075 3.65026 11.8993 3.67902 11.8874C3.6806 11.8868 3.68185 11.8857 3.68343 11.8851C3.71796 11.8705 3.75091 11.8519 3.78226 11.8299C3.80887 11.8115 3.83445 11.7914 3.85766 11.7682L11.7944 3.83249C11.8985 3.72879 11.9556 3.58819 11.9556 3.44181C11.9556 3.29532 11.8966 3.15517 11.7926 3.05238Z"
                                                fill="#374163"
                                              />
                                            </g>
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                    <div
                                      v-show="showPanel"
                                      class="col-3"
                                    >
                                      <div class="d-flex justify-content-center aling-items-center mx-4">
                                        <div class="d-flex">
                                          <div class="swicth-checkbox px-2 mt-2">
                                            <input
                                              type="checkbox"
                                              :id="`sendEmail${index}`"
                                              v-model="item.content.is_email_enabled"
                                              @change="edit(index)"
                                              :true-value="1"
                                              :false-value="0"
                                            >
                                            <label
                                              class="swicth-label"
                                              :for="`sendEmail${index}`"
                                            />
                                          </div>
                                          <div class="swicth-checkbox px-2 mt-2">
                                            <input
                                              type="checkbox"
                                              :id="`sendPushNotification${index}`"
                                              v-model="item.content.is_push_enabled"
                                              @change="edit(index)"
                                              :true-value="1"
                                              :false-value="0"
                                            >
                                            <label
                                              class="swicth-label"
                                              :for="`sendPushNotification${index}`"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    v-show="isValidated"
                                    class="row"
                                  >
                                    <div
                                      class="col-12 d-flex help is-danger justify-content-end m-0"
                                    >
                                      <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                                      {{ checkDateValidation(item.content.starting_date) ? $t('not_before') : '' }}
                                    </div>
                                  </div>
                                </div>
                              </template>
                              <template v-if="item && item.type === 'item_scheduled_notification' && (item.content.sub_type !== 'LIVE_EVENT' || item.content.sub_type !== 'MEETING')">
                                <div class="container-fluid">
                                  <div class="row">
                                    <div :class="showPanel ? 'col-5 d-flex align-content-center': 'col-6 d-flex align-content-center'">
                                      <div class="journey-preview-list__item journey-preview-list__item--primary">
                                        <span class="icon">
                                          <span
                                            class="icon__item"
                                          >
                                            <icon-container
                                              :width="22"
                                              :height="24"
                                              name="notification"
                                              view-box="0 0 22 24"
                                            >
                                              <icon-notification />
                                            </icon-container>
                                          </span>
                                        </span>
                                        <span class="title">{{ item?.content?.title }}</span>
                                      </div>
                                    </div>
                                    <div :class="showPanel ? 'col-4 d-flex align-content-center': 'col-6 d-flex align-content-center'">
                                      <div
                                        class="journey-preview-list__item"
                                        :class="{ 'is-editing': record.id === item.id }"
                                      >
                                        <date-picker
                                          class="date"
                                          v-model="item.content.activation_date"
                                          :ref="`datepickernotif${index}`"
                                          :lang="userLang"
                                          type="datetime"
                                          value-type="YYYY-MM-DD HH:mm:ss"
                                          :format="userLang === 'en' ? 'MM.DD.YYYY / hh:mm A' : 'DD.MM.YYYY / HH:mm'"
                                          :time-picker-options="{ start: '08:00', step: '00:15', end: '23:45', format: userLang === 'en' ? 'hh:mm A' : 'HH:mm' }"
                                          @change="(date, type) => { edit(index, type, date, 'activation_date') }"
                                          @input="(date) => { edit(index, 'manuel', date, 'activation_date') }"
                                        >
                                          <i slot="icon-calendar" /><i slot="icon-clear" />
                                        </date-picker>
                                        <button
                                          class="button-edit"
                                          @click="showDatePicker(`datepickernotif${index}`)"
                                        >
                                          <svg
                                            width="12"
                                            height="12"
                                            viewBox="0 0 12 12"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <mask
                                              id="mask0"
                                              mask-type="alpha"
                                              maskUnits="userSpaceOnUse"
                                              x="0"
                                              y="0"
                                              width="12"
                                              height="12"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M0 0H11.9556V11.9291H0V0Z"
                                                fill="white"
                                              />
                                            </mask>
                                            <g mask="url(#mask0)">
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M9.53796 4.53166L7.4203 2.39521L8.48875 1.32687L10.6249 3.44487L9.53796 4.53166ZM1.10072 9.806L2.13215 10.8285H1.10072V9.806ZM3.46698 10.6026L1.33019 8.48453L6.64189 3.17351L8.75966 5.30996L3.46698 10.6026ZM11.7926 3.05238L8.87423 0.159584C8.65981 -0.0547183 8.31238 -0.0521145 8.09808 0.160942L0.161434 8.09668C0.161094 8.09702 0.160981 8.09736 0.160642 8.0977C0.160302 8.09792 0.159962 8.09815 0.159623 8.09849C0.15 8.10823 0.143208 8.11943 0.134377 8.12973C0.11966 8.14694 0.104151 8.16358 0.0918113 8.18238C0.0784528 8.20219 0.0688302 8.22336 0.0586415 8.2443C0.0530943 8.25551 0.0461887 8.26581 0.041434 8.27724C0.0320377 8.30022 0.0261509 8.32411 0.0199245 8.348C0.0169811 8.35887 0.0126792 8.36917 0.0105283 8.38015C0.00543396 8.40619 0.00396226 8.43256 0.00271698 8.45894C0.00226415 8.468 0 8.47694 0 8.48611V11.3788C0 11.6826 0.246113 11.9292 0.550302 11.9292H3.46879C3.50468 11.9292 3.54057 11.9256 3.576 11.9186C3.58098 11.9175 3.58562 11.9154 3.5906 11.9143C3.62049 11.9075 3.65026 11.8993 3.67902 11.8874C3.6806 11.8868 3.68185 11.8857 3.68343 11.8851C3.71796 11.8705 3.75091 11.8519 3.78226 11.8299C3.80887 11.8115 3.83445 11.7914 3.85766 11.7682L11.7944 3.83249C11.8985 3.72879 11.9556 3.58819 11.9556 3.44181C11.9556 3.29532 11.8966 3.15517 11.7926 3.05238Z"
                                                fill="#374163"
                                              />
                                            </g>
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                    <div
                                      v-show="showPanel"
                                      class="col-3"
                                    >
                                      <div class="d-flex justify-content-center aling-items-center mx-4">
                                        <div class="d-flex">
                                          <div class="swicth-checkbox px-2 mt-2" />
                                          <div class="swicth-checkbox px-2 mt-2" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </li>
                          </template>
                        </transition-group>
                      </draggable-comp>
                    </div>
                  </collapse-transition>
                </div>
                <div
                  v-show="errorBag.length > 0 && isValidated"
                  class="form-group"
                >
                  <div class="alert alert--card alert--error">
                    <p>{{ $t('error_msg_title') }}</p>
                    <p class="help is-danger">
                      {{ $t('fix_date') }}
                    </p>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="button button--primary"
                    :disabled="isPending || canNotEdit || isNotDirty"
                    @click="rescheduleJourney"
                  >
                    <span
                      v-if="!isPending"
                    >
                      {{ $t('reschedule') }}
                    </span>
                    <div
                      class="lds-ellipsis"
                      v-if="isPending"
                    >
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </button>
                </div>
              </div>

              <div
                class="tab-pane"
                v-if="duplicated_journey_id"
                v-show="tabPane === 'reminderSettingsTab'"
              >
                <div class="form-group row">
                  <div class="col-auto">
                    <div class="swicth-checkbox">
                      <input
                        type="checkbox"
                        name="hasReminder"
                        id="hasReminder"
                        checked
                        v-model="hasReminder"
                        :disabled="canNotEdit"
                        :true-value="true"
                        :false-value="false"
                      >
                      <label
                        class="swicth-label"
                        for="hasReminder"
                      >
                        {{ $t('hasReminder') }}
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <collapse-transition
                    :duration="500"
                    dimension="height"
                  >
                    <div
                      class="container mt-5"
                      v-show="hasReminder"
                    >
                      <div class="row">
                        <div class="form-group row">
                          <label
                            for="employee_title"
                            class="col-3 form-label"
                          >
                            {{ $t('employee_title') }}
                          </label>
                          <div class="col-md-9">
                            <input
                              :class="{ 'is-danger': errors.has('journey.employee_title') }"
                              type="text"
                              class="form-control"
                              id="employee_title"
                              v-model="employee_title"
                              data-vv-name="employee_title"
                              data-vv-scope="journey"
                              :data-vv-as="$t('employee_title')"
                              v-validate="hasReminder ? 'required|max:40':''"
                            ><!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                            <span
                              v-if="errors.has('journey.employee_title')"
                              class="help is-danger"
                            >{{ errors.first('journey.employee_title') }}</span>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label
                            for="employee_content"
                            class="col-3 form-label"
                          >
                            {{ $t('employee_content') }}
                          </label>
                          <div class="col-md-9">
                            <textarea
                              :class="{ 'is-danger': errors.has('journey.employee_content') }"
                              id="employee_content"
                              class="form-control"
                              v-model="employee_content"
                              data-vv-name="employee_content"
                              data-vv-scope="journey"
                              :data-vv-as="$t('employee_content')"
                              v-validate="hasReminder ? 'required|max:160':''"
                            /><!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                            <span
                              v-if="errors.has('journey.employee_content')"
                              class="help is-danger"
                            >{{ errors.first('journey.employee_content') }}</span>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label
                            for="weeks_after_last_content"
                            class="col-3 form-label"
                          >
                            {{ $t('weeks_after_last_content') }}
                          </label>
                          <div class="col-md-7">
                            <select
                              class="selectbox-o"
                              id="weeks_after_last_content"
                              v-model="weeks_after_last_content"
                            >
                              <option
                                v-for="n in [1,2,3,4,5,6,7,8,9]"
                                :key="n"
                                :value="n"
                              >
                                {{ n }}
                              </option>
                            </select>
                            <span class="form-label mx-2">{{ $t('week') }}</span>
                            <svg-icon
                              v-tooltip.top="$t('weeks_after_last_content_info')"
                              type="mdi"
                              class="form-label"
                              :path="iconInformationOutline"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label
                            for="week_span"
                            class="col-3 form-label"
                          >
                            {{ $t('week_span') }}
                          </label>
                          <div class="col-md-7">
                            <select
                              class="selectbox-o"
                              id="week_span"
                              v-model="week_span"
                            >
                              <option
                                v-for="n in [1,2,3,4]"
                                :key="n"
                                :value="n"
                              >
                                {{ n }}
                              </option>
                            </select>
                            <span class="form-label mx-2">{{ $t('week') }}</span>
                            <svg-icon
                              v-tooltip.top="$t('week_span_info')"
                              type="mdi"
                              class="form-label"
                              :path="iconInformationOutline"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label
                            for="day_of_week"
                            class="col-3 form-label"
                          >
                            {{ $t('day_of_week') }}
                          </label>
                          <div class="col-md-7">
                            <select
                              class="selectbox-o"
                              id="day_of_week"
                              v-model="day_of_week"
                            >
                              <option
                                v-for="n in [1,2,3,4,5,6,7]"
                                :key="n"
                                :value="n"
                              >
                                {{ $helpers.getWeekDay(n) }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </collapse-transition>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    :disabled="canNotEdit"
                    class="button button--primary"
                    @click="tabNavigation('mainTab')"
                  >
                    {{ $t('save') }}
                  </button>
                </div>
              </div>

              <div
                class="tab-pane"
                v-show="tabPane === 'advancedSettingsTab'"
              >
                <div class="form-group form-group--cards">
                  <div class="row">
                    <div class="col-12 col-sm-6">
                      <label
                        for="uploadThumbnail"
                        class="form-label"
                      >{{ $t('upload_catalog_cover') }}</label>
                      <thumbnail-container
                        :class="{ 'is-danger': errors.has('journey.thumbnail') }"
                        :thumbnail-image="journeyThumbnailPreview"
                        vee-rule="required|size:5000|mimes:image/jpeg,image/png"
                        vee-scoop-name="journey"
                        vee-name="thumbnail"
                        @update="thumbnailAdded"
                        class="card card--upload"
                        :is-disable="canNotEdit"
                      />
                      <span
                        v-show="errors.has('journey.thumbnail')"
                        class="help is-danger"
                      >{{ errors.first('journey.thumbnail') }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-6">
                    <div class="swicth-checkbox">
                      <input
                        type="checkbox"
                        name="listOnTheCatalog"
                        id="listOnTheCatalog"
                        v-model="statusCatalog"
                        :disabled="canNotEdit"
                        :true-value="1"
                        :false-value="0"
                      >
                      <label
                        class="swicth-label"
                        for="listOnTheCatalog"
                      >
                        {{ $t('list_on_catalog') }}
                      </label>
                    </div>
                  </div>
                  <div
                    class="col-md-6"
                    v-if="isVibonsAdmin"
                  >
                    <div class="swicth-checkbox">
                      <input
                        type="checkbox"
                        name="setPublic"
                        id="setPublic"
                        v-model="is_public"
                        :true-value="1"
                        :false-value="0"
                      >
                      <label
                        class="swicth-label"
                        for="setPublic"
                      >
                        {{ $t('is_public_journey') }}
                      </label>
                    </div>
                  </div>
                  <div
                    class="col-md-6"
                    v-if="showHideFromLxp"
                  >
                    <div class="swicth-checkbox">
                      <input
                        type="checkbox"
                        id="hideFromLxp"
                        v-model="is_in_lxp_library"
                        :true-value="1"
                        :false-value="0"
                      >
                      <label
                        class="swicth-label"
                        for="hideFromLxp"
                      >
                        {{ $t('is_in_lxp_library') }}
                      </label>
                    </div>
                  </div>
                  <div class="col-md-6 d-flex justify-content-between align-items-center">
                    <div class="swicth-checkbox mt-2">
                      <input
                        type="checkbox"
                        id="can_be_assigned_on_weekends"
                        :disabled="editMode && journeyHaveUsers"
                        v-model="can_be_assigned_on_weekends"
                        :true-value="1"
                        :false-value="0"
                      >
                      <label
                        class="swicth-label"
                        for="can_be_assigned_on_weekends"
                      >
                        {{ $t('can_be_assigned_on_weekends') }}
                      </label>
                    </div>
                    <svg-icon
                      v-tooltip.top="$t('can_be_assigned_on_weekends_info')"
                      type="mdi"
                      class="form-label mx-4"
                      :path="iconInformationOutline"
                    />
                  </div>
                  <div class="col-md-6">
                    <div class="swicth-checkbox">
                      <input
                        type="checkbox"
                        name="selfPaced"
                        id="selfPaced"
                        checked
                        v-model="is_self_paced"
                        :disabled="canNotEdit"
                        :true-value="1"
                        :false-value="0"
                      >
                      <label
                        class="swicth-label"
                        for="selfPaced"
                      >
                        {{ $t('self_paced_option') }}
                      </label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="swicth-checkbox">
                      <input
                        type="checkbox"
                        name="isMandatory"
                        id="isMandatory"
                        checked
                        v-model="is_mandatory"
                        :disabled="canNotEdit"
                        :true-value="1"
                        :false-value="0"
                      >
                      <label
                        class="swicth-label"
                        for="isMandatory"
                      >
                        {{ $t('is_mandatory') }}
                      </label>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="swicth-checkbox">
                      <input
                        type="checkbox"
                        name="use_employment_start_date"
                        id="use_employment_start_date"
                        v-model="use_employment_start_date"
                        :disabled="canNotEdit"
                        :true-value="1"
                        :false-value="0"
                      >
                      <label
                        class="swicth-label"
                        for="use_employment_start_date"
                      >
                        {{ $t('use_employment_start_date') }}
                      </label>
                    </div>
                  </div>
                  <!-- <collapse-transition
                    :duration="500"
                    dimension="height"
                  >
                    <div
                      v-show="is_welcome_notifications_enabled"
                      style="transition: all 1s;"
                      class="row mx-4 my-4"
                    >
                      <div class="form-group row">
                        <label
                          for="pushNotificationHeading"
                          class="col-md-5 form-label"
                        >
                          {{ $t('push_noti_head') }}
                        </label>
                        <div class="col-md-7">
                          <input
                            :class="{ 'is-danger': errors.has('journey.push_notification_title') }"
                            type="text"
                            class="form-control"
                            id="pushNotificationHeading"
                            v-model="push_notification_title"
                            data-vv-name="push_notification_title"
                            data-vv-scope="journey"
                            :data-vv-as="$t('push_noti_head')"
                            v-validate="'max:40'"
                          >
                          <button
                            class="emoji-button"
                            @click="dropdownToggle('title')"
                            v-html="'☺'"
                          />
                          <div
                            class="dropdown"
                            style="z-index: 1000001"
                            v-if="dropdownIndex === 'title'"
                            v-on-clickaway="hideDropdown"
                          >
                            <div
                              class="dropdown__menu emoji-dropdown-title"
                              ref="dropdownBoxtitle"
                            >
                              <VEmojiPicker
                                class="emoji"
                                :pack="emojiPack"
                                :label-search="$t('search_placeholder')"
                                @select="SelectEmojiForTitle"
                              />
                            </div>
                          </div>
                          <span
                            v-if="errors.has('journey.push_notification_title')"
                            class="help is-danger"
                          >{{ errors.first('journey.push_notification_title') }}</span>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          for="pushNotificationBody"
                          class="col-md-5 form-label"
                        >
                          {{ $t('push_noti_body') }}
                        </label>
                        <div class="col-md-7">
                          <textarea
                            :class="{ 'is-danger': errors.has('journey.push_notification_body') }"
                            id="pushNotificationBody"
                            class="form-control"
                            v-model="push_notification_body"
                            data-vv-name="push_notification_body"
                            data-vv-scope="journey"
                            :data-vv-as="$t('push_noti_body')"
                            v-validate="'max:160'"
                          />
                          <button
                            class="emoji-button"
                            @click="dropdownToggle('body')"
                            v-html="'☺'"
                          />
                          <div
                            class="dropdown"
                            style="z-index: 1000001"
                            v-if="dropdownIndex === 'body'"
                            v-on-clickaway="hideDropdown"
                          >
                            <div
                              class="dropdown__menu emoji-dropdown-body"
                              ref="dropdownBoxbody"
                            >
                              <VEmojiPicker
                                class="emoji"
                                :pack="emojiPack"
                                :label-search="$t('search_placeholder')"
                                @select="SelectEmojiForBody"
                              />
                            </div>
                          </div>
                          <span
                            v-if="errors.has('journey.push_notification_body')"
                            class="help is-danger"
                          >{{ errors.first('journey.push_notification_body') }}</span>
                        </div>
                      </div>
                    </div>
                  </collapse-transition> -->
                </div>


                <div class="modal-footer">
                  <button
                    type="button"
                    :disabled="canNotEdit"
                    class="button button--primary"
                    @click="tabNavigation('mainTab')"
                  >
                    {{ $t('save') }}
                  </button>
                </div>
              </div>
              <div
                class="tab-pane"
                v-show="tabPane === 'assignProfileTab'"
              >
                <div class="form-group row">
                  <div class="col-12">
                    <div class="swicth-checkbox">
                      <input
                        type="checkbox"
                        name="is_automatic_assignment_enabled"
                        id="is_automatic_assignment_enabled"
                        v-model="is_automatic_assignment_enabled"
                        :disabled="canNotEdit"
                        :true-value="1"
                        :false-value="0"
                      >
                      <label
                        class="swicth-label"
                        for="is_automatic_assignment_enabled"
                      >
                        {{ $t('profile_assignment_is_enabled') }}
                      </label>
                    </div>
                  </div>
                </div>
                <collapse-transition
                  :duration="500"
                  dimension="height"
                >
                  <div
                    class="form-group row"
                    v-show="is_automatic_assignment_enabled"
                  >
                    <!--eslint-disable-next-line vue/require-v-for-key -->
                    <div
                      v-for="(infotype, index) in listOrderUserInfoTypes"
                      class="col-4 my-2"
                    >
                      <div
                        style="max-width: 200px"
                        :key="index"
                      >
                        <label class="form-label text-center">
                          <!--eslint-disable-next-line vue-i18n/no-dynamic-keys -->
                          {{ $t(infotype === 'title' ? 'the_title': infotype) }}
                        </label>
                        <multi-select
                          :name="infotype"
                          :allow-empty="true"
                          :multiple="true"
                          :placeholder="$t('select_placeholder_taggable')"
                          :tag-placeholder="$t('tag_placeholder')"
                          :deselect-label="$t('deselect_placeholder')"
                          :select-label="$t('enter_select_placeholder')"
                          :selected-label="$t('selected_label')"
                          :show-labels="true"
                          :taggable="true"
                          @tag="(t) => addTag(t, infotype)"
                          :clear-on-select="true"
                          v-model="selectedUserInfoTypes[infotype]"
                          :options="userInfoTypes[infotype]"
                          class="selectbox selectbox--secondary"
                        />
                      </div>
                    </div>
                  </div>
                </collapse-transition>
                <div class="modal-footer">
                  <button
                    type="button"
                    :disabled="canNotEdit"
                    class="button button--primary"
                    @click="tabNavigation('mainTab')"
                  >
                    {{ $t('save') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { directive as onClickaway } from 'vue-clickaway';
// import VEmojiPicker from 'v-emoji-picker';
import packData from 'v-emoji-picker/data/emojis';
import Draggable from 'vuedraggable';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import CollapseTransition from '@ivanv/vue-collapse-transition';
import Multiselect from 'vue-multiselect';
import {
  API_JOURNEY_ITEMS_ALL_UPDATE,
  API_JOURNEY_ITEM_ORDER,
  API_JOURNEY_SCHEDULED_NOTIFICATION_UPDATE,
  API_SERVER_TIME_FORMAT,
} from '@/helpers/config';
import API from '@/services/';
import 'vue2-datepicker/locale/en';
import 'vue2-datepicker/locale/tr';
import 'vue2-datepicker/index.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import ThumbnailContainer from '@/components/Thumbnail.vue';
import IconContainer from '@/components/elements/Icon.vue';
import IconCalander from '@/components/icons/Calander.vue';
import IconNotification from '@/components/icons/Notification.vue';
import IconSetting from '@/components/icons/Setting.vue';
import IconHandle from '@/components/icons/Handle.vue';
import FullCalendarComponent from '@/components/FullCalendar.vue';
import { mdiInformationOutline } from '@mdi/js';
import SvgIcon from '@jamescoyle/vue-icon';

Vue.component('Multiselect', Multiselect);

export default {
  name: 'ModalJourneyV2',
  inject: ['$validator'],

  data() {
    return {
      emojiPack: packData,
      dropdownIndex: null,
      isPending: false,
      showPanel: false,
      showCalander: false,
      pageIsLoading: true,
      formSending: false,
      journey_id: null,
      customer_id: this.$store.state.auth.customerId,
      selectedLanguage: {
        name: 'tr',
        value: 'Türkçe',
      },
      languageOptions: [
        {
          name: 'en',
          value: 'English',
        },
        {
          name: 'es',
          value: 'Español',
        },
        {
          name: 'fr',
          value: 'Français',
        },
        {
          name: 'tr',
          value: 'Türkçe',
        },
      ],
      journeyType: 0,
      info: ' ',
      is_self_paced: 0,
      subtype_id: 1,
      journeyName: '',
      isDragging: false,
      journeyNameFriendly: '',
      is_automatic_assignment_enabled: 0,
      file: null,
      journeyThumbnail: null,
      journeyThumbnailPreview: '',
      statusCatalog: 1,
      newJourney: null,
      is_public: 0,
      is_in_lxp_library: 1,
      can_be_assigned_on_weekends: 1,
      showHideFromLxp: false,
      activation_date: null,
      deadline: null,
      record: {},
      firstRun: true,
      previewList: [],
      is_welcome_notifications_enabled: 0,
      push_notification_body: '',
      push_notification_title: '',
      isValidated: false,
      errorBag: [],
      tempDataList: [],
      rescheduleList: [],
      calenderEvents: [],
      totalEventDays: 1,
      firstItemDate: null,
      runOnce: true,
      runTempOnce: true,
      hasReminder: false,
      hasReminderBefore: false,
      employee_title: this.$t('employee_title_text'),
      employee_content: this.$t('employee_content_text'),
      weeks_after_last_content: 1,
      week_span: 1,
      day_of_week: 1,
      duplicated_journey_id: null,
      use_employment_start_date: 0,
      is_mandatory: 0,
      as_customer_id: null,
      userInfoTypes: null,
      selectedUserInfoTypes: {
        title: [], division: [], unit: [], team: [], personnel_group: [], company_name: [], business_name: [], business_group: [], position: [], operation: [], work_station: [],
      },
      listOrderUserInfoTypes: ['title', 'division', 'unit', 'team', 'personnel_group', 'company_name', 'business_name', 'business_group', 'position', 'operation', 'work_station'],
    };
  },

  components: {
    'date-picker': DatePicker,
    'full-calendar': FullCalendarComponent,
    'collapse-transition': CollapseTransition,
    'icon-container': IconContainer,
    'icon-calander': IconCalander,
    'icon-setting': IconSetting,
    'icon-notification': IconNotification,
    'thumbnail-container': ThumbnailContainer,
    'multi-select': Multiselect,
    'icon-handle': IconHandle,
    'draggable-comp': Draggable,
    // VEmojiPicker,
    SvgIcon,
  },
  directives: {
    'on-clickaway': onClickaway,
  },
  created() {
    console.time('journeymodal');
    this.multiProcessProgressBar = true;

    this.$store.dispatch('modal/GET_USER_INFO_TYPES', { customerId: this.$store.state.auth.customerId }).then((types) => {
      if (types) {
        this.userInfoTypes = this.cleanObject(types);
      }
    });

    if (this.isVibonsAdmin || (this.$store.state.auth.userInfo && this.$store.state.auth.userInfo.role === 'customer-admin' && this.$store.state.auth.userInfo.customer && this.$store.state.auth.userInfo.customer.services && this.$store.state.auth.userInfo.customer.services.length && this.$store.state.auth.userInfo.customer.services.includes('LXP'))) {
      this.showHideFromLxp = true;
    }

    if (this.userLang === 'en') {
      this.selectedLanguage = {
        name: 'en',
        value: 'English',
      };
    }
    if (this.userLang === 'tr') {
      this.selectedLanguage = {
        name: 'tr',
        value: 'Türkçe',
      };
    }
  },
  async mounted() {
    try {
      if (this.journeyId && this.editMode) {
        this.journey_id = this.journeyId;
        this.journeyName = this.journeyDetail?.name;
        this.is_public = this.journeyDetail?.is_public;
        this.as_customer_id = this.journeyDetail?.as_customer_id;
        this.is_in_lxp_library = this.journeyDetail?.is_in_lxp_library;
        this.is_mandatory = this.journeyDetail?.is_mandatory;
        this.push_notification_title = this.journeyDetail?.options?.notification?.title;
        this.can_be_assigned_on_weekends = this.journeyDetail?.options?.can_be_assigned_on_weekends === undefined ? 1 : this.journeyDetail?.options?.can_be_assigned_on_weekends;
        this.push_notification_body = this.journeyDetail?.options?.notification?.body;
        this.is_welcome_notifications_enabled = this.journeyDetail?.options?.notification?.is_enabled;
        this.journeyNameFriendly = this.journeyDetail?.friendly_name;
        this.is_self_paced = this.journeyDetail?.is_self_paced;
        this.selectedJourneyType = this.journeyDetail?.type || 0;
        if (this.journeyDetail?.options) {
          if (this.journeyDetail?.options?.lang) {
            if (this.journeyDetail?.options?.lang === 'en') {
              this.selectedLanguage = {
                name: 'en',
                value: 'English',
              };
            } else if (this.journeyDetail?.options?.lang === 'tr') {
              this.selectedLanguage = {
                name: 'tr',
                value: 'Türkçe',
              };
            }
          }
          console.log('this.journeyDetail?.options:', this.journeyDetail?.options);

          console.log('this.journeyDetail?.options?.is_list_on_the_catalog_enabled:', this.journeyDetail?.options?.is_list_on_the_catalog_enabled);
          this.statusCatalog = this.journeyDetail?.options?.is_list_on_the_catalog_enabled;
          console.log('statusCatalog:', this.statusCatalog);
          if (this.journeyDetail?.options?.assignment && this.selectedUserInfoTypes) {
            this.use_employment_start_date = this.journeyDetail?.options?.assignment?.use_employment_start_date;
            if (this.journeyDetail?.options?.assignment?.automatic) {
              this.is_automatic_assignment_enabled = this.journeyDetail?.options?.assignment?.automatic?.is_enabled;
              this.selectedUserInfoTypes.title = this.journeyDetail?.options?.assignment?.automatic?.title ? this.journeyDetail?.options?.assignment?.automatic?.title.split(',') : [];
              this.selectedUserInfoTypes.division = this.journeyDetail?.options?.assignment?.automatic?.division ? this.journeyDetail?.options?.assignment?.automatic?.division.split(',') : [];
              this.selectedUserInfoTypes.unit = this.journeyDetail?.options?.assignment?.automatic?.unit ? this.journeyDetail?.options?.assignment?.automatic?.unit.split(',') : [];
              this.selectedUserInfoTypes.team = this.journeyDetail?.options?.assignment?.automatic?.team ? this.journeyDetail?.options?.assignment?.automatic?.team.split(',') : [];
              this.selectedUserInfoTypes.personnel_group = this.journeyDetail?.options?.assignment?.automatic?.personnel_group ? this.journeyDetail?.options?.assignment?.automatic?.personnel_group.split(',') : [];
              this.selectedUserInfoTypes.company_name = this.journeyDetail?.options?.assignment?.automatic?.company_name ? this.journeyDetail?.options?.assignment?.automatic?.company_name.split(',') : [];
              this.selectedUserInfoTypes.business_name = this.journeyDetail?.options?.assignment?.automatic?.business_name ? this.journeyDetail?.options?.assignment?.automatic?.business_name.split(',') : [];
              this.selectedUserInfoTypes.business_group = this.journeyDetail?.options?.assignment?.automatic?.business_group ? this.journeyDetail?.options?.assignment?.automatic?.business_group.split(',') : [];
              this.selectedUserInfoTypes.position = this.journeyDetail?.options?.assignment?.automatic?.position ? this.journeyDetail?.options?.assignment?.automatic?.position.split(',') : [];
              this.selectedUserInfoTypes.operation = this.journeyDetail?.options?.assignment?.automatic?.operation ? this.journeyDetail?.options?.assignment?.automatic?.operation.split(',') : [];
              this.selectedUserInfoTypes.work_station = this.journeyDetail?.options?.assignment?.automatic?.work_station ? this.journeyDetail?.options?.assignment?.automatic?.work_station?.split(',') : [];
            }
          }
        }
        if (this.journeyDetail?.cover_url) {
          this.journeyThumbnailPreview = this.journeyDetail?.cover_url;
          this.journeyThumbnail = await this.$helpers.getThumbnailImage(this.journeyThumbnailPreview);
        }
      }
      if (!this.editMode) {
        this.activation_date = moment().startOf('isoWeek').isoWeekday(1).add(1, 'seconds');
        this.setDefaultThumbnail();
        this.push_notification_title = this.$t('default_push_noti_head_msg', this.selectedLanguage.name);
        this.push_notification_body = this.$t('default_push_noti_body_msg', this.selectedLanguage.name, { JOURNEY_NAME: this.journeyName });
      }
      this.showCalander = true;
    } catch (error) {
      console.error(error);
    } finally {
      this.pageIsLoading = false;
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 1,
        disabled: false,
        ghostClass: 'newghost',
        sort: true,
        forceFallback: !!(/Edge/.test(navigator.userAgent)),
      };
    },
    iconInformationOutline() {
      return mdiInformationOutline;
    },
    tabPane: {
      get() {
        return this.$store.state.modal.tabPane;
      },
      set(newValue) {
        this.$store.state.modal.tabPane = newValue;
      },
    },
    isNotDirty() {
      if (this.rescheduleList.length > 0) {
        return false;
      }
      return this.dash.isEqual(this.tempDataList, this.previewList);
    },
    firstDateInItemList() {
      const items = this.previewList;
      if (items && items.length > 0) {
        items.sort((a, b) => {
          if (a.content.activation_date > b.content.activation_date) {
            return 1;
          }
          if (a.content.activation_date < b.content.activation_date) {
            return -1;
          }
          return 0;
        });
        if (items[0].content.sub_type !== 'LIVE_EVENT' && items[0].content.sub_type !== 'MEETING') {
          console.log('first item is neither live event nor meeting so,');
          if (moment(items[0].content.activation_date, API_SERVER_TIME_FORMAT).isBefore(moment())) {
            console.log('Date is before now:', items[0].content.activation_date);
            return moment().add(5, 'minutes').format(API_SERVER_TIME_FORMAT);
          }
          console.log('item date is subtracted 5 min from the first one');
          return moment(items[0].content.activation_date, API_SERVER_TIME_FORMAT).subtract(5, 'minutes').format(API_SERVER_TIME_FORMAT);
        }
        console.log('first item is either live event or meeting so get the date 5 min later from now');
        return moment().add(5, 'minutes').format(API_SERVER_TIME_FORMAT);
      }
      return null;
    },

    userLang() {
      if (this.$store?.state?.auth?.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },

    journeyItems() {
      return this.$store?.state?.modal?.journeyItems;
    },

    launchedFrom: {
      get() {
        return this.$store?.state?.modal?.launchedFrom;
      },
      set(newValue) {
        this.$store.state.modal.launchedFrom = newValue;
      },
    },

    canBeReschedule() {
      // if (this.editMode && this.journeyHaveUsers && this.launchedFrom === 'journeyitems') {
      //   return true;
      // }
      return false;
    },
    journeyHaveUsers() {
      if (this.journeyDetail && this.journeyDetail?.assigned_users_count > 0) {
        return true;
      }
      return false;
    },
    canNotEdit() {
      if (this.is_public === 0) {
        return false;
      }
      if (this.is_public && this.isVibonsAdmin) {
        return false;
      }
      return true;
    },

    journeyId() {
      return this.$store.state.modal.journeyId;
    },

    editMode() {
      return this.$store?.state?.modal?.editMode;
    },

    isVibonsAdmin() {
      return this.$store.state.auth.isVibonsAdmin;
    },

    journeyDetail() {
      if (this.$store?.state?.modal?.journeyDetail) {
        return this.$store.state.modal.journeyDetail;
      }
      return null;
    },
    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
  },

  watch: {

    journeyItems: {
      handler(newVal) {
        if (newVal) {
          let inc = 0;
          let chapter = 0;
          const $newval = this.dash.cloneDeep(newVal);
          $newval.map((item) => {
            const $item = item;
            if ($item.content.sub_type === 'CHAPTER') {
              inc = 0;
              chapter += 1;
              $item.content.inChapter = chapter;
              $item.content.order = null;
              return $item;
            }
            $item.content.inChapter = chapter;
            $item.content.order = inc;
            inc += 1;
            return $item;
          });
          this.previewList = this.dash.cloneDeep(Object.values($newval)); // .filter(item => item.content.sub_type !== 'CHAPTER')
          this.tempDataList = this.dash.cloneDeep(Object.values($newval));
        }
      },
      immediate: true,
      deep: true,
    },

    tempDataList: {
      handler(newVal) {
        if (newVal) {
          this.getActivationDates();
        }
      },
      immediate: true,
      deep: true,
    },

    firstDateInItemList: {
      handler(newVal) {
        console.log('Based on the calculation first item date will be: ', newVal);
      },
      immediate: true,
    },

    rescheduleList: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && this.runOnce) {
          let indexToRemove = null;
          newVal.forEach((item, index) => {
            const result = this.dash.filter(this.journeyItems, {
              content: {
                activation_date: item.content.activation_date,
                id: item.content.id,
                is_push_enabled: item.content.is_push_enabled,
                is_email_enabled: item.content.is_email_enabled,
              },
            });
            if (result.length > 0) {
              indexToRemove = index;
            }
          });
          if (indexToRemove !== null) {
            newVal.splice(indexToRemove, 1);
          }
          this.runOnce = false;
          setTimeout(() => {
            this.runOnce = true; // to prevent loop
          }, 10);
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    cleanObject(obj) {
    // Iterate through each key in the object
      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj) {
        // Check if the property is an array
        if (Array.isArray(obj[key])) {
          // Filter out null, empty strings, and the string "null"
          // eslint-disable-next-line no-param-reassign
          obj[key] = obj[key].filter(value => value !== null && value !== '' && value !== ' ' && value !== 'null');
        }
      }
      return obj;
    },
    tabNavigation(value) {
      const $value = value;
      this.tabPane = $value;
    },

    async cardItemMoved(evt) {
      const index = evt.moved.newIndex;
      const movedItem = evt.moved.element;
      let newDateTime = null;
      console.log('method:->cardItemMoved new index:', index, 'id:', movedItem);
      if (index > 0) {
        newDateTime = this.tempDataList[index - 1].content.activation_date;
      } else {
        newDateTime = this.tempDataList[1].content.activation_date;
      }
      if (newDateTime && movedItem) {
        movedItem.content.activation_date = newDateTime;
        console.log('new date for the item:', newDateTime);
      }
    },

    edit(index, type = null, date = null, location = null) {
      console.log('type:->', type);
      if (type !== 'manuel') {
        let movedItemId = null;
        if (type === 'time' || type === null) {
          if (location === 'activation_date') {
            this.tempDataList[index].content.activation_date = date;
            movedItemId = this.tempDataList[index].content.id;
          }
          if (location === 'starting_date') {
            this.tempDataList[index].content.starting_date = date;
            movedItemId = this.tempDataList[index].content.id;
          }
          this.tempDataList.sort((a, b) => {
            if (moment(a.content.activation_date).format(API_SERVER_TIME_FORMAT) > moment(b.content.activation_date).format(API_SERVER_TIME_FORMAT)) {
              return 1;
            }
            if (moment(a.content.activation_date).format(API_SERVER_TIME_FORMAT) < moment(b.content.activation_date).format(API_SERVER_TIME_FORMAT)) {
              return -1;
            }
            return 0;
          });
          let movedIndex = null;
          if (movedItemId) {
            movedIndex = this.tempDataList.findIndex(item => item.content.id === movedItemId);
            if (movedIndex != null) {
              const elem = document.querySelector(`.journey-preview-list__element:nth-child(${movedIndex + 1})`);
              if (elem) {
              // eslint-disable-next-line no-unused-vars
                const callbackFunc = (entries, observer) => {
                  entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                      entry.target.classList.add('itsmovedItem');
                      setTimeout(() => entry.target.classList.remove('itsmovedItem'), 1500);
                    }
                  });
                };
                const ioptions = {
                  root: null,
                  rootMargin: '0px',
                  threshold: 0.3,
                };
                const observer = new IntersectionObserver(callbackFunc, ioptions);
                observer.observe(elem);
              }
            }
          }
        }
        const rescheduleListClone = [...this.rescheduleList];
        const found = rescheduleListClone.some(item => item.content.id === this.tempDataList[index].content.id);
        if (!found) {
          this.rescheduleList.push(this.tempDataList[index]);
        } else {
          this.rescheduleList = rescheduleListClone.map((item) => {
            if (item.content.id === this.tempDataList[index].content.id) {
              return type ? { ...this.tempDataList[index], ...item } : { ...item, ...this.tempDataList[index] };
            }
            return item;
          });
        }
      }
    },

    notBeforeToday(date) {
      const now = new Date();
      now.setMonth(now.getMonth() - 3);
      return date < new Date(now.getFullYear(), now.getMonth(), now.getDate());
    },

    notBeforeTime(date) {
      const now = new Date();
      now.setMonth(now.getMonth() - 3);
      if (moment(new Date(), 'YYYY-MM-DD').isSame(moment(this.beginDate, this.userLang === 'en' ? 'MM.DD.YYYY' : 'DD.MM.YYYY').format('YYYY-MM-DD'), 'day')) {
        return date < new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds());
      }
      return false;
    },

    checkDateValidation(date) {
      return moment(date, API_SERVER_TIME_FORMAT).isBefore(moment());
    },

    SelectEmojiForTitle(dataEmoji) {
      this.push_notification_title += dataEmoji.emoji;
    },

    SelectEmojiForBody(dataEmoji) {
      this.push_notification_body += dataEmoji.emoji;
    },

    dropdownToggle(index) {
      this.dropdownIndex = index;
      setTimeout(() => {
        if (this.dropdownIndex && this.$refs[`dropdownBox${index}`]) {
          const elem = this.$refs[`dropdownBox${index}`];

          if (elem) {
            const isOut = this.$helpers.isOutOfScreen(elem);
            if (isOut.right) {
              elem.classList.add('right');
            } else if (isOut.left) {
              elem.classList.add('left');
            } else if (isOut.bottom) {
              elem.classList.add('bottomjourney');
            } else if (isOut.top) {
              elem.classList.add('top');
            }
          }
        }
      }, 50);
    },

    async getActivationDates() {
      const items = await this.dash.cloneDeep(this.tempDataList);
      items.sort((a, b) => {
        if (moment(a.content.activation_date).format(API_SERVER_TIME_FORMAT) > moment(b.content.activation_date).format(API_SERVER_TIME_FORMAT)) {
          return 1;
        }
        if (moment(a.content.activation_date).format(API_SERVER_TIME_FORMAT) < moment(b.content.activation_date).format(API_SERVER_TIME_FORMAT)) {
          return -1;
        }
        return 0;
      });
      if (items && items[0]) {
        this.firstItemDate = items[0].content.activation_date;
        const lastItem = [...items].pop();
        if (lastItem) {
          const now = moment();
          const finalday = moment(lastItem.content.activation_date, API_SERVER_TIME_FORMAT);
          this.totalEventDays = finalday.diff(now, 'days') + 2; // we have to include the first and last day in calculation
        }
      }
      this.updateCalenderEvents(items);
    },

    async updateCalenderEvents(newVal) {
      this.calenderEvents = [];
      await Promise.all(newVal.map(async (v) => {
        // console.log('subtype:', v.content.sub_type, v.content.item.id, v.content.name, v.content.id);
        const ss = {
          // eslint-disable-next-line no-nested-ternary
          id: v.content.id,
          content_id: (v.type === 'journey_item' && v.content && v.content.as_content_id) ? v.content.as_content_id : v.content.id,
          notif_preview: (v.type !== 'journey_item'),
          isnotif: (v.type !== 'journey_item'),
          all_content: (v.type !== 'journey_item') ? v.content : '',
          // eslint-disable-next-line no-nested-ternary
          color: (v.type === 'journey_item') ? ((v.content.sub_type === 'LIVE_EVENT' || v.content.sub_type === 'MEETING') ? '#FF6347' : (v.content.sub_type === 'CHAPTER') ? '#e6d' : '#1976d2') : '#2dcf85',
          subtype: this.titleCase(this.$helpers.getSubTypeName(v.content.sub_type, this.userLang)),
          title: (v.type === 'journey_item') ? v.content?.name : v.content?.title,
          allDay: false,
          start: v.content.activation_date,
          order: v.content.order,
        };
        this.calenderEvents.push(ss);
      }));
    },

    titleCase(str) {
      if (str) {
        const mystr = str.toLowerCase().split(' ');
        for (let i = 0; i < mystr.length; i += 1) {
          mystr[i] = mystr[i].charAt(0).toUpperCase() + mystr[i].slice(1);
        }
        return mystr.join(' ');
      }
      return '';
    },

    setDateFromDrop(dropitem) {
      let found = false;
      if (dropitem.isnotif) {
        found = this.tempDataList.find((item) => {
          if (item.content.id) {
            return item.content.id === dropitem.id;
          }
          return false;
        });
      } else {
        found = this.tempDataList.find((item) => {
          if (item.content && item.content.id && item.content.id.length !== 36) {
            return Number(item.content.id) === Number(dropitem.id);
          }
          return false;
        });
      }
      if (found) {
        found.content.activation_date = dropitem.newdatetime;
      }
      this.getActivationDates();
      const rescheduleListClone = [...this.rescheduleList];
      const alreadyEnListed = rescheduleListClone.some(item => item.content.id === found.content.id);
      if (!alreadyEnListed) {
        this.rescheduleList.push(found);
      } else {
        this.rescheduleList = rescheduleListClone.map((item) => {
          if (item.content.id === found.content.id) {
            return { ...item, ...found };
          }
          return item;
        });
      }
    },

    hideDropdown() {
      this.dropdownIndex = null;
    },

    setDragging(value) {
      if (!value) {
        this.$nextTick(() => {
          setTimeout(() => {
            console.log('dragging finito');
          }, 10);
        });
      }
    },

    showDatePicker(elem) {
      this.$refs[`${elem}`][0].openPopup();
    },

    async sendingOrders() {
      console.log('SENDING ORDER....');
      const itemIds = await this.tempDataList.filter(f => f.content.sub_type !== 'CHAPTER' && f.type === 'journey_item').map(list => list.content.id);
      const chapterIds = await this.tempDataList.filter(f => f.content.sub_type !== 'CHAPTER' && f.type === 'journey_item').map(list => list.content.inChapter);
      const chapterOrderIds = await this.tempDataList.filter(f => f.content.sub_type === 'CHAPTER' && f.type === 'journey_item').map(list => list.content.id).filter(s => s.length === 36);
      const notifOrderIds = await this.tempDataList.reduce((acc, curr, index, arr) => {
        if (curr.type === 'item_scheduled_notification') {
          let id = null;
          let inc = 1;
          do {
            if (arr[index - inc].type === 'journey_item') {
              // eslint-disable-next-line prefer-destructuring
              id = arr[index - inc].content.id;
            }
            // eslint-disable-next-line no-plusplus
            inc++;
          }
          while (id == null);
          acc.push(`${curr.content.id}:${id}`);
        }
        return acc;
      }, []);
      console.log('sendingOrders:', itemIds);
      if (itemIds.length > 0) {
        this.sendItemOrders(itemIds.join(','), chapterIds.join(','), chapterOrderIds.join(','), notifOrderIds.join(','));
      }
    },

    sendItemOrders(itemIds, chapterIds, chapterOrderIds, notifOrderIds) {
      const formData = new FormData();
      formData.append('journey_id', this.$route.params.journeyId);
      formData.append('journey_items', itemIds);
      if (chapterIds) {
        formData.append('chapters', chapterIds);
      }

      if (chapterOrderIds) {
        formData.append('chapter_order', chapterOrderIds);
      }

      if (notifOrderIds) {
        formData.append('mobile_notification_orders', notifOrderIds);
      }
      API.post(API_JOURNEY_ITEM_ORDER, formData)
        .then(() => {
          console.log('orders sent:', formData.values);
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
    },

    async rescheduleJourney() {
      const arr = Object.values(this.rescheduleList);
      console.log('Arr: ', arr);
      const $journeyId = this.$store.state.modal.journeyId || this.$route.params.journeyId;
      const responseChaine = [];
      const successMsg = this.$t('processed_success');
      const self = this;
      let regularItemPostData = null;
      let notifFormData = null;
      let notifIndex = 0;
      let hasregular = false;
      let hasnotif = false;
      self.isPending = true;
      this.$store.commit('modal/setPageToRefresh', 'journeyitems');
      regularItemPostData = new FormData();
      regularItemPostData.append('journey_id', $journeyId);
      notifFormData = new FormData();
      notifFormData.append('journey_id', $journeyId);
      // eslint-disable-next-line no-restricted-syntax
      for await (const [index, item] of arr.entries()) {
        console.log(index, item);
        const $contentId = (item.content && item.content.content_id) ? item.content.content_id : null;
        const $journeyItemId = item.content.id;
        const $contentName = item.content.name ? item.content?.name : item.content?.title;
        const $email = item.content.is_email_enabled;
        const $notif = item.content.is_push_enabled;
        const $date = moment(item.content.activation_date, API_SERVER_TIME_FORMAT).format(API_SERVER_TIME_FORMAT);
        if (Object.prototype.hasOwnProperty.call(item.content, 'notify_dt')) {
          hasnotif = true;
          notifFormData.append(`notifies[${notifIndex}]`, $journeyItemId);
          notifFormData.append(`notify_dt[${notifIndex}]`, $date);
          notifIndex += 1;
        } else {
          hasregular = true;
          regularItemPostData.append(`activation_dt[${$journeyItemId}]`, $date);
          regularItemPostData.append(`is_email_enabled[${$journeyItemId}]`, $email);
          regularItemPostData.append(`is_push_enabled[${$journeyItemId}]`, $notif);
        }

        if (item.content.sub_type === 'LIVE_EVENT') {
          const liveEventPayload = { contentLanguage: { name: '' } };
          liveEventPayload.content_id = $contentId;
          liveEventPayload.name = $contentName;
          if (this.firstDateInItemList) {
            liveEventPayload.activation_date = this.firstDateInItemList;
          }
          liveEventPayload.starting_date = item.content.starting_date;
          liveEventPayload.is_public = item.content.is_public;
          liveEventPayload.contentLanguage.name = this.userLang;
          liveEventPayload.isRescheduling = true;

          this.$store.dispatch('modal/UPDATE_LIVE_EVENT_CONTENT', liveEventPayload).then(() => {
            if (!self.formError) {
              self.$snotify.success(successMsg);
            } else {
              self.formError = false;
              self.formSending = false;
            }
          });
        }

        if (item.content.sub_type === 'MEETING') {
          const meetingPayload = { contentLanguage: { name: '' } };
          meetingPayload.content_id = $contentId;
          meetingPayload.name = $contentName;
          if (this.firstDateInItemList) {
            meetingPayload.activation_date = this.firstDateInItemList;
          }
          meetingPayload.starting_date = item.content.starting_date;
          meetingPayload.is_public = item.content.is_public;
          meetingPayload.contentLanguage.name = this.userLang;
          meetingPayload.isRescheduling = true;

          this.$store.dispatch('modal/UPDATE_MEETING_CONTENT', meetingPayload).then(() => {
            if (!self.formError) {
              self.$snotify.success(successMsg);
            } else {
              self.formError = false;
              self.formSending = false;
            }
          });
        }
      }
      console.log('regularItemPostData:', regularItemPostData);
      if (regularItemPostData && hasregular && !regularItemPostData.entries().next().done) {
        await API.post(API_JOURNEY_ITEMS_ALL_UPDATE, regularItemPostData)
          .then((response) => {
            if (response.status === 200) {
              responseChaine.push(1);
            } else {
              responseChaine.push(0);
            }
          })
          .catch((error) => {
            self.formSending = false;
            self.isPending = false;
            self.$helpers.displayError(error);
          }).then(() => {
            if (!responseChaine.includes(0)) {
              self.$snotify.success(self.$t('reschedule_success'));
            }
          });
      }
      if (notifFormData && hasnotif && !notifFormData.entries().next().done) {
        await API.post(API_JOURNEY_SCHEDULED_NOTIFICATION_UPDATE, notifFormData)
          .then((response) => {
            if (response.status === 200) {
              responseChaine.push(1);
              self.statusNewJourney = true;
            } else {
              responseChaine.push(0);
            }
          })
          .catch((error) => {
            self.formSending = false;
            self.isPending = false;
            self.$helpers.displayError(error);
          }).then(() => {
            if (!responseChaine.includes(0)) {
              self.$snotify.success(self.$t('reschedule_success'));
            }
          });
      }
      self.sendingOrders();
      setTimeout(() => {
        self.$store.commit('modal/REFRESH_PAGE', true);
        self.closeModal();
      }, 200);
    },

    async setDefaultThumbnail() {
      const defaultThumbnail = await this.$helpers.getThumbnail('journey-default-thumbnail');
      if (defaultThumbnail) {
        this.journeyThumbnailPreview = defaultThumbnail.thumbnailPreview;
        this.journeyThumbnail = defaultThumbnail.thumbnail;
      }
    },
    addTag(newTag, infotype) {
      this.selectedUserInfoTypes[infotype].push(newTag);
      this.userInfoTypes[infotype].push(newTag);
    },
    thumbnailAdded(file) {
      if (file) {
        [this.journeyThumbnailPreview, this.journeyThumbnail] = file;
      } else {
        this.journeyThumbnailPreview = null;
        this.journeyThumbnail = null;
      }
    },

    iconAdded(file) {
      if (file) {
        [this.journeyIconPreview, this.journeyIcon] = file;
      } else {
        this.journeyIconPreview = null;
        this.journeyIcon = null;
      }
    },

    closeModal() {
      this.$store.commit('modal/toggleModal');
    },

    updateName(value) {
      if (this.$store.state.modal.journeyDetail) {
        this.$store.state.modal.journeyDetail.name = value;
      }
    },

    async checkValidation() {
      let allIsValid = false;
      let journeyIsValid = false;
      await this.$validator.validateAll('all').then((res) => {
        allIsValid = res;
      });
      await this.$validator.validateAll('journey').then((res) => {
        journeyIsValid = res;
      });

      if (allIsValid && journeyIsValid) {
        return true;
      }
      return false;
    },

    updateJourney() {
      this.checkValidation().then((res) => {
        if (res) {
          this.formSending = true;
          this.isPending = true;
          this.$store.commit('modal/setPageToRefresh', 'JourneyV2');
          const self = this;
          const successMsg = this.$t('updated_success');
          this.$store.dispatch('modal/UPDATE_JOURNEY_V2', self.$data).then(() => {
            if (!self.formError) {
              self.$snotify.success(successMsg);
            } else {
              self.formError = false;
              self.formSending = false;
              self.isPending = false;
            }
          });
        }
      });
    },

    createJourney() {
      this.checkValidation().then((res) => {
        if (res) {
          this.formSending = true;
          this.isPending = true;
          const self = this;
          const successMsg = this.$t('created_success');
          this.$store.commit('modal/setPageToRefresh', 'JourneyV2');
          this.$store.dispatch('modal/CREATE_JOURNEY_V2', self.$data).then(() => {
            if (!self.formError) {
              // TODO: ask whether or not this will be implemented --- asked will be in future
              // self.createdNewJourney();
              self.$snotify.success(successMsg);
            }
          }).finally(() => {
            self.formError = false;
            self.formSending = false;
            self.isPending = false;
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep button.mx-btn.mx-datepicker-btn-confirm {
  background: #007bff61;
}
li:hover .button-handle {
  opacity: 1;
}
.button-handle {
  opacity: 0;
  cursor: move;
  svg {
    fill: #727d92;
  }
}
.newghost {
  background: #fff !important;
  border: 1px dashed rgba(114, 125, 146, 1) !important;
  box-shadow: none !important;
  .container-fluid {
    opacity: 0;
  }
}
::v-deep .ilist-move{
  transition: .5s;
}
::v-deep .page-loader {
    z-index: 600;
    position: relative;
    top: 110px;
}

::v-deep .page-is-loading {
  display: inline-block;
  width: 64px;
  height: 64px;
  position: absolute;
  left: calc(50% - 32px);
}

::v-deep .page-is-loading:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #666;
  border-color: #666 transparent #666 transparent;
  animation: page-is-loading 1.2s linear infinite;
}

@keyframes page-is-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.disabled {
  pointer-events: none;
}
.selectbox-o {
  height: 36px;
  color: #666;
  border: 1px solid rgba(114, 125, 146, 0.25);
  border-radius: 4px;
  padding:2px;
}
</style>
