/* eslint-disable @intlify/vue-i18n/no-v-html */
<!-- eslint-disable vue-i18n/no-v-html -->
<!-- eslint-disable vue-i18n/no-raw-text -->
<template>
  <div class="modal-container">
    <div
      class="modal"
      :class="{ 'is-loading': formSending }"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5
              class="modal-title d-inline-block text-truncate"
              style="max-width: 380px;"
            >
              {{ journeyItemDetail.name }}
            </h5>

            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>
          <nav class="navigation navigation--secondary">
            <div class="container">
              <ul
                class="navigation__list nav"
                id="contentCreateTab"
                role="tablist"
              >
                <li class="navigation__item">
                  <button
                    class="navigation__link"
                    :class="{ active: tabPane === 'statisticsTab' }"
                    @click="tabNavigation('statisticsTab')"
                  >
                    {{ $t("statistics") }}
                  </button>
                </li>
                <li
                  class="navigation__item"
                >
                  <button
                    class="navigation__link"
                    :class="{ active: tabPane === 'usersCompletedTab' }"
                    @click="tabNavigation('usersCompletedTab')"
                  >
                    {{ $t("users_completed") }}
                  </button>
                </li>
                <li
                  class="navigation__item"
                >
                  <button
                    class="navigation__link"
                    :class="{ active: tabPane === 'downloadReportTab' }"
                    @click="tabNavigation('downloadReportTab')"
                  >
                    {{ $t("download_report") }}
                  </button>
                </li>
              </ul>
            </div>
          </nav>

          <div class="modal-body">
            <div class="tab-content">
              <div
                class="tab-pane"
                v-show="tabPane === 'statisticsTab'"
              >
                <div class="form form--horizontal">
                  <div class="form-group form-group--cards">
                    <figure class="highcharts-figure">
                      <highcharts
                        class="chart-container"
                        :options="completionChartOptions"
                        ref="completionGauge"
                      />
                      <highcharts
                        class="chart-container"
                        :options="scoreChartOptions"
                        ref="scoreGauge"
                      />
                    </figure>
                  </div>
                </div>

                <div class="modal-footer">
                  <button
                    type="button"
                    class="button button--primary"
                    @click="closeModal"
                  >
                    <span>
                      {{ $t("close") }}
                    </span>
                  </button>
                </div>
              </div>

              <div
                class="tab-pane"
                v-if="tabPane === 'usersCompletedTab'"
              >
                <div class="form form--horizontal">
                  <div class="form-group form-group--cards">
                    <div class="top-group">
                      <div>
                        <filter-perpage
                          @searchFor="setSearchFor"
                          @perPage="setPerPage"
                        />
                      </div>
                    </div>
                    <div class="container">
                      <div class="table-responsive">
                        <div
                          v-if="tableIsLoading"
                          class="lds-dual-ring"
                        />
                        <vue-table
                          :class="{ 'table-fade': tableIsLoading }"
                          ref="myvuetable"
                          :api-url="apiUrl"
                          :http-fetch="myFetch"
                          :api-mode="true"
                          @vuetable:loading="tableIsLoading = true"
                          @vuetable:loaded="tableIsLoading = false"
                          @vuetable:load-error="vuetableLoadError"
                          pagination-path="meta"
                          http-method="get"
                          :append-params="moreParams"
                          :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
                          :fields="fields"
                          :no-data-template="$t('no_data_info')"
                          :css="css.table"
                          :per-page="perPage"
                          :query-params="makeQueryParams"
                          @vuetable:pagination-data="onPaginationData"
                        >
                          <template
                            slot="completion_date"
                            slot-scope="props"
                          >
                            {{ getCurrentLocaleDate(props.rowData.completion_date) }}
                          </template>
                          <template
                            slot="actions"
                            slot-scope="props"
                          >
                            <button
                              class="ui button delete"
                              @click="getUserQuizResult(props.rowData.user_id)"
                            >
                              <icon-container
                                name="search"
                                :width="14"
                                :height="14"
                                color="#727d92"
                                view-box="0 0 14 14"
                                :is-icon-class="false"
                              >
                                <icon-search />
                              </icon-container>
                            </button>
                          </template>
                        </vue-table>
                        <div class="pagination__info">
                          <vuetable-pagination-info
                            :class="{ 'table-fade': tableIsLoading }"
                            ref="paginationInfo"
                            :info-template="$t('pagination_info')"
                            :no-data-template="$t('no_data_info')"
                          />
                          <div class="pagination__items">
                            <vuetable-pagination
                              ref="pagination"
                              :css="css.pagination"
                              @vuetable-pagination:change-page="onChangePage"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal-footer">
                  <button
                    type="button"
                    class="button button--primary"
                    @click="closeModal"
                  >
                    <span>
                      {{ $t("close") }}
                    </span>
                  </button>
                </div>
              </div>

              <div
                class="tab-pane"
                v-if="tabPane === 'downloadReportTab'"
              >
                <div class="form form--horizontal">
                  <div class="form-group form-group--cards">
                    <div class="row form-label">
                      <div class="col-6 my-4">
                        <input
                          type="radio"
                          v-model="currentReport"
                          value="generalquiz"
                          id="generalquiz"
                        >
                        <label
                          for="generalquiz"
                          class="ml-3"
                          v-html="$t('general_quiz_report')"
                        />
                      </div>
                      <div class="col-6 my-4">
                        <input
                          type="radio"
                          v-model="currentReport"
                          value="quizdetail"
                          id="quizdetail"
                        >
                        <label
                          for="quizdetail"
                          class="ml-3"
                          v-html="$t('quiz_detail_report')"
                        />
                      </div>
                      <div class="col-6 mb-4">
                        <input
                          type="radio"
                          v-model="currentUsers"
                          value="onlyjourneyitemusers"
                          id="onlyjourneyitemusers"
                        >
                        <label
                          for="onlyjourneyitemusers"
                          class="ml-3"
                          v-html="$t('only_item_users')"
                        />
                      </div>
                      <div class="col-6 mb-4">
                        <input
                          type="radio"
                          v-model="currentUsers"
                          value="allfirmusers"
                          id="allfirmusers"
                        >
                        <label
                          for="allfirmusers"
                          class="ml-3"
                          v-html="$t('all_firm_users')"
                        />
                      </div>
                      <div class="col-6 mb-4">
                        <input
                          type="radio"
                          v-model="isFirstResult"
                          :value="1"
                          id="isFirstResult1"
                        >
                        <label
                          for="isFirstResult1"
                          class="ml-3"
                          v-html="$t('is_first_result_1')"
                        />
                      </div>
                      <div class="col-6 mb-4">
                        <input
                          type="radio"
                          v-model="isFirstResult"
                          :value="0"
                          id="isFirstResult0"
                        >
                        <label
                          for="isFirstResult0"
                          class="ml-3"
                          v-html="$t('is_first_result_0')"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal-footer">
                  <button
                    type="button"
                    class="button button--primary"
                    @click="downloadReport"
                  >
                    <span
                      v-if="!isPending"
                    >
                      {{ $t('download') }}
                    </span>
                    <div
                      class="lds-ellipsis"
                      v-if="isPending"
                    >
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        @click.stop
        class="modal__mobile-preview"
      >
        <div class="flag">
          <icon-container
            name="flag"
            :width="224"
            :height="21"
            view-box="0 0 224 21"
            :is-icon-class="false"
          >
            <icon-flag />
          </icon-container>
        </div>
        <div
          class="mobile-content bluered-bg"
        >
          <div
            v-if="isLoading"
            class="lds-dual-ring-mobile_preview"
          />
          <div
            v-if="questions && questions.length"
            class="mobile-content__quiz"
          >
            <div class="quiz-preview--container">
              <div
                class="quiz-preview--question"
                v-for="(questionPreview, index) in questions"
                :key="index"
              >
                <div class="quiz-preview--progress">
                  <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                  <span>{{
                    questions
                      ? parseInt((100 / questions.length) * (index + 1), 10) +
                        "% " +
                        $t("completed_")
                      : ""
                  }}</span>
                  <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                  <div class="quiz-progress-bar">
                    <span :style="{ width: (100 / questions.length) * (index + 1) + '%' }" />
                  </div>
                </div>
                <h5 v-html="questionPreview.question" />
                <ul
                  v-if="
                    questionPreview.type === 'standard' && !questionPreview?.choices[0]?.image
                  "
                >
                  <li
                    v-for="(choice, scindex) in questionPreview.choices"
                    :key="scindex"
                  >
                    <span
                      v-if="choice.choice_text"
                      class="d-flex p-2"
                      :class="{ 'is-correct': choice.is_correct == 1, 'is-wrong': (choice.is_selected && !choice.is_correct) }"
                    >
                      <span class="optiontick">
                        {{ $helpers.choiceLetter(scindex) }}
                      </span>
                      {{ choice.choice_text }}
                    </span>
                  </li>
                </ul>
                <ul
                  v-if="
                    questionPreview.type === 'standard' && questionPreview?.choices[0]?.image
                  "
                  class="row"
                >
                  <li
                    v-for="(choice, ccindex) in questionPreview.choices"
                    :key="ccindex"
                    class="col-6 px-1"
                  >
                    <picture
                      class="d-flex flex-column"
                      v-if="choice?.image"
                      :class="{ 'is-correct': choice.is_correct == 1, 'is-wrong': (choice.is_selected && !choice.is_correct) }"
                    >
                      <img
                        :src="choice?.image"
                        alt="Choice Image"
                      >
                      <div
                        v-if="choice.choice_text"
                        class="d-flex p-2"
                        style="mix-blend-mode: difference; font-size: 11px"
                      >
                        {{ choice.choice_text }}
                      </div>
                    </picture>
                  </li>
                </ul>
                <ul
                  v-if="questionPreview.type === 'pairs'"
                  id="pair-choices"
                  class="row"
                >
                  <li
                    v-for="(choice, cindex) in questionPreview.choices"
                    :key="cindex"
                  >
                    <span v-if="choice.choice_text">
                      {{ choice.choice_text }}
                    </span>
                  </li>
                </ul>
                <div class="quiz-preview--button">
                  <button
                    type="button"
                    class="button button--secondary size-sm"
                    @click="previewQuestionNext(index)"
                  >
                    {{ $t("next") }}
                  </button>
                </div>
              </div>
              <div class="quiz-preview--question">
                <div class="quiz-finalpage">
                  <div class="d-flex final-score flex-column justify-content-center">
                    <span class="score-point">{{ $t("score_point") }}</span>
                    <div class="your-score">
                      {{ $t("your_score") }}
                    </div>
                  </div>
                  <div class="completed-text text-center mt-4">
                    {{ $t("quiz_completed") }}
                  </div>
                  <div class="d-flex justify-content-center text-center mt-4">
                    {{ $t("thanks_feedback") }}
                  </div>
                </div>
                <div class="quiz-preview--button">
                  <button
                    type="button"
                    class="button button--secondary size-sm"
                    @click="previewQuestionNext(-1)"
                  >
                    {{ $t("close") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import { directive as onClickaway } from 'vue-clickaway';
import Highcharts from 'highcharts';
import more from 'highcharts/highcharts-more';
import Annotation from 'highcharts/modules/annotations';
import Solidgauge from 'highcharts/modules/solid-gauge';
import FilterPerpage from '@/components/FilterPerpage.vue';
import myTableMixin from '@/helpers/myTableMixin.vue';

import API from '@/services/';
import {
  API_QUIZ_COMPLETION_STATUS, API_QUIZ_COMPLETION_DATATABLE, API_USER_QUIZ_RESULT, API_QUIZ_GENERAL_RESULT, API_QUIZ_DETAIL_RESULT,
} from '@/helpers/config';
import IconContainer from '@/components/elements/Icon.vue';
import IconFlag from '@/components/icons/MobilePreviewFlag.vue';
import IconSearch from '@/components/icons/Search.vue';

import VuetablePagination from '../../components/CustomVuetable/VuetablePagination.vue';
import 'vue-multiselect/dist/vue-multiselect.min.css';

more(Highcharts);
Annotation(Highcharts);
Solidgauge(Highcharts);

export default {
  name: 'ModalQuizDetail',
  mixins: [myTableMixin],
  directives: {
    'on-clickaway': onClickaway,
  },
  data() {
    return {
      formSending: false,
      pageIsLoading: false,
      questionId: [],
      questions: null,
      isPending: false,
      isLoading: false,
      isValidator: true,
      formIsCompleted: true,
      currentReport: 'generalquiz',
      currentUsers: 'onlyjourneyitemusers',
      isFirstResult: 0,
      content_id: null,
      contentCustomerId: null,
      quizCompletionStatusData: null,
      customer_id: this.$store.state.auth.customerId,
      dropdownIndex: null,
      tabPane: 'statisticsTab',
      gaugeOptions: {
        chart: {
          type: 'solidgauge',
        },

        title: null,

        credits: {
          enabled: false,
        },
        pane: {
          center: ['50%', '85%'],
          size: '140%',
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc',
          },
        },

        exporting: {
          enabled: false,
        },

        tooltip: {
          enabled: false,
        },

        // the value axis
        yAxis: {
          stops: [
            [0, '#A2C764'],
          ],
          lineWidth: 0,
          tickWidth: 0,
          minorTickInterval: null,
          tickAmount: 1,
          title: {
            y: -70,
          },
          labels: {
            y: 16,
          },
        },

        plotOptions: {
          solidgauge: {
            dataLabels: {
              y: 20,
              borderWidth: 0,
              useHTML: true,
            },
          },
        },
      },
    };
  },

  watch: {
    modalData(newValue) {
      const $newValue = newValue;
      if ($newValue) {
        if ($newValue.quiz && $newValue.quiz.questions) {
          this.questions = $newValue.quiz.questions;
          this.contentCustomerId = $newValue.customer_id;
          this.content_id = $newValue.id;
        }
        this.pageIsLoading = false;
      }
    },
    tabPane(newval, oldval) {
      if (newval && newval !== 'usersCompletedTab' && oldval === 'usersCompletedTab') {
        this.questions = null;
        this.isLoading = true;
        setTimeout(() => {
          this.questions = this.quizCompletionStatusData.quiz.questions;
          this.isLoading = false;
        }, 1000);
      }
      if (newval === 'statisticsTab') {
        if (this.journeyItemId) {
          this.getCompletionStatus();
        }
      }
    },
    errors: {
      handler(val) {
        const that = val;
        if (val.items === 0) {
          that.isValidator = false;
          that.formIsCompleted = false;
        } else {
          that.isValidator = true;
          that.formIsCompleted = true;
        }
      },
      deep: true,
    },
  },

  components: {
    'icon-container': IconContainer,
    'icon-flag': IconFlag,
    'icon-search': IconSearch,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    'filter-perpage': FilterPerpage,
  },

  filters: {
    uppercase(value) {
      return value.toUpperCase();
    },
  },

  computed: {
    apiUrl() {
      if (API_QUIZ_COMPLETION_DATATABLE && this.journeyItemId) {
        return `${API_QUIZ_COMPLETION_DATATABLE}/${this.journeyItemId}`;
      }
      return '';
    },
    fields() {
      return [
        {
          name: 'user_name',
          title: this.$t('name'),
          width: '30%',
        },
        {
          name: 'score',
          title: this.$t('score'),
          width: '10%',
        },
        {
          name: 'duration',
          title: this.$t('duration'),
          width: '20%',
        },
        {
          name: 'completion_date',
          title: this.$t('completion_date'),
          width: '30%',
        },
        {
          name: 'actions',
          title: this.$t('actions'),
          width: '10%',
        },
      ];
    },
    completionChartOptions() {
      return Highcharts.merge(this.gaugeOptions, {
        yAxis: {
          min: 0,
          max: this.quizCompletionStatusData ? this.quizCompletionStatusData.completed + this.quizCompletionStatusData.not_completed : 0,
          tickInterval: 0,
          title: {
            text: this.$t('status'),
          },
        },

        credits: {
          enabled: false,
        },

        series: [{
          name: this.$t('completed_users'),
          data: [this.quizCompletionStatusData ? this.quizCompletionStatusData.completed : 0],
          dataLabels: {
            format:
                `<div style="text-align:center">
                <span style="font-size:25px">{y}</span><br/>
                <span style="font-size:12px;opacity:0.4">${this.$t('users_completed')}</span>
                </div>`,
          },
        }],
      });
    },
    scoreChartOptions() {
      return Highcharts.merge(this.gaugeOptions, {
        yAxis: {
          min: 0,
          max: 100,
          title: {
            text: this.$t('average_score'),
          },
        },

        series: [{
          name: this.$t('score'),
          data: [this.quizCompletionStatusData ? this.quizCompletionStatusData.average_result : 0],
          dataLabels: {
            format:
                `<div style="text-align:center">
                <span style="font-size:25px">{y}</span><br/>
                <span style="font-size:12px;opacity:0.4">
                ${this.$t('points')}
                </span>
                </div>`,
          },
        }],

      });
    },
    multiProcessProgressBar: {
      get() {
        return this.$store.state.modal.multiProcessProgressBar;
      },
      set(newValue) {
        this.$store.state.modal.multiProcessProgressBar = newValue;
      },
    },

    userLang() {
      if (this.$store.state.auth.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },

    userCustomerId() {
      return this.$store.state.auth.customerId;
    },

    isVibonsAdmin() {
      return this.$store.state.auth.isVibonsAdmin;
    },

    journeyDetail() {
      return this.$store.state.modal.journeyDetail;
    },
    journeyItemId() {
      return this.$store.state.modal.journeyItemId;
    },
    contentId() {
      return this.$store.state.modal.contentId;
    },
    journeyItemDetail: {
      get() {
        return this.$store.state.modal.journeyItemDetail;
      },
      set(newValue) {
        this.$store.commit('modal/setJourneyItemDetail', newValue);
      },
    },

    modalData() {
      return this.$store.state.modal.modalData;
    },

    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
  },

  deactivated() {

  },

  methods: {
    tabNavigation(value) {
      const $value = value;
      this.tabPane = $value;
    },

    closeModal() {
      this.$store.commit('modal/toggleModal');
    },
    getCurrentLocaleDate(dateVal) {
      if (this.userLang) {
        return this.$helpers.getUserLangBasedDateTimeFormat(dateVal, this.userLang, 'userdetail');
      }
      return dateVal;
    },

    downloadReport() {
      let downloadLink = '';
      if (this.currentReport === 'generalquiz') {
        downloadLink = `${API_QUIZ_GENERAL_RESULT}`;
      } else {
        downloadLink = `${API_QUIZ_DETAIL_RESULT}`;
      }
      const obj = {
        modalType: 'modalReportDownload',
        reportDownloadUrl: `${downloadLink}`,
        dateOnly: true,
        reportDownloadPayload: {
          customer_id: this.journeyDetail.customer_id,
          content_id: this.contentId,
          is_first_result: this.isFirstResult,
        },
      };
      if (this.currentUsers === 'onlyjourneyitemusers') {
        obj.reportDownloadPayload.journey_item_id = this.journeyItemId;
      }
      this.$store.commit('modal/toggleModal', false);
      this.$helpers.toggleModal(obj);
    },

    getModalContent() {
      this.pageIsLoading = true;
      // this.$store.dispatch('modal/GET_CONTENT');
    },

    getCompletionStatus() {
      const that = this;
      API.get(`${API_QUIZ_COMPLETION_STATUS}/${this.journeyItemId}`)
        .then((response) => {
          that.quizCompletionStatusData = response.data.quiz_completion_status;
          that.questions = that.quizCompletionStatusData.quiz.questions;
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
    },

    getUserQuizResult(userId) {
      const that = this;
      this.questions = null;
      this.isLoading = true;
      API.get(`${API_USER_QUIZ_RESULT}/${this.journeyItemId}/${userId}`)
        .then((response) => {
          that.isLoading = false;
          console.log('user questions:', response.data.data.quiz_content_resource.questions, response.data);
          that.questions = response.data.data.quiz_content_resource.questions;
        })
        .catch((error) => {
          this.$helpers.displayError(error);
          that.isLoading = false;
          this.questions = this.quizCompletionStatusData.quiz.questions;
        });
    },

    previewQuestionNext(index) {
      const quizPreviewContainer = document.querySelector('.quiz-preview--container');
      const quizPreviewQuestion = document.querySelector('.quiz-preview--question');
      const quizPreviewQuestionsLength = document.querySelectorAll('.quiz-preview--question').length;
      const quizPreviewQuestionWidth = quizPreviewQuestion.offsetWidth;
      const $index = index + 1;
      if (quizPreviewQuestionsLength === $index) {
        quizPreviewContainer.style.transform = 'translateX(0)';
      } else {
        quizPreviewContainer.style.transform = `translateX(-${$index
          * quizPreviewQuestionWidth}px)`;
      }
      this.$helpers.randomizeList();
      if (quizPreviewContainer) {
        quizPreviewContainer.scrollIntoView();
      }
    },

    hideDropdown() {
      this.dropdownIndex = null;
    },

    dropdownToggle(index) {
      this.dropdownIndex = index;
      setTimeout(() => {
        if (this.dropdownIndex && this.$refs[`dropdownBox${index}`]) {
          const elem = this.$refs[`dropdownBox${index}`];

          if (elem) {
            const isOut = this.$helpers.isOutOfScreen(elem);
            if (isOut.right) {
              elem.classList.add('emojiright');
            } else if (isOut.left) {
              elem.classList.add('left');
            } else if (isOut.bottom) {
              elem.classList.add('emojibottom');
            } else if (isOut.top) {
              elem.classList.add('top');
            }
          }
        }
      }, 50);
    },
    async checkQuizValidation() {
      let quizIsValid = false;
      let customCheck = true;

      this.questions.forEach((question) => {
        question.choices.forEach((answer, aindex) => {
          // check empty answer box and delete before validation
          if (question.type === 'standard') {
            if (
              Object.prototype.hasOwnProperty.call(answer, 'image')
              && (answer.choice_text === '' || !answer.choice_text)
            ) {
              if (!answer?.image && question?.choices.length > 1) {
                question.choices.splice(aindex, 1);
              }
            } else if (!answer.choice_text && question.choices.length > 1) {
              question.choices.splice(aindex, 1);
            }
          } else if (
            !answer.choice_text
            && question.type === 'pairs'
            && question.choices.length > 4
          ) {
            question.choices.splice(aindex, 2);
          }
        });
      });

      this.clearErrorMsg();

      if (this.questions.length < 1) {
        // check at least one question exist
        this.$validator.errors.add({
          field: 'question',
          msg: this.$t('quiz_one_question'),
          scope: 'all',
        });
        customCheck = false;
      }

      this.$validator.validateAll().then((res) => {
        quizIsValid = res;
      });
      if (quizIsValid && customCheck) {
        return true;
      }
      return false;
    },

  },

  beforeDestroy() {
    this.$off();
  },

  created() {
    if (this.journeyItemId) {
      this.getCompletionStatus();
    }
  },
};
</script>
<style lang="scss" scoped>

.mobile-content__main {
  position: absolute !important;
  padding-right: 25px;
  p {
    font-family: Helvetica, Arial, sans-serif !important;
    font-size: 0.9rem !important;
  }
}
.mobile-content__title {
  font-family: Helvetica, Arial, sans-serif !important;
}
.modal .mobile-content {
  overflow-y: auto !important;
  font-family: Helvetica, Arial, sans-serif !important;
  z-index: 0;
}

.modal .mobile-content::-webkit-scrollbar {
  width: 0px;
}

.modal .mobile-content::-webkit-scrollbar-track {
  display: none;
}

.mobile-content__info {
  position: absolute;
  bottom: 10px;
  left: 15px;
  color: #fff;
  font-family: Helvetica, Arial, sans-serif !important;
  font-weight: 400;
}

.modal__mobile-preview {
  position: sticky;
  top: 28px;
  transition: 1s;
}
.mobile-content__type {
  font-family: Helvetica, Arial, sans-serif !important;
}

.page-loader {
  z-index: 10100;
  position: absolute;
  top: 15px;
}
.page-is-loading {
  display: inline-block;
  width: 64px;
  height: 64px;
  position: absolute;
  left: calc(50% - 32px);
}
.page-is-loading:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #666;
  border-color: #666 transparent #666 transparent;
  animation: page-is-loading 1.2s linear infinite;
}
@keyframes page-is-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.form--strong .form-label {
  justify-content: flex-start;
}

::v-deep .multiselect__tags {
  min-height: 44px !important;
}

.selectbox {
  width: 100% !important;
  max-width: 400px !important;
}
.thumbnail-wrapper {
  width: 280px;
  height: 328px;
}
textarea.form-control,
textarea.ti-input,
body textarea.mx-input {
  height: 120px;
  resize: none;
}
.noborder-bottom {
  border-bottom: none !important;
}

.highcharts-figure .chart-container {
    width: 300px;
    height: 200px;
    float: left;
}

.highcharts-figure, .highcharts-data-table table {
    width: 600px;
    margin: 0 auto;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #EBEBEB;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}
.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}
.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}
.highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
    padding: 0.5em;
}
.highcharts-data-table thead tr, .highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}
.highcharts-data-table tr:hover {
    background: #f1f7ff;
}

@media (max-width: 600px) {
.highcharts-figure, .highcharts-data-table table {
    width: 100%;
}
.highcharts-figure .chart-container {
    width: 300px;
    float: none;
    margin: 0 auto;
}

}

</style>
