<template>
  <div class="flip-wrapper">
    <div
      id="bb-bookblock"
      class="bb-bookblock"
      v-bookblock="options"
      ref="bookBlock"
    >
      <div
        class="bb-item"
        v-for="(image, index) in images"
        :key="index"
      >
        <img
          class="bb-custom-img"
          :src="typeof image === 'object' ? image?.image : image"
          alt="Flip Page Image"
        >
      </div>
    </div>
    <button
      id="bb-nav-prev"
      v-touch:swipe.bottom="prev"
      @mousedown="prev"
    />
    <button
      id="bb-nav-next"
      v-touch:swipe.top="next"
      @mousedown="next"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import BookBlock from 'vue-bookblock';
import Vue2TouchEvents from 'vue2-touch-events';

Vue.use(Vue2TouchEvents, {
  swipeTolerance: 10,
  tapTolerance: 5,
});
Vue.use(BookBlock);

export default {
  name: 'FlipBlock',

  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      options: {
        // vertical or horizontal flip
        orientation: 'horizontal',
        // speed for the flip transition in ms
        speed: 1000,
        // hasCover. If true the first item will become cover and be centered
        hasCover: false,
      },
    };
  },
  methods: {
    prev() {
      this.bookblock.prev();
    },
    next() {
      this.bookblock.next();
    },
  },
  computed: {
    bookblock() {
      return this.$refs.bookBlock.bookblock;
    },
  },
};
</script>

<style scope>
.flip-wrapper {
width:250px;
height: 535px;
position: absolute;
top: 0px;
left: 0px;
}
.bb-custom-img {
object-fit: contain;
object-position: center center;
height: 100%;
}
#bb-nav-prev {
position: absolute;
top:1px;
width: 100%;
height: 250px;
z-index: 1000;
}
#bb-nav-next {
position: absolute;
bottom:1px;
width: 100%;
height: 250px;
z-index: 1000;
}
.bb-bookblock {
width: 250px;
height: 535px;
margin: 0 auto;
position: relative;
z-index: 100;
-webkit-perspective: 1300px;
perspective: 1300px;
-webkit-backface-visibility: hidden;
backface-visibility: hidden;
}

.bb-page {
position: absolute;
-webkit-transform-style: preserve-3d;
transform-style: preserve-3d;
-webkit-transition-property: -webkit-transform;
transition-property: transform;
}

.bb-vertical .bb-page {
width: 50%;
height: 100%;
left: 50%;
-webkit-transform-origin: left center;
transform-origin: left center;
}

.bb-horizontal .bb-page {
width: 100%;
height: 50%;
top: 50%;
-webkit-transform-origin: center top;
transform-origin: center top;
}

.bb-page > div,
.bb-outer,
.bb-content,
.bb-inner {
position: absolute;
height: 100%;
width: 100%;
top: 0;
left: 0;
-webkit-backface-visibility: hidden;
backface-visibility: hidden;
}

.bb-vertical .bb-content {
width: 200%;
}

.bb-horizontal .bb-content {
height: 200%;
}

.bb-page > div {
width: 100%;
-webkit-transform-style: preserve-3d;
transform-style: preserve-3d;
}

.bb-vertical .bb-back {
-webkit-transform: rotateY(-180deg);
transform: rotateY(-180deg);
}

.bb-horizontal .bb-back {
-webkit-transform: rotateX(-180deg);
transform: rotateX(-180deg);
}

.bb-outer {
width: 100%;
overflow: hidden;
z-index: 999;
}

.bb-overlay,
.bb-flipoverlay {
background-color: rgba(0, 0, 0, 0.7);
position: absolute;
top: 0px;
left: 0px;
width: 100%;
height: 100%;
opacity: 0;
}

.bb-flipoverlay {
background-color: rgba(0, 0, 0, 0.2);
}

.bb-bookblock.bb-vertical > div.bb-page:first-child,
.bb-bookblock.bb-vertical > div.bb-page:first-child .bb-back {
-webkit-transform: rotateY(180deg);
transform: rotateY(180deg);
}

.bb-bookblock.bb-horizontal > div.bb-page:first-child,
.bb-bookblock.bb-horizontal > div.bb-page:first-child .bb-back {
-webkit-transform: rotateX(180deg);
transform: rotateX(180deg);
}

/* Content display */
.bb-content {
background: transparent;
}

.bb-vertical .bb-front .bb-content {
left: -100%;
}

.bb-horizontal .bb-front .bb-content {
top: -100%;
}

/* Flipping classes */
.bb-vertical .bb-flip-next,
.bb-vertical .bb-flip-initial {
-webkit-transform: rotateY(-180deg);
transform: rotateY(-180deg);
}

.bb-vertical .bb-flip-prev {
-webkit-transform: rotateY(0deg);
transform: rotateY(0deg);
}

.bb-horizontal .bb-flip-next,
.bb-horizontal .bb-flip-initial {
-webkit-transform: rotateX(180deg);
transform: rotateX(180deg);
}

.bb-horizontal .bb-flip-prev {
-webkit-transform: rotateX(0deg);
transform: rotateX(0deg);
}

.bb-vertical .bb-flip-next-end {
-webkit-transform: rotateY(-15deg);
transform: rotateY(-15deg);
}

.bb-vertical .bb-flip-prev-end {
-webkit-transform: rotateY(-165deg);
transform: rotateY(-165deg);
}

.bb-horizontal .bb-flip-next-end {
-webkit-transform: rotateX(15deg);
transform: rotateX(15deg);
}

.bb-horizontal .bb-flip-prev-end {
-webkit-transform: rotateX(165deg);
transform: rotateX(165deg);
}

.bb-item {
width: 100%;
height: 100%;
position: absolute;
top: 0;
left: 0;
display: none;
background: transparent;
}

/* No JS */
.no-js .bb-bookblock,
.no-js ul.bb-custom-grid li {
width: auto;
height: auto;
}

.no-js .bb-item {
display: block;
position: relative;
}
</style>
