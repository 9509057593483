<template>
  <div class="page">
    <div class="top-group">
      <div>
        <filter-perpage
          @searchFor="setSearchFor"
          @perPage="setPerPage"
        />
      </div>
      <div>
        <button
          class="button button--secondary text-size-xs size-sm button--icon"
          v-if="tabPane=== 'plan_user_assign'"
          @click="addJourneyUserModal()"
        >
          <icon-container
            name="plusfull"
            view-box="0 0 448 512"
            :width="15"
            :height="16"
            color="#FFF"
            class="mr-2"
            :is-icon-class="false"
          >
            <icon-plusfull />
          </icon-container>
          {{ $t('add_new_user') }}
        </button>
      </div>
    </div>

    <div class="container">
      <div class="table-responsive">
        <div
          v-if="tableIsLoading"
          class="lds-dual-ring"
        />
        <vue-table
          :class="{ 'table-fade': tableIsLoading }"
          ref="myvuetable"
          :api-url="apiUrl"
          :http-fetch="myFetch"
          :api-mode="true"
          @vuetable:loading="tableIsLoading = true"
          @vuetable:loaded="tableIsLoading = false"
          @vuetable:load-error="vuetableLoadError"
          pagination-path="meta"
          http-method="get"
          :append-params="moreParams"
          :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
          :fields="fields"
          :no-data-template="$t('no_data_info')"
          :css="css.table"
          :per-page="perPage"
          :query-params="makeQueryParams"
          @vuetable:pagination-data="onPaginationData"
        >
          <template
            slot="icon"
            slot-scope="props"
          >
            <button
              class="ui button"
              @click="getMobilStatus(props.rowData.id, `ref${props.rowData.id}`)"
              @blur="closeTooltip(`ref${props.rowData.id}`)"
            >
              <icon-container
                :width="13"
                :height="20"
                name="cellphone"
                view-box="0 0 320 512"
                :class="{'disabled': !props.rowData.has_mobile_device_regitered}"
                :color="props.rowData.has_mobile_device_regitered ? 'rgb(8, 206, 25)':'#727d92'"
              >
                <icon-cellphone />
              </icon-container>
            </button>
            <div
              :ref="`ref${props.rowData.id}`"
              class="content-tooltip"
            >
              <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
              <pre>{{ tooltipText ? tooltipText : '⏳' }}</pre>
            </div>
          </template>
          <template
            slot="email"
            slot-scope="props"
          >
            <div
              class="d-inline-block text-truncate"
              style="max-width: 180px;"
              :title="props.rowData.email"
            >
              {{ props.rowData.email }}
            </div>
          </template>
          <template
            slot="employee_no"
            slot-scope="props"
          >
            <div
              class="d-inline-block text-truncate"
              style="max-width: 180px;"
              :title="props.rowData.employee_no"
            >
              <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
              {{ props.rowData.employee_no ? props.rowData.employee_no : '-' }}
            </div>
          </template>
          <template
            slot="activation_date"
            slot-scope="props"
          >
            <div v-if="props.rowData.activation_date">
              <span>{{ formatDate(props.rowData.activation_date) }}</span>
            </div>
          </template>
          <template
            slot="assignment_date"
            slot-scope="props"
          >
            <div v-if="props.rowData.assignment_date">
              <span>{{ formatDate(props.rowData.assignment_date) }}</span>
            </div>
          </template>

          <template
            slot="actions"
            slot-scope="props"
          >
            <button
              class="ui button delete"
              @click="onAction('delete-item', props.rowData, props.rowIndex)"
            >
              <icon-container
                :width="20"
                :height="20"
                view-box="0 0 24 24"
                name="trash"
                color="#727d92"
              >
                <icon-trash />
              </icon-container>
            </button>
          </template>
        </vue-table>
        <div class="pagination__info">
          <vuetable-pagination-info
            :class="{ 'table-fade': tableIsLoading }"
            ref="paginationInfo"
            :info-template="$t('pagination_info')"
            :no-data-template="$t('no_data_info')"
          />
          <div class="pagination__items">
            <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import { directive as onClickaway } from 'vue-clickaway';
import IconContainer from '@/components/elements/Icon.vue';
import IconTrash from '@/components/icons/Trash.vue';
import IconCellPhone from '@/components/icons/CellPhone.vue';
import IconPlusFull from '@/components/icons/PlusFull.vue';

import {
  API_SERVER_TIME_FORMAT,
  API_JOURNEY_V2_USER_ASSIGMENT,
  API_JOURNEY_V2_USERS_REMOVE,
  API_JOURNEY_V2_USER_ASSIGMENT_DELETE,
  API_JOURNEY_V2_USERS,
} from '@/helpers/config';
import FilterPerpage from '@/components/FilterPerpage.vue';
import myTableMixin from '@/helpers/myTableMixin.vue';
import API from '@/services/';
import VuetablePagination from '../components/CustomVuetable/VuetablePagination.vue';

console.log('JourneyUsersV2');

export default {
  name: 'JourneyUsersV2',
  mixins: [myTableMixin],
  props: {
    journeyId: {
      type: String,
      default: null,
    },
    tabPane: {
      type: String,
      default: 'assigned_user',
    },
  },

  data() {
    return {
    };
  },

  components: {
    'icon-container': IconContainer,
    'icon-trash': IconTrash,
    'icon-plusfull': IconPlusFull,
    'icon-cellphone': IconCellPhone,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    'filter-perpage': FilterPerpage,
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  computed: {
    apiUrl() {
      if (!this.tabPane) return null;
      if (this.tabPane === 'plan_user_assign') {
        return `${API_JOURNEY_V2_USER_ASSIGMENT}?as_journey2_id=${this.journeyId}`;
      }
      return `${API_JOURNEY_V2_USERS}?as_journey2_id=${this.journeyId}`;
    },

    refreshPage: {
      get() {
        return this.$store.state.modal.refreshPage;
      },
      set(newValue) {
        this.$store.state.modal.refreshPage = newValue;
      },
    },

    pageToRefresh: {
      get() {
        return this.$store.state.modal.pageToRefresh;
      },

      set(newValue) {
        this.$store.state.modal.pageToRefresh = newValue;
      },
    },

    fields() {
      let fields = [
        {
          name: 'icon',
          title: '',
          width: '35px',
        },
        {
          name: 'name',
          title: this.$t('name'),
        },
        {
          name: 'email',
          title: this.$t('email'),

        },
        {
          name: 'employee_no',
          title: this.$t('employee_no_'),
        },
        {
          name: 'activation_date',
          title: this.$t('activation_date'),
        },
        {
          name: 'actions',
          title: this.$t('actions'),
        },
      ];
      if (this.tabPane === 'plan_user_assign') {
        fields = [
          {
            name: 'icon',
            title: '',
            width: '35px',
          },
          {
            name: 'name',
            title: this.$t('name'),

          },
          {
            name: 'email',
            title: this.$t('email'),

          },
          {
            name: 'employee_no',
            title: this.$t('employee_no_'),
          },
          {
            name: 'assignment_date',
            title: this.$t('assignment_date'),

          },
          {
            name: 'actions',
            title: this.$t('actions'),

          },
        ];
      }
      return fields;
    },
    userLang() {
      if (this.$store.state.auth.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },
  },

  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'JourneyUsersV2') {
        setTimeout(() => {
          this.$refs.myvuetable.refresh();
          this.refreshPage = false;
          this.pageToRefresh = null;
          this.$store.state.modal.launchedFrom = null;
        }, 500);
      }
    },
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },
    formatDate(value) {
      if (!value) return '';
      let fmt = null;
      let date = null;
      if (value) {
        if (this.userLang === 'en') {
          fmt = 'MM.DD.YYYY';
        }
        if (this.userLang === 'tr') {
          fmt = 'DD.MM.YYYY';
        }
        date = moment(value, API_SERVER_TIME_FORMAT).format(fmt);
      }
      return date;
    },
    addJourneyUserModal() {
      const { journeyId } = this;
      this.$store.dispatch('modal/SAVE_PREVIOUS_STATE');
      const obj = {
        modalType: 'modalJourneyV2AddUserOverlay',
        journeyId,
        overlayModal: true,
        pageToRefresh: 'JourneyUsersV2',
      };
      this.$helpers.toggleModal(obj);
    },

    async onAction(action, data) {
      const userId = this.tabPane === 'plan_user_assign' ? data.as_user_id : data.id;
      this.idHolder = userId;
      console.log('userID:', userId);
      this.apiUrlHolder = this.tabPane === 'plan_user_assign' ? API_JOURNEY_V2_USER_ASSIGMENT_DELETE : API_JOURNEY_V2_USERS_REMOVE;

      if (action === 'delete-item') {
        if (!this.snotifyQueueList.includes(userId)) {
          this.snotifyQueueList.push(userId);
          this.$snotify.confirm(this.$t('journey_user_delete_msg_body', { name: data.name }), this.$t('journey_user_delete_msg_title'), {
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            buttons: [
              {
                text: this.$t('yes'),
                action: (toast) => {
                  this.deleteConfirm(toast, this.idHolder, this.apiUrlHolder);
                },
                bold: false,
              },
              {
                text: this.$t('no'),
                action: (toast) => {
                  this.deleteCancel(toast, this.idHolder);
                },
              },
            ],
          }).on('shown', (toast) => {
            this.toastHolder = toast;
            document.addEventListener('keydown', this.eventHandler, false);
          }).on('hidden', (toast) => {
            this.toastHolder = toast;
            document.removeEventListener('keydown', this.eventHandler, false);
          });
        }
      }
    },
    deleteConfirm(toast, itemId, apiUrl) {
      this.$snotify.remove(toast.id);
      const snotifyIndex = this.snotifyQueueList.indexOf(itemId);
      if (snotifyIndex > -1) {
        this.snotifyQueueList.splice(snotifyIndex, 1);
      }
      const { journeyId } = this;
      this.tableIsLoading = true;
      API.delete(`${apiUrl}`, {
        data: {
          as_journey2_id: journeyId,
          user_ids: [itemId],
        },
      })
        .then((response) => {
          if (response.status === 200) {
            this.$snotify.success(this.$t('deleted_success'));
            setTimeout(() => {
              this.tableIsLoading = false;
              this.$refs.myvuetable.refresh();
            }, 1500);
          }
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .journey-users--title {
    display: inline-flex;
    font-size: 26px;
    color: #727D92;
    align-items: center;
    font-weight: 500;
    small {
      font-size: 16px;
      margin-top: 5px;
      margin-left: 10px;
    }
    a {
      margin-right: 10px;
      display: inline-block;
      svg {
        transition: transform .2s ease-in;
      }
      &:hover {
        svg {
          transform: translateX(-5px);
        }
      }
    }
  }

  .disabled {
    opacity:0.5;
  }
  .top-group {
    display: flex;
    justify-content: space-between;
  }

</style>
