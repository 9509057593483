<template>
  <div class="page mb-4 pb-4">
    <app-header
      child-header-class="container"
      :child-header-navigation="userSubNavigation"
      is-child-header-left-actions
    >
      <filter-perpage
        @searchFor="setSearchFor"
        @perPage="setPerPage"
      />
    </app-header>

    <div class="container">
      <div
        v-if="tableIsLoading"
        class="lds-dual-ring"
      />
      <div class="table-responsive">
        <vue-table
          :class="{ 'table-fade': tableIsLoading }"
          ref="myvuetable"
          :api-url="apiUrl"
          :http-fetch="myFetch"
          :api-mode="true"
          @vuetable:loading="tableIsLoading = true"
          @vuetable:loaded="tableIsLoading = false"
          @vuetable:load-error="vuetableLoadError"
          pagination-path="meta"
          http-method="get"
          :append-params="moreParams"
          :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
          :fields="fields"
          :no-data-template="$t('no_data_info')"
          :css="css.table"
          :per-page="perPage"
          :query-params="makeQueryParams"
          @vuetable:pagination-data="onPaginationData"
        >
          <template
            slot="icon"
            slot-scope="props"
          >
            <button
              class="ui button"
              @click="getMobilStatus(props.rowData.id, `ref${props.rowData.id}`)"
              @blur="closeTooltip(`ref${props.rowData.id}`)"
            >
              <icon-container
                :width="13"
                :height="20"
                name="cellphone"
                view-box="0 0 320 512"
                :class="{'disabled': !props.rowData.has_mobile_device_registered}"
                :color="props.rowData.has_mobile_device_registered ? 'rgb(8, 206, 25)':'#727d92'"
              >
                <icon-cellphone />
              </icon-container>
            </button>
            <div
              :ref="`ref${props.rowData.id}`"
              class="content-tooltip"
            >
              <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
              <pre>{{ tooltipText ? tooltipText : '⏳' }}</pre>
            </div>
          </template>
          <template
            slot="completion_rate"
            slot-scope="props"
          >
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                :style="props.rowData.completion_rate_live === 0 ? 'width:' + props.rowData.completion_rate_live + '%;background-color:transparent;color:#000' : 'width:' + props.rowData.completion_rate_live + '%;color:#fff'"
                :aria-valuenow="props.rowData.completion_rate_live"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {{ '‎‎‎‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎' + props.rowData.completion_rate_live + '%' }}
              </div>
            </div>
          </template>
          <template
            slot="actions"
            slot-scope="props"
          >
            <button
              class="ui button"
              @click="onAction('user-detail', props.rowData)"
            >
              <icon-container
                name="search"
                :width="14"
                :height="14"
                color="#727d92"
                view-box="0 0 14 14"
                :is-icon-class="false"
              >
                <icon-search />
              </icon-container>
            </button>
          </template>
        </vue-table>
        <div class="pagination__info">
          <vuetable-pagination-info
            :class="{ 'table-fade': tableIsLoading }"
            ref="paginationInfo"
            :info-template="$t('pagination_info')"
            :no-data-template="$t('no_data_info')"
          />
          <div class="pagination__items">
            <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import { directive as onClickaway } from 'vue-clickaway';
import { mapState } from 'vuex';
import IconContainer from '@/components/elements/Icon.vue';
import IconCellPhone from '@/components/icons/CellPhone.vue';
import IconSearch from '@/components/icons/Search.vue';

import AppHeader from '@/components/AppHeader.vue';
import { API_REPORTS_WEEKLY_USER_DATATABLE } from '@/helpers/config';
import FilterPerpage from '@/components/FilterPerpage.vue';
import myTableMixin from '@/helpers/myTableMixin.vue';
import VuetablePagination from '../../components/CustomVuetable/VuetablePagination.vue';

console.log('Dashboard users');

export default {
  name: 'DashboardUsers',
  mixins: [myTableMixin],
  data() {
    return {

    };
  },

  components: {
    'icon-container': IconContainer,
    'icon-cellphone': IconCellPhone,
    'icon-search': IconSearch,
    'app-header': AppHeader,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    'filter-perpage': FilterPerpage,
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'DashboardUsers') {
        setTimeout(() => {
          this.$refs.myvuetable.refresh();
          this.refreshPage = false;
          this.pageToRefresh = null;
        }, 500);
      }
    },
  },

  computed: {
    ...mapState([
      'modal',
    ]),

    apiUrl() {
      if (API_REPORTS_WEEKLY_USER_DATATABLE) {
        return `${API_REPORTS_WEEKLY_USER_DATATABLE}/${this.$store.state.auth.customerId}`;
      }
      return null;
    },

    customerId() {
      return this.$store.state.auth.customerId;
    },

    isVibonsAdmin() {
      if (this.$store.state && this.$store.state.auth) {
        return this.$store.state.auth.isVibonsAdmin;
      }
      return false;
    },
    isCustomerAdmin() {
      if (this.$store?.state?.auth?.userInfo?.role) return this.$store?.state?.auth?.userInfo?.role === 'customer-admin';
      return false;
    },
    userSubNavigation() {
      let submenu = [
        {
          text: this.$t('overview'),
          url: '/dashboard/overview',
          order: 1,
        },
        {
          text: this.$t('journeys'),
          url: '/dashboard/journeys',
          order: 2,
        },
        {
          text: this.$t('users'),
          url: '/dashboard/users',
          order: 4,
        },
        {
          text: this.$t('quiz_survey'),
          url: '/dashboard/quiz-survey',
          order: 5,
        },
        {
          text: this.$t('report_demands'),
          url: '/dashboard/reportdemands',
          order: 7,
        },
      ];
      if (this.isVibonsAdmin) {
        submenu = submenu.concat([
          {
            text: this.$t('journeys_v2'),
            url: '/dashboard/journeys_v2',
            order: 3,
          },
          {
            text: this.$t('customers_sub'),
            url: '/dashboard/customers',
            order: 6,
          },

        ]);
      }
      if (this.isCustomerAdmin && this.$store.state.auth?.userInfo?.customer?.customizations?.journeyv2) {
        submenu = submenu.concat({
          text: this.$t('journeys_v2'),
          url: '/dashboard/journeys_v2',
          order: 3,
        });
      }

      submenu.sort((a, b) => a.order - b.order);
      return submenu;
    },
    fields() {
      return [
        {
          name: 'icon',
          title: '',
          width: '3%',
        },
        {
          name: 'first_name',
          title: this.$t('first_name'),
          sortField: 'first_name',
          width: '15%',
        },
        {
          name: 'last_name',
          title: this.$t('last_name'),
          sortField: 'last_name',
          width: '15%',
        },
        {
          name: 'email',
          title: this.$t('email'),
          sortField: 'email',
          width: '27%',
        },
        {
          name: 'completion_rate',
          title: this.$t('completion_rate'),
          sortField: 'completion_rate',
          width: '20%',
          titleClass: 'center',
          dataClass: 'center',
        },
        {
          name: 'number_of_journeys',
          title: this.$t('number_of_journeys'),
          width: '10%',
          titleClass: 'center',
          dataClass: 'center',
        },
        {
          name: 'actions',
          title: this.$t('actions'),
          width: '10%',
          titleClass: 'center',
          dataClass: 'center',
        }];
    },
  },

  methods: {
    onAction(action, data) {
      const userId = data.id;
      if (action === 'user-detail') {
        if (userId) {
          this.$store.commit('modal/SET_MODAL_DATA', data);
          this.$router.push({ path: `/dashboard/users/detail/${userId}`, query: data });
        }
      }
    },
  },
};
</script>
