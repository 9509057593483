<!-- eslint-disable vue-i18n/no-missing-keys -->
<template>
  <header class="site-header">
    <div class="site-header__primary">
      <div class="container-fluid">
        <div class="row">
          <div class="col-2">
            <div class="site-header__actions">
              <router-link
                to="/"
                class="logo"
              >
                <img
                  src="@/assets/img/journey-logo.svg"
                  alt="Journey"
                >
              </router-link>
              <div
                class="search"
                style="display:none !important;"
              >
                <div class="form-group">
                  <icon-container
                    :width="30"
                    :height="30"
                    name="search"
                    color="#fff"
                  >
                    <icon-search />
                  </icon-container>
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('search_placeholder')"
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="col-8">
            <app-navigation type="primary">
              <ul class="navigation__list">
                <li
                  class="navigation__item"
                  v-if="isVibonsAdmin"
                >
                  <router-link
                    class="navigation__link"
                    to="/customers"
                  >
                    <span class="icon">
                      <icon-container
                        name="cutomers"
                        view-box="0 0 24 24"
                        :is-icon-class="false"
                      >
                        <icon-customers />
                      </icon-container>
                    </span>
                    {{ $t('customers').toUpperCase() }}
                  </router-link>
                </li>
                <li class="navigation__item">
                  <router-link
                    class="navigation__link"
                    to="/"
                  >
                    <span class="icon">
                      <icon-container
                        name="journey"
                        view-box="0 0 13 13"
                        :is-icon-class="false"
                      >
                        <icon-journey />
                      </icon-container>
                    </span>
                    {{ $t('journeys') }}
                  </router-link>
                </li>
                <li
                  class="navigation__item"
                  v-if="canSeeJourneyV2"
                >
                  <router-link
                    class="navigation__link"
                    to="/journey_v2"
                  >
                    <span class="icon">
                      <icon-container
                        name="journeyonboarding"
                        view-box="0 0 256 256"
                        :is-icon-class="false"
                        width="22"
                        height="22"
                      >
                        <icon-journey-onboarding />
                      </icon-container>
                    </span>
                    {{ $t('label_onboarding') }}
                  </router-link>
                </li>
                <li class="navigation__item">
                  <router-link
                    class="navigation__link"
                    to="/contents"
                  >
                    <span class="icon">
                      <icon-container
                        name="content"
                        view-box="0 0 15 15"
                        :is-icon-class="false"
                      >
                        <icon-content />
                      </icon-container>
                    </span>
                    {{ $t('contents') }}
                  </router-link>
                </li>
                <li
                  class="navigation__item"
                  v-if="showCommunication"
                >
                  <router-link
                    class="navigation__link"
                    to="/communication"
                  >
                    <span class="icon">
                      <icon-container
                        name="notification"
                        :width="23"
                        :height="23"
                        view-box="0 0 24 24"
                        :is-icon-class="false"
                      >
                        <icon-communication />
                      </icon-container>
                    </span>
                    {{ $t('communication') }}
                  </router-link>
                </li>
                <li class="navigation__item">
                  <router-link
                    class="navigation__link"
                    to="/users"
                  >
                    <span class="icon">
                      <icon-container
                        name="user"
                        view-box="0 0 12 14"
                        :is-icon-class="false"
                      >
                        <icon-user />
                      </icon-container>
                    </span>
                    {{ $t('users') }}
                  </router-link>
                </li>
                <li class="navigation__item">
                  <router-link
                    class="navigation__link"
                    to="/dashboard"
                  >
                    <span class="icon">
                      <icon-container
                        name="content"
                        view-box="0 0 18 17"
                        :is-icon-class="false"
                      >
                        <icon-dashboard />
                      </icon-container>
                    </span>
                    {{ $t('dashboard') }}
                  </router-link>
                </li>
                <!-- <li class="navigation__item">
                  <router-link
                    class="navigation__link"
                    to="/meetings"
                  >
                    <span class="icon">
                      <icon-container
                        name="journey"
                        view-box="0 0 13 13"
                        :is-icon-class="false"
                      >
                        <icon-journey />
                      </icon-container>
                    </span>
                    {{ $t('meetings') }}
                  </router-link>
                </li> -->
              </ul>
            </app-navigation>
          </div>

          <div class="col-2">
            <ul class="u-list-unstyled site-header__actions justify-content-end">
              <li
                class="site-header__actions-item"
                style="display:none;"
              >
                <button
                  class="site-header__actions-button"
                  type="button"
                >
                  <icon-container
                    :width="22"
                    :height="24"
                    name="notification"
                    view-box="0 0 22 24"
                  >
                    <icon-notification />
                  </icon-container>
                </button>
              </li>
              <li
                class="site-header__actions-item dropdown"
                @click="dropdownMenu('profileDropdown')"
                @mouseover.prevent="dropdownMenu('profileDropdown')"
                @mouseleave="hideDropdown"
              >
                <button
                  type="button"
                  class="site-header__actions-button has-arrow"
                >
                  <div
                    class="media-object"
                    v-if="userData"
                  >
                    <span class="h5 media-object__text u-text-uppercase">
                      <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                      {{ userData.first_name }} {{ userData.last_name }}
                    </span>
                  </div>
                </button>
                <div
                  class="dropdown__menu dropdown__menu--profile"
                  v-if="isDropdown === 'profileDropdown'"
                >
                  <span
                    v-if="isVibonsAdmin"
                    class="dropdown__button button"
                  >
                    {{ $t('switch_customer') }}
                    <switch-customer />
                  </span>
                  <span
                    v-if="isVibonsAdmin"
                    class="dropdown__button button"
                    @click.prevent="setupEdDemo"
                  >
                    {{ $t('setup_ed_demo') }}
                  </span>
                  <span
                    v-if="showLxpRecomendation"
                    class="dropdown__button button"
                    @click.prevent="importLxpRecomendation"
                  >
                    {{ $t('import_lxp_recomend') }}
                  </span>
                  <span
                    v-if="isVibonsAdmin"
                    class="dropdown__button button"
                  >
                    {{ $t('switch_language') }}
                    <switch-language />
                  </span>

                  <span
                    class="dropdown__button button"
                    @click.prevent="gotoHelpCenter"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                    >
                      <path
                        fill="#fff"
                        fill-rule="evenodd"
                        stroke="#727D92"
                        stroke-opacity=".2"
                        d="M16 31c8.2843 0 15-6.7157 15-15 0-8.28427-6.7157-15-15-15C7.71573 1 1 7.71573 1 16c0 8.2843 6.71573 15 15 15z"
                        clip-rule="evenodd"
                      />
                      <path
                        fill="url(#paint0_linear)"
                        fill-rule="evenodd"
                        d="M15.9685 21.4917c-1.1582 0-2.246-.3443-3.1451-.9952-.1282-.0923-.2805-.1404-.434-.1404a.73787.73787 0 00-.249.0432l-.9039.3224.3314-.8789c.0858-.2283.0539-.485-.086-.6853-.6557-.9366-1.0023-2.0272-1.0023-3.1547 0-3.0456 2.4621-5.5233 5.4889-5.5233 3.026 0 5.4883 2.4777 5.4883 5.5233 0 3.0266-2.4623 5.4889-5.4883 5.4889zm0-12.49161c-3.8423 0-6.96852 3.14151-6.96852 7.00271 0 1.3005.36157 2.5613 1.05032 3.6709l-.76225 2.0213c-.10135.2699-.03728.5739.1648.7795.20209.2057.50568.2751.77655.1779l2.0557-.7338c1.0841.6899 2.349 1.0527 3.6834 1.0527 3.8422 0 6.9679-3.1262 6.9679-6.9685 0-3.8612-3.1257-7.00271-6.9679-7.00271z"
                        clip-rule="evenodd"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear"
                          x1="22.9364"
                          x2="8.99998"
                          y1="9.00009"
                          y2="9.00009"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#F27299" />
                          <stop
                            offset="1"
                            stop-color="#254FFB"
                          />
                        </linearGradient>
                      </defs>
                    </svg>{{ $t('help_center') }}
                  </span>
                  <span
                    class="dropdown__button button"
                    @click.prevent="logOut"
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    ><path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z"
                      fill="white"
                      stroke="#727D92"
                      stroke-opacity="0.2"
                    /><path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M21.1236 16.3506C21.1297 16.3397 21.1359 16.3287 21.1414 16.3175C21.1465 16.307 21.1533 16.2975 21.1578 16.2867C21.1586 16.2848 21.1593 16.2829 21.1601 16.2812C21.1673 16.2637 21.1709 16.2452 21.1764 16.2275C21.1809 16.2131 21.1861 16.1992 21.1894 16.1845C21.1908 16.1789 21.1934 16.1735 21.1946 16.1678C21.1949 16.1664 21.195 16.165 21.1953 16.1637C21.1995 16.1429 21.1998 16.1217 21.2018 16.1005C21.2036 16.0812 21.2073 16.0621 21.2074 16.0426C21.2074 16.0184 21.2036 15.9943 21.2009 15.9702C21.1992 15.954 21.1993 15.9376 21.1963 15.9215C21.1959 15.9192 21.1954 15.917 21.1949 15.9146C21.1917 15.8993 21.186 15.8852 21.1818 15.8703C21.179 15.8604 21.1763 15.8508 21.173 15.841C21.1691 15.8288 21.167 15.816 21.162 15.8038C21.161 15.8012 21.1598 15.7986 21.1588 15.7959C21.151 15.7775 21.1403 15.7609 21.131 15.7433C21.1279 15.7373 21.1249 15.7312 21.1215 15.7252C21.1154 15.7146 21.1111 15.7032 21.1045 15.6928C21.1032 15.6907 21.1017 15.6887 21.1003 15.6867C21.0838 15.662 21.0639 15.64 21.0443 15.618C21.0372 15.61 21.0321 15.601 21.0245 15.5934C21.024 15.5929 21.0233 15.5925 21.0228 15.592C21.0222 15.5912 21.0215 15.5903 21.0207 15.5897L19.0093 13.5783C18.7606 13.3295 18.358 13.3295 18.1092 13.5783C17.861 13.8259 17.861 14.2295 18.1092 14.4773L19.0359 15.4038H15.2455C14.8943 15.4038 14.6094 15.6883 14.6094 16.0398C14.6094 16.3914 14.8943 16.6758 15.2455 16.6758H19.0191L18.1129 17.569C17.8626 17.8155 17.8596 18.2181 18.1062 18.4689C18.2305 18.5953 18.3951 18.6586 18.5593 18.6586C18.7204 18.6586 18.882 18.5974 19.0058 18.4761L21.0178 16.4935C21.0189 16.4924 21.0195 16.4912 21.0206 16.4902C21.0366 16.4742 21.0495 16.4558 21.0638 16.4382C21.0765 16.4224 21.0909 16.4078 21.1018 16.3911C21.1105 16.3782 21.116 16.3641 21.1236 16.3506Z"
                      fill="url(#logOut0_linear)"
                    /><path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18.8554 19.7829C18.5043 19.7829 18.2194 20.0672 18.2194 20.4189C18.2194 21.0957 17.6552 21.6472 16.9615 21.6472H13.5294C12.8364 21.6472 12.2722 21.0957 12.2722 20.4189V11.6313C12.2722 10.9543 12.8364 10.4031 13.5294 10.4031H16.9615C17.6552 10.4031 18.2194 10.9543 18.2194 11.6313C18.2194 11.983 18.5043 12.2675 18.8554 12.2675C19.2066 12.2675 19.4915 11.983 19.4915 11.6313C19.4915 10.2521 18.3565 9.13094 16.9615 9.13094H13.5294C12.1345 9.13094 11 10.2521 11 11.6313V20.4189C11 21.7979 12.1345 22.9192 13.5294 22.9192H16.9615C18.3565 22.9192 19.4915 21.7979 19.4915 20.4189C19.4915 20.0672 19.2066 19.7829 18.8554 19.7829Z"
                      fill="url(#logOut1_linear)"
                    /><defs><linearGradient
                      id="logOut0_linear"
                      x1="21.2074"
                      y1="13.3917"
                      x2="14.6094"
                      y2="13.3917"
                      gradientUnits="userSpaceOnUse"
                    ><stop stop-color="#F27299" /><stop
                      offset="1"
                      stop-color="#254FFB"
                    /></linearGradient><linearGradient
                      id="logOut1_linear"
                      x1="19.4915"
                      y1="9.13094"
                      x2="11"
                      y2="9.13094"
                      gradientUnits="userSpaceOnUse"
                    ><stop stop-color="#F27299" /><stop
                      offset="1"
                      stop-color="#254FFB"
                    /></linearGradient></defs></svg>
                    {{ $t('logout') }}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <app-navigation
      type="secondary"
      class="child-header"
    >
      <div
        :class="childHeaderClass"
      >
        <div
          class="row sub-header"
        >
          <div class="sub-header__left">
            <div
              class="col-3 child-header__left"
              v-if="childTitle !== null || isChildHeaderLeftActions"
            >
              <div class="site-header__actions">
                <div
                  class="form-group u-mB-0"
                  v-if="childTitle !== null"
                >
                  <div
                    class="form-title text-nowrap"
                  >
                    {{ childTitle }}
                  </div>
                </div>
                <div
                  class="d-flex"
                  v-if="isChildHeaderLeftActions"
                >
                  <slot />
                </div>
              </div>
            </div>
          </div>
          <div class="sub-header__center">
            <div
              v-if="childHeaderNavigation.length > 0"
            >
              <ul class="navigation__list">
                <li
                  v-for="item in childHeaderNavigation"
                  class="navigation__item"
                  :key="item.id"
                >
                  <router-link
                    :to="{path: item.url, query: item.query}"
                    class="navigation__link"
                    :class="[ isCurrentPageIsActive(item.url) ? 'router-link-exact-active router-link-active' : '', 'navigation__link']"
                  >
                    {{ item.text }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="d-flex align-items-center">
              <slot name="center" />
            </div>
          </div>
          <div class="sub-header__right">
            <div class="d-flex align-items-center">
              <slot name="right" />
            </div>
            <div
              class="d-flex align-items-center"
              v-if="childHeaderRightContent.length > 0
                || isChildHeaderSort
                || addNewContentStatus
                || startTheJourney
                || assignTheJourney
                || (totalUsers !== null && totalUsers !== undefined)
                || addUser
                || addBulkBadi
                || addJourneyUser
                || addGroup
                || addPin
                || addJourneygroup
                || addCustomer"
            >
              <div
                class="button-group justify-content-end"
                v-if="childHeaderRightContent.length > 0"
              >
                <a
                  :href="item.href"
                  :class="item.class"
                  :key="index"
                  v-for="(item, index) in childHeaderRightContent"
                >
                  {{ item.text }}
                </a>
              </div>
              <!-- eslint-disable-next-line -->
              <div
                class="form-group u-mB-0 max-width-sm"
                v-if="isChildHeaderSort"
              >
                <multi-select
                  clear-on-select
                  deselect-label
                  select-label
                  v-model="selectValue"
                  :options="selectOptions"
                  class="selectbox selectbox--secondary"
                />
              </div>
              <div
                class="total-user"
                v-if="totalUsers !== null && totalUsers !== undefined"
                @click="showJourneyItemUsers"
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z"
                    fill="white"
                    stroke="#727D92"
                    stroke-opacity="0.2"
                  /><path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.112 10.39C17.5749 10.39 18.765 11.5801 18.765 13.043C18.765 14.5057 17.5749 15.6958 16.112 15.6958C14.6491 15.6958 13.459 14.5057 13.459 13.043C13.459 11.5801 14.6491 10.39 16.112 10.39ZM16.112 16.9562C18.2697 16.9562 20.0252 15.2005 20.0252 13.043C20.0252 10.8853 18.2697 9.12958 16.112 9.12958C13.9543 9.12958 12.1987 10.8853 12.1987 13.043C12.1987 15.2005 13.9543 16.9562 16.112 16.9562Z"
                    fill="url(#userAll0_linear)"
                  /><path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.1723 21.9096C21.1295 19.4884 18.7507 17.9236 16.112 17.9236C13.4726 17.9236 11.0939 19.4884 10.0517 21.9096C9.91386 22.2298 10.0612 22.6004 10.3809 22.7378C10.4622 22.7731 10.547 22.7893 10.6298 22.7893C10.8742 22.7893 11.1065 22.6468 11.209 22.4086C12.0522 20.4499 13.9771 19.184 16.112 19.184C18.2468 19.184 20.1712 20.4499 21.015 22.4086C21.1522 22.7277 21.5219 22.8786 21.843 22.7378C22.1627 22.6004 22.3101 22.2293 22.1723 21.9096Z"
                    fill="url(#userAll1_linear)"
                  /><defs><linearGradient
                    id="userAll0_linear"
                    x1="20.0252"
                    y1="9.12958"
                    x2="12.1987"
                    y2="9.12958"
                    gradientUnits="userSpaceOnUse"
                  ><stop stop-color="#F27299" /><stop
                    offset="1"
                    stop-color="#254FFB"
                  /></linearGradient><linearGradient
                    id="userAll1_linear"
                    x1="22.2239"
                    y1="17.9236"
                    x2="10"
                    y2="17.9236"
                    gradientUnits="userSpaceOnUse"
                  ><stop stop-color="#F27299" /><stop
                    offset="1"
                    stop-color="#254FFB"
                  /></linearGradient></defs>
                </svg><!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                <span>{{ $t('total_user') }} {{ totalUsers }}</span>
              </div>
              <div
                class="journey-edit-icon"
                v-if="journeyDetail?.isJourneyTypeV2"
              >
                <span
                  class="icon-setting button--shadow align-items-center"
                  @click="editJourney()"
                >
                  <icon-container
                    name="setting"
                    :width="20"
                    :height="20"
                    view-box="0 0 12 12"
                    color="#727D92"
                    :is-icon-class="false"
                  >
                    <icon-setting />
                  </icon-container>
                </span>
              </div>
              <div
                class="journey-edit-icon"
                v-if="startTheJourney || assignTheJourney"
              >
                <span
                  class="icon-setting button--shadow align-items-center"
                  @click="rescheduleJourney()"
                >
                  <icon-container
                    name="setting"
                    :width="20"
                    :height="20"
                    view-box="0 0 12 12"
                    color="#727D92"
                    :is-icon-class="false"
                  >
                    <icon-setting />
                  </icon-container>
                </span>
              </div>
              <button
                class="button button--secondary text-size-xs size-sm button--icon"
                :class="{'button--disabled' : totalJourney === 0}"
                v-if="startTheJourney"
                :disabled="totalJourney === 0"
                @click="startTheJourneyEvent()"
              >
                <icon-container
                  name="playfull"
                  view-box="0 0 448 512"
                  color="#FFF"
                  :is-icon-class="true"
                >
                  <icon-playfull />
                </icon-container>
                {{ $t('start_journey') }}
              </button>
              <button
                class="button button--secondary text-size-xs size-sm button--icon"
                v-if="addBulkBadi"
                @click="bulkImport('badi')"
              >
                <icon-container
                  name="upload"
                  view-box="0 0 334 334"
                  height="24"
                  width="24"
                  :color="'#ffffff'"
                  :is-icon-class="true"
                >
                  <icon-upload />
                </icon-container>
                {{ $t('add_bulk_badi') }}
              </button>
              <!-- <button
                class="button button--secondary text-size-xs size-sm button--icon"
                v-if="assignTheJourney"
                @click="assignTheJourneyEvent()"
              >
                <icon-container
                  name="playfull"
                  view-box="0 0 448 512"
                  color="#FFF"
                  :is-icon-class="true"
                >
                  <icon-playfull />
                </icon-container>
                {{ $t('assign_journey') }}
              </button> -->
              <button
                class="button button--secondary text-size-xs size-sm button--icon"
                v-if="addNewContentStatus"
                @click="addNewContent()"
              >
                <svg
                  fill="none"
                  class="icon-svg"
                  viewBox="0 0 14 14"
                  width="16"
                  height="16"
                >
                  <path
                    fill="#fff"
                    fill-rule="evenodd"
                    d="M6.845 12.48A5.59 5.59 0 011.26 6.896c0-3.063 2.506-5.555 5.585-5.555 3.08 0 5.584 2.492 5.584 5.555a5.59 5.59 0 01-5.584 5.584zm0-12.399C3.071.081 0 3.14 0 6.896a6.853 6.853 0 006.845 6.845 6.853 6.853 0 006.845-6.845c0-3.757-3.071-6.815-6.845-6.815z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill="#fff"
                    fill-rule="evenodd"
                    d="M9.073 6.266H7.475V4.668a.63.63 0 00-1.26 0v1.598H4.588a.63.63 0 000 1.26h1.627v1.627a.63.63 0 001.26 0V7.526h1.598a.63.63 0 000-1.26z"
                    clip-rule="evenodd"
                  />
                </svg>
                {{ $t('add_new_content') }}
              </button>

              <div
                class="page-settings--menu"
                v-if="addNewContentStatus"
              >
                <button
                  class="btn btn-primary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  @mouseover.prevent="showBulkImport = true"
                  @mouseleave="closeBulkImport"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="6"
                  >
                    <g
                      fill="#727D92"
                      fill-rule="evenodd"
                    >
                      <circle
                        cx="3"
                        cy="3"
                        r="3"
                      />
                      <circle
                        cx="11.4"
                        cy="3"
                        r="3"
                      />
                      <circle
                        cx="19.8"
                        cy="3"
                        r="3"
                      />
                    </g>
                  </svg>
                </button>
                <transition name="dropdown">
                  <div
                    class="page-settings__dropdown-menu"
                    @mouseleave="showBulkImport = false"
                    @mouseover.prevent="holdBulkMenu"
                    :class="{ active: showBulkImport }"
                    v-if="showBulkImport"
                  >
                    <ul class="page-settings__dropdown-menu-nav">
                      <li class="page-settings__dropdown-menu-item">
                        <a
                          href="#"
                          class="page-settings__dropdown-menu-link"
                          title="Import survey in bulk from file"
                          @click="bulkImport('survey')"
                        >
                          {{ $t('import_survey') }}
                        </a>
                      </li>
                      <li class="page-settings__dropdown-menu-item">
                        <a
                          href="#"
                          class="page-settings__dropdown-menu-link"
                          title="Import quiz in bulk from file"
                          @click="bulkImport('quiz')"
                        >
                          {{ $t('import_quiz') }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </transition>
              </div>
              <button
                class="button button--secondary text-size-xs size-sm button--icon"
                v-if="addJourneygroup"
                @click="addJourneygroupModal()"
              >
                <icon-container
                  name="plusfull"
                  view-box="0 0 448 512"
                  :width="15"
                  :height="16"
                  color="#FFF"
                  :is-icon-class="true"
                >
                  <icon-plusfull />
                </icon-container>
                {{ $t('add_new_journey_group') }}
              </button>
              <button
                class="button button--secondary text-size-xs size-sm button--icon"
                v-if="addCustomer"
                @click="addCustomerModal()"
              >
                <icon-container
                  name="plusfull"
                  view-box="0 0 448 512"
                  :width="15"
                  :height="16"
                  color="#FFF"
                  :is-icon-class="true"
                >
                  <icon-plusfull />
                </icon-container>
                {{ $t('add_new_customer') }}
              </button>
              <button
                class="button button--secondary text-size-xs size-sm button--icon"
                v-if="addUser"
                @click="addUserModal()"
              >
                <icon-container
                  name="plusfull"
                  view-box="0 0 448 512"
                  :width="15"
                  :height="16"
                  color="#FFF"
                  :is-icon-class="true"
                >
                  <icon-plusfull />
                </icon-container>
                {{ $t('add_new_user') }}
              </button>
              <button
                class="button button--secondary text-size-xs size-sm button--icon"
                v-if="addJourneyUser"
                @click="addJourneyUserModal()"
              >
                <icon-container
                  name="plusfull"
                  view-box="0 0 448 512"
                  :width="15"
                  :height="16"
                  color="#FFF"
                  :is-icon-class="true"
                >
                  <icon-plusfull />
                </icon-container>
                {{ $t('add_new_user') }}
              </button>
              <div
                class="page-settings--menu"
                v-if="addUser"
              >
                <button
                  class="btn btn-primary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  @click="showUserSettings = !showUserSettings"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="6"
                  >
                    <g
                      fill="#727D92"
                      fill-rule="evenodd"
                    >
                      <circle
                        cx="3"
                        cy="3"
                        r="3"
                      />
                      <circle
                        cx="11.4"
                        cy="3"
                        r="3"
                      />
                      <circle
                        cx="19.8"
                        cy="3"
                        r="3"
                      />
                    </g>
                  </svg>
                </button>
                <transition name="dropdown">
                  <div
                    class="page-settings__dropdown-menu"
                    :class="{ active: showUserSettings }"
                    v-if="showUserSettings"
                  >
                    <ul class="page-settings__dropdown-menu-nav">
                      <li class="page-settings__dropdown-menu-item">
                        <a
                          href="#"
                          class="page-settings__dropdown-menu-link"
                          :title="$t('export_to_excel_new')"
                          @click="importUserModal('Add')"
                        >
                          {{ $t('export_to_excel_new') }}
                        </a>
                      </li>
                      <li class="page-settings__dropdown-menu-item">
                        <a
                          href="#"
                          class="page-settings__dropdown-menu-link"
                          :title="$t('export_users_to_excell_new')"
                          @click="downloadReport"
                        >
                          {{ $t('export_users_to_excell_new') }}
                        </a>
                      </li>
                      <li class="page-settings__dropdown-menu-item">
                        <a
                          href="#"
                          class="page-settings__dropdown-menu-link"
                          :title="$t('import_excell_delete_new')"
                          @click="importUserModal('Delete')"
                        >
                          {{ $t('import_excell_delete_new') }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </transition>
              </div>

              <button
                class="button button--secondary text-size-xs size-sm button--icon"
                v-if="addGroup"
                @click="addGroupModal()"
              >
                <icon-container
                  name="plusfull"
                  view-box="0 0 448 512"
                  :width="15"
                  :height="16"
                  color="#FFF"
                  :is-icon-class="true"
                >
                  <icon-plusfull />
                </icon-container>
                {{ $t('add_new_group') }}
              </button>
              <button
                class="button button--secondary text-size-xs size-sm button--icon"
                v-if="addPin"
                @click="addPinModal()"
              >
                <icon-container
                  name="plusfull"
                  view-box="0 0 448 512"
                  :width="15"
                  :height="16"
                  color="#FFF"
                  :is-icon-class="true"
                >
                  <icon-plusfull />
                </icon-container>
                {{ $t('add_new_pin') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </app-navigation>
  </header>
</template>

<script>
import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import AppNavigation from '@/components/AppNavigation.vue';
import IconContainer from '@/components/elements/Icon.vue';
import IconSearch from '@/components/icons/Search.vue';
import IconCommunication from '@/components/icons/Communication.vue';
import IconContent from '@/components/icons/Content.vue';
import IconDashboard from '@/components/icons/Dashboard.vue';
import IconJourney from '@/components/icons/Journey.vue';
import IconUser from '@/components/icons/User.vue';
import IconPlayFull from '@/components/icons/PlayFull.vue';
import IconPlusFull from '@/components/icons/PlusFull.vue';
import IconSetting from '@/components/icons/Setting.vue';
import IconCustomers from '@/components/icons/Customers.vue';
import IconNotification from '@/components/icons/Notification.vue';
import SwitchLanguage from '@/components/SwitchLanguage.vue';
import SwitchCustomer from '@/components/SwitchCustomer.vue';
import IconUpload from '@/components/icons/Upload.vue';
import {
  API_REPORT_USER_LIST_DOWNLOAD,
} from '@/helpers/config';
import moment from 'moment';
import { saveAs } from 'file-saver';
import API from '@/services/';
import IconJourneyOnboarding from '@/components/icons/JourneyOnboarding.vue';

Vue.component('Multiselect', Multiselect);
Vue.component('SwitchLanguage', SwitchLanguage);
Vue.component('SwitchCustomer', SwitchCustomer);

export default {
  name: 'AppHeader',

  data() {
    return {
      selectValue: null,
      selectOptions: [
        'Sort',
      ],
      isDropdown: null,
      showUserSettings: false,
      showBulkImport: false,
      timer: null,
    };
  },

  components: {
    'app-navigation': AppNavigation,
    'icon-container': IconContainer,
    'icon-search': IconSearch,
    'icon-content': IconContent,
    'icon-dashboard': IconDashboard,
    'icon-journey': IconJourney,
    'icon-journey-onboarding': IconJourneyOnboarding,
    'icon-setting': IconSetting,
    'icon-user': IconUser,
    'icon-playfull': IconPlayFull,
    'icon-plusfull': IconPlusFull,
    'icon-customers': IconCustomers,
    'icon-communication': IconCommunication,
    'icon-notification': IconNotification,
    'icon-upload': IconUpload,
    'multi-select': Multiselect,
  },

  props: {
    addNewContentStatus: {
      type: Boolean,
      default: false,
    },

    isChildHeaderLeftActions: {
      type: Boolean,
      default: false,
    },

    childHeaderNavigation: {
      type: Array,
      default: () => [],
    },

    childHeaderRightContent: {
      type: Array,
      default: () => [],
    },

    isChildHeaderSort: {
      type: Boolean,
      default: false,
    },

    childTitle: {
      type: String,
      default: null,
    },

    justifyBetweenClass: {
      type: Boolean,
      default: false,
    },

    childHeaderClass: {
      type: String,
      default: 'container-fluid',
    },

    isReadOnly: {
      type: Boolean,
      default: true,
    },

    startTheJourney: {
      type: Boolean,
      default: false,
    },

    assignTheJourney: {
      type: Boolean,
      default: false,
    },

    addGroup: {
      type: Boolean,
      default: false,
    },

    addUser: {
      type: Boolean,
      default: false,
    },
    addBulkBadi: {
      type: Boolean,
      default: false,
    },
    addPin: {
      type: Boolean,
      default: false,
    },
    addJourneyUser: {
      type: Boolean,
      default: false,
    },

    addCustomer: {
      type: Boolean,
      default: false,
    },
    addJourneygroup: {
      type: Boolean,
      default: false,
    },
    totalUsers: {
      type: Number,
      default: null,
    },

    totalJourney: {
      type: Number,
      default: null,
    },
  },

  computed: {

    customerName() {
      return this.$store.state.auth.customerName;
    },

    userData() {
      if (this.$store.state && this.$store.state.auth) {
        return this.$store.state.auth.userInfo;
      }
      return false;
    },
    canSeeJourneyV2() {
      if (this.userData && this.userData?.customer?.customizations?.journeyv2) {
        return true;
      }
      return false;
    },
    isVibonsAdmin() {
      if (this.$store.state && this.$store.state.auth) {
        return this.$store.state.auth.isVibonsAdmin;
      }
      return false;
    },
    showCommunication() {
      if (this.$store.state && this.$store.state.auth && this.$store.state.auth.userInfo && this.$store.state.auth.userInfo.customer && this.$store.state.auth.userInfo.customer.services && this.$store.state.auth.userInfo.customer.services.includes('Communication')) {
        return true;
      }
      return false;
    },
    showLxpRecomendation() {
      if (this.isVibonsAdmin || (this.$store.state.auth.userInfo && this.$store.state.auth.userInfo.role === 'customer-admin' && this.$store.state.auth.userInfo.customer && this.$store.state.auth.userInfo.customer.services && this.$store.state.auth.userInfo.customer.services.length && this.$store.state.auth.userInfo.customer.services.includes('LXP'))) {
        return true;
      }
      return false;
    },
    journeyDetail: {
      get() {
        return this.$store.state.modal.journeyDetail;
      },
      set(newValue) {
        this.$store.state.modal.journeyDetail = newValue;
      },
    },

    isOnboarding() {
      if (this.journeyDetail) {
        if (this.journeyDetail.journey_type && (this.journeyDetail.journey_type === 2 || this.journeyDetail.journey_type === 'ON_BOARDING')) {
          return true;
        }
      }
      return false;
    },
  },

  methods: {
    isCurrentPageIsActive(item) {
      const cpath = this.$route.path;
      return item.toLowerCase() === cpath.toLowerCase();
    },
    createJourneyModal() {
      const obj = {
        modalType: 'modalJourney',
        tabPane: 'mainTab',
      };
      this.$helpers.toggleModal(obj);
    },

    dropdownMenu(value) {
      this.isDropdown = value;
    },

    hideDropdown() {
      this.isDropdown = null;
    },

    closeBulkImport() {
      this.timer = setTimeout(() => {
        this.showBulkImport = false;
      }, 1000);
    },

    holdBulkMenu() {
      clearTimeout(this.timer);
    },
    importLxpRecomendation() {
      const obj = {
        modalType: 'modalLxpRecommend',
      };
      this.$helpers.toggleModal(obj);
    },
    showJourneyItemUsers() {
      let obj = {
        modalType: 'modalJourneyItemUsers',
        pageToRefresh: 'journeyitems',
        journeyName: this.childTitle,
        launchedFrom: 'journeyitems',
        tabPane: 'mainTab',
      };
      if (this.journeyDetail?.isJourneyTypeV2) {
        obj = {
          modalType: 'modalJourneyItemUsersV2',
          pageToRefresh: 'journeyitemsv2',
          journeyName: this.childTitle,
          launchedFrom: 'journeyitemsv2',
          tabPane: 'mainTab',
        };
      }
      this.$helpers.toggleModal(obj);
    },

    addNewContent() {
      const obj = {
        modalType: 'modalCreateContent',
        pageToRefresh: 'Contents',
        launchedFrom: 'Contents',
        tabPane: 'mainTab',
      };
      this.$helpers.toggleModal(obj);
    },
    editJourney() {
      const itemid = this.$store.state.modal.journeyId || this.$route.params.journeyId;
      if (itemid) {
        const obj = {
          modalType: 'modalJourneyV2',
          journeyId: itemid,
          pageToRefresh: 'journeyitemsv2',
          launchedFrom: 'journeyitemsv2',
          tabPane: 'mainTab',
          editMode: true,
        };
        this.$helpers.toggleModal(obj);
      }
    },
    // eslint-disable-next-line vue/no-dupe-keys
    rescheduleJourney() {
      const itemid = this.$store.state.modal.journeyId || this.$route.params.journeyId;
      if (itemid) {
        const obj = {
          modalType: 'modalJourney',
          journeyId: itemid,
          pageToRefresh: 'Journey',
          launchedFrom: 'journeyitems',
          tabPane: this.totalUsers > 0 ? 'rescheduleJourneyTab' : 'mainTab',
          editMode: true,
        };
        this.$helpers.toggleModal(obj);
      }
    },

    startTheJourneyEvent() {
      let modalType = 'modalAssign';
      if (this.isOnboarding) modalType = 'modalOnboardingAssign';
      // console.log('Assigning journey item info:', this.journeyDetail, this.journeyDetail.id, this.isOnboarding);
      this.$helpers.toggleModal(modalType);
    },

    assignTheJourneyEvent() {
      let modalType = 'modalAssign';
      if (this.isOnboarding) modalType = 'modalOnboardingAssign';
      // console.log('Assigning journey item info:', this.journeyDetail, this.journeyDetail.id, this.isOnboarding);
      this.$helpers.toggleModal(modalType);
    },

    addUserModal() {
      const obj = {
        modalType: 'modalAddUser',
        pageToRefresh: this.$store.state.modal.pageToRefresh ? this.$store.state.modal.pageToRefresh : 'Users',
      };
      this.$helpers.toggleModal(obj);
    },
    addPinModal() {
      const obj = {
        modalType: 'modalAddPin',
        pageToRefresh: 'Pins',
      };
      this.$helpers.toggleModal(obj);
    },
    setupEdDemo() {
      const obj = {
        modalType: 'modalSetupEdDemo',
      };
      if (this.isVibonsAdmin) {
        this.$helpers.toggleModal(obj);
      }
    },
    gotoHelpCenter() {
      const windowReference = window.open();
      windowReference.location = 'https://journeyapp.atlassian.net/servicedesk/customer/portal/1';
    },
    addJourneyUserModal() {
      const obj = {
        modalType: 'modalJourneyAddUser',
        journeyId: this.$store.state.modal.journeyId || this.$route.params.journeyId,
        pageToRefresh: 'JourneyUsers',
      };
      this.$helpers.toggleModal(obj);
    },
    addJourneygroupModal() {
      const obj = {
        modalType: 'modalCreateJourneyGroup',
        editMode: false,
        pageToRefresh: 'JourneyGroup',
      };
      this.$helpers.toggleModal(obj);
    },
    addGroupModal() {
      const obj = {
        modalType: 'modalAddGroup',
        pageOptions: 'new-group',
        editMode: false,
        pageToRefresh: 'Groups',
      };
      this.$helpers.toggleModal(obj);
    },

    addCustomerModal() {
      const obj = {
        modalType: 'modalAddCustomer',
        pageToRefresh: 'Customers',
      };
      this.$helpers.toggleModal(obj);
    },

    displayReportNotification() {
      const obj = {
        modalType: 'modalReportNotification',
      };
      this.$helpers.toggleModal(obj);
    },

    downloadReport() {
      const payload = new FormData();
      payload.append('customer_id', this.$store.state.auth.customerId);
      API.post(`${API_REPORT_USER_LIST_DOWNLOAD}`, payload, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          this.$store.commit('modal/setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
        },
      })
        .then((response) => {
          if (response.data.type === 'application/json') {
            this.displayReportNotification();
          } else {
            const csvFile = response.data;
            saveAs(csvFile, `${this.$t('user_list')}-${moment().format('YYYY-MM-DD')}.xlsx`);
          }
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        })
        .finally(() => this.$store.commit('modal/setProgressBarValue', -1));
    },

    importUserModal(type) {
      this.showUserSettings = false;
      const obj = {
        modalType: 'modalImportUser',
        pageToRefresh: this.$store.state.modal.pageToRefresh ? this.$store.state.modal.pageToRefresh : 'Users',
        importType: type, // takes 'Add', 'Delete' or 'Export', options.
      };

      this.$helpers.toggleModal(obj);
    },

    bulkImport(type) {
      this.showUserSettings = false;
      const obj = {
        modalType: 'modalSQBulkImport',
        pageToRefresh: 'Contents',
        importType: type, // takes 'survey', 'quiz', 'badi' options.
      };

      this.$helpers.toggleModal(obj);
    },

    logOut() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$store.commit('auth/set_status', null);
        this.isDropdown = null;
        this.$router.push('/login');
      }).catch(() => {
        this.$store.commit('auth/set_status', null);
      });
    },
  },
};
</script>

<style lang="scss">
.site-header {
  width: 100%;
  color: $white;
  // margin-bottom: 25px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 102;
  height: 112px;

  .logo {
    @extend .d-flex;
    margin-right: 30px;
    margin-left: 25px;
  }

  .navigation__link { cursor: pointer; }

  .navigation--secondary {
    @extend .d-flex;
    @extend .align-items-center;

    .button {
      white-space: nowrap;
    }
  }

  .form-title {
    width: 100%;
    height: 100%;
    background: transparent;
    border: 0;
    appearance: none;
    @include primary-medium;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: $primaryColor;
  }

  .navigation--secondary .navigation__list {
    height: 48px;
  }

  .navigation--secondary .form-group {
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .align-items-center;
  }

  .search {
    width: 160px;
    @extend .d-flex;
    @extend .align-items-center;

    .form-group {
      position: relative;
      margin-bottom: 0;
      @extend .d-flex;
    }

    .icon {
      position: absolute;
      left: 10px;
      top: 50%;
      margin-top: -7.5px;
      width: 15px;
      height: 15px;
    }

    .form-control {
      position: relative;
      overflow: hidden;
      border-radius: $baseRadius;
      background-color: rgba(20, 43, 88, 0.25);
      border: 0;
      color: $white;
      font-size: 14px;
      @include primary-regular;
      padding-left: 35px;
      padding-right: 15px;

      @include input-placeholder {
        color: $white;
        font-size: 14px;
        @include primary-regular;
      }
    }
  }

  &__primary {
    height: 64px;
    box-shadow: 0 2px 14px 0 rgba(20, 43, 88, 0.23);
    background-image: linear-gradient(to left, #f27299, #254ffb);
    @extend .d-flex;
  }

  &__actions {
    height: 100%;
    margin-right:25px;
    @extend .d-flex;
    // @extend .justify-content-end;
  }

  &__actions-button {
    width: 100%;
    height: 64px;
    border-top: 0;
    border-bottom: 0;
    padding: 0 15px;
    color: #fff;
    background: transparent;
    @include primary-medium;
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .align-items-center;
  }
}
.child-header__right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.page-settings {
  &--menu {
    display: inline-flex;
    flex-direction: column;
    margin-left: 1rem;
    position: relative;
    .dropdown-toggle {
      width: 60px;
      height: 36px;
      background-color: #fff;
      font-weight: bold;
      padding: 0;
      box-shadow: 0 4px 10px 0 rgba(154,171,201,0.24);
      border-radius: 4px;
    }
  }
  &__dropdown-menu {
    position: absolute;
    right: 0;
    top: 120%;
    background: #FFFFFF;
    box-shadow: 0 6px 10px 0 rgba(20,43,88,0.30);
    filter: drop-shadow(0 3px 6px rgba(20,43,88,0.30));
    min-width: 175px;
    border-radius: 4px;
    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      right: 30px;
      margin-right: -0.15em;
      box-sizing: border-box;
      border: 0.3em solid #ffffff;
      border-color: #ffffff #ffffff transparent transparent;
      transform-origin: 0 0;
      transform: rotate(-45deg);
      z-index: 0;

    }
  }
  &__dropdown-menu-nav {
    margin-bottom: 0;
  }
  &__dropdown-menu-item {
    border-bottom: 1px solid rgba(114,125,146,0.30);
    display: block;
  }
  &__dropdown-menu-link {
    color: #142B58;
    letter-spacing: -0.27px;
    font-size: 14px;
    padding: 0.5rem;
    display: block;
    white-space: nowrap;
    transition: background-color .2s ease-in-out, color .2s ease-in-out;
    &:first-child{
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:last-child{
      border-bottom: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &:hover {
      background-color: #142B58;
      color: #fff;
    }
  }
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 0.3s;
}

.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(15px);
}

.dropdown__menu .dropdown__button {
  small {
    color: #727D92;
  }
}

.dropdown__menu.dropdown__menu--profile {
  min-width: 225px;
  left: auto;
  right: 0;

  .dropdown__button {
    white-space: nowrap;
  }
}

.dropdown__menu.dropdown__menu--navigation {
  // padding: 0 0 15px;
  min-width: 225px;

  .dropdown__button {
    font-size: 14px;
    color: #142B58;
    // padding: 15px 15px 0;
    // margin-right: 25px;
    border-bottom: 0;
    text-align: left;
    text-transform: none;

  }
}

.site-header .navigation--secondary .row {
  height: 100%;
}
.total-user {
  color:#333;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
  font-size: 0.8em;
}

.total-user span:hover {
  cursor: pointer;
  color:#254ffb;
}

.icon-setting {
  margin-left: 10px;
  padding:5px;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
  }
}
.journey-edit-icon {
  margin-right: 10px;
}

.sub-header {
  display: flex;
  &__left {
    display: flex;
    flex: 1;
    justify-content: flex-start !important;
    align-items: center !important;
  }
  &__right {
    display: flex;
    flex: 1;
    justify-content: flex-end !important;
    align-items: center !important;
    margin-right: 15px;
  }
}
</style>
