<template>
  <div class="page">
    <app-header
      child-header-class="container"
      :child-header-navigation="dashboardSubNavigation"
      is-child-header-left-actions
    >
      <div class="button-group">
        <div class="button-group__item dropdown">
          <label
            class="button button--shadow button--type-icon size-sm d-flex align-items-center"
            @click="dropdownAction('showFilters')"
          >
            <icon-container
              name="search"
              :width="14"
              :height="14"
              color="#727d92"
              view-box="0 0 14 14"
              :is-icon-class="false"
            >
              <icon-search />
            </icon-container>
          </label>
          <div
            class="dropdown__menu dropdown__menu--filter"
            v-if="isDropdown === 'showFilters'"
            v-on-clickaway="hideDropdown"
          >
            <div class="form-group form-group--filter">
              <input
                type="text"
                v-model="filterText"
                class="form-control"
                v-sanitize
                @keyup.enter="doFilter"
                :placeholder="$t('filter_by_name')"
              >
              <button
                v-if="filterText"
                class="icon button-reset"
                @click="resetFilter"
              >
                <icon-container
                  name="reset"
                  view-box="0 0 20 20"
                >
                  <icon-reset />
                </icon-container>
              </button>
            </div>
          </div>
        </div>
        <div class="button-group__item dropdown">
          <button
            class="button button--shadow button__dropdown size-sm"
            :class="{'active': isDropdown === 'perPageDropdown' }"
            @click="dropdownAction('perPageDropdown')"
          >
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            {{ $t('view') }} {{ perPage }}
            <icon-container
              name="down"
              view-box="0 0 11 8"
              :is-icon-class="false"
            >
              <icon-down />
            </icon-container>
          </button>
          <div
            class="dropdown__menu dropdown__menu--per-page"
            v-if="isDropdown === 'perPageDropdown'"
            v-on-clickaway="hideDropdown"
          >
            <div
              class="dropdown__menu-item"
              v-for="option in pageOptions"
              :key="option.value"
              @click="pageOptionsSelected(option.value)"
            >
              {{ option.text }}
            </div>
          </div>
        </div>
      </div>
    </app-header>

    <div class="container">
      <div class="usergroup-analytic--title">
        <router-link
          :to="{
            name: 'DashboardOverview',
          }"
        >
          <icon-container
            :width="40"
            :height="18"
            name="Go Back"
            color="#727d92"
          >
            <icon-goback />
          </icon-container>
        </router-link><small>{{ $t('group_analytics') }}</small>
      </div>
      <div class="table-responsive">
        <div
          v-if="tableIsLoading"
          class="lds-dual-ring"
        />
        <vue-table
          :class="{ 'table-fade': tableIsLoading }"
          ref="usergroupAnalyticsTable"
          :api-mode="false"
          :fields="usergroupAnalyticsFields"
          :data-total="totalUsergroupAnalyticsDataCount"
          :data-manager="usergroupAnalyticsDataManager"
          :no-data-template="$t('no_data_info')"
          pagination-path="usergroupAnalyticPagination"
          :per-page="usergroupAnalyticsPerPage"
          :css="css.table"
          @vuetable:pagination-data="onUsergroupAnalyticPaginationData"
        >
          <template
            slot="name_slot"
            slot-scope="props"
          >
            <router-link
              :to="{
                name: 'UserGroupOverview',
                params: {
                  userGroupId: props.rowData.user_group_id,
                  userGroupName: props.rowData.name
                }}"
            >
              {{ props.rowData.name }}
            </router-link>
          </template>
          <template
            slot="completion_rate"
            slot-scope="props"
          >
            {{ $t('rate_info', { rate: props.rowData.completion_rate }) }}
          </template>

          <template
            slot="response_rate"
            slot-scope="props"
          >
            {{ $t('rate_info', { rate: props.rowData.response_rate }) }}
          </template>
        </vue-table>
        <div class="pagination__info">
          <vuetable-pagination-info
            :class="{ 'table-fade': tableIsLoading }"
            ref="usergroupAnalyticPaginationInfo"
            :info-template="$t('pagination_info')"
            :no-data-template="$t('no_data_info')"
          />
          <div class="pagination__items">
            <vuetable-pagination
              ref="usergroupAnalyticPagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onUsergroupAnalyticChangePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';

import { directive as onClickaway } from 'vue-clickaway';

import IconContainer from '@/components/elements/Icon.vue';
import IconGoBack from '@/components/icons/GoBack.vue';
import IconReset from '@/components/icons/Reset.vue';
import IconSearch from '@/components/icons/Search.vue';
import IconDown from '@/components/icons/Down.vue';

import AppHeader from '@/components/AppHeader.vue';
import API from '@/services/';
import {
  API_REPORTS_USERGROUP_ANALYTICS,
} from '@/helpers/config';
import VuetablePagination from '../../components/CustomVuetable/VuetablePagination.vue';

export default {
  name: 'UsergroupAnalytics',

  data() {
    return {
      isDropdown: null,
      snotifyQueueList: [],
      filterText: '',
      perPage: 10,
      searchFor: '',
      tableIsLoading: false,
      data: [],
      usergroupAnalyticsPerPage: 10,
      totalUsergroupAnalyticsDataCount: 0,
      usergroupAnalyticPagination: {
        total: null,
        per_page: 10,
        current_page: 1,
        last_page: 1,
        next_page_url: '?per_page=10&page=2',
        prev_page_url: null,
        from: 1,
        to: 10,
      },

      css: {
        table: {
          tableWrapper: '',
          tableHeaderClass: 'mb-0',
          tableBodyClass: 'mb-0',
          tableClass: '',
          loadingClass: 'blur',
          ascendingIcon: 'fa fa-angle-up',
          descendingIcon: 'fa fa-angle-down',
          ascendingClass: 'sorted-asc',
          descendingClass: 'sorted-desc',
          sortableIcon: '',
          detailRowClass: 'vuetable-detail-row',
          handleIcon: 'fa-bars text-secondary',
          renderIcon(classes, options) {
            return `<i class="${classes.join(' ')}" ${options}></span>`;
          },
        },
        pagination: {
          wrapperClass: 'page-items-list',
          activeClass: 'active',
          disabledClass: 'disabled',
          pageClass: 'page-item',
          linkClass: 'page-link',
          paginationClass: 'pagination',
          paginationInfoClass: 'float-left',
          dropdownClass: 'form-control',
          icons: {
            first: 'fa fa-angle-double-left',
            prev: 'fa fa-angle-left',
            next: 'fa fa-angle-right',
            last: 'fa fa-angle-double-right',
          },
        },
      },
    };
  },

  components: {
    'icon-container': IconContainer,
    'icon-goback': IconGoBack,
    'icon-reset': IconReset,
    'icon-search': IconSearch,
    'icon-down': IconDown,
    'app-header': AppHeader,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  watch: {
    data() {
      this.$refs.usergroupAnalyticsTable.refresh();
    },

    totalUsergroupAnalyticsDataCount() {
      this.usergroupAnalyticPagination.total = this.totalUsergroupAnalyticsDataCount;
    },
    usergroupAnalyticsPerPage() {
      this.usergroupAnalyticPagination.per_page = this.usergroupAnalyticsPerPage;
    },
    $route(from) {
      if (from.name === 'UsergroupAnalytics') {
        this.$refs.usergroupAnalyticsTable.refresh();
        window.scrollTo(0, document.body.scrollHeight);
      }
    },
  },

  computed: {

    formData() {
      const postData = new FormData();
      let orderByColumnName;
      postData.append('customer_id', this.userCustomerId);
      postData.append('length', this.usergroupAnalyticPagination.per_page);
      postData.append('start', this.usergroupAnalyticPagination.from - 1);
      if (this.orderDirection) {
        postData.append('order[0][dir]', this.orderDirection);
      }
      if (this.orderField) {
        if (this.orderField === 'name') {
          orderByColumnName = 2;
        }
        if (this.orderField === 'completion_rate') {
          orderByColumnName = 1;
        }
        if (this.orderField === 'response_rate') {
          orderByColumnName = 3;
        }
        if (this.orderField === 'user_count') {
          orderByColumnName = 4;
        }
        postData.append('order[0][column]', orderByColumnName);
      }
      if (this.searchFor) {
        postData.append('search[value]', this.searchFor);
      }
      return postData;
    },

    pageOptions() {
      return [
        { text: `${this.$t('view')} 10`, value: 10 },
        { text: `${this.$t('view')} 20`, value: 20 },
        { text: `${this.$t('view')} 50`, value: 50 },
        { text: `${this.$t('view')} 100`, value: 100 },
      ];
    },

    userCustomerId() {
      return this.$store.state.auth.customerId;
    },

    isVibonsAdmin() {
      if (this.$store.state && this.$store.state.auth) {
        return this.$store.state.auth.isVibonsAdmin;
      }
      return false;
    },
    isCustomerAdmin() {
      if (this.$store?.state?.auth?.userInfo?.role) return this.$store?.state?.auth?.userInfo?.role === 'customer-admin';
      return false;
    },
    dashboardSubNavigation() {
      let submenu = [
        {
          text: this.$t('overview'),
          url: '/dashboard/overview',
          order: 1,
        },
        {
          text: this.$t('journeys'),
          url: '/dashboard/journeys',
          order: 2,
        },
        {
          text: this.$t('users'),
          url: '/dashboard/users',
          order: 4,
        },
        {
          text: this.$t('quiz_survey'),
          url: '/dashboard/quiz-survey',
          order: 5,
        },
        {
          text: this.$t('report_demands'),
          url: '/dashboard/reportdemands',
          order: 7,
        },
      ];
      if (this.isVibonsAdmin) {
        submenu = submenu.concat([
          {
            text: this.$t('journeys_v2'),
            url: '/dashboard/journeys_v2',
            order: 3,
          },
          {
            text: this.$t('customers_sub'),
            url: '/dashboard/customers',
            order: 6,
          },

        ]);
      }
      if (this.isCustomerAdmin && this.$store.state.auth?.userInfo?.customer?.customizations?.journeyv2) {
        submenu = submenu.concat({
          text: this.$t('journeys_v2'),
          url: '/dashboard/journeys_v2',
          order: 3,
        });
      }

      submenu.sort((a, b) => a.order - b.order);
      return submenu;
    },
    usergroupAnalyticsFields() {
      return [
        {
          name: 'name_slot',
          title: this.$t('group_name'),
          sortField: 'name',
          width: '40%',
          dataClass: 'gradient-back',
        },
        {
          name: 'completion_rate',
          title: this.$t('completion_rate'),
          sortField: 'completion_rate',
          titleClass: 'center',
          dataClass: 'center',
          width: '20%',
        },
        {
          name: 'response_rate',
          title: this.$t('response_rate'),
          sortField: 'response_rate',
          titleClass: 'center',
          dataClass: 'center',
          width: '20%',
        },
        {
          name: 'user_count',
          title: this.$t('user_count'),
          titleClass: 'center',
          sortField: 'user_count',
          dataClass: 'center',
          width: '20%',
        },
      ];
    },
  },

  methods: {
    pageOptionsSelected(value) {
      this.usergroupAnalyticsPerPage = value;
      this.hideDropdown();
    },

    usergroupAnalyticsLoadData() {
      return new Promise((resolve, reject) => {
        API.post(API_REPORTS_USERGROUP_ANALYTICS, this.formData)
          .then((response) => {
            this.totalUsergroupAnalyticsDataCount = this.searchFor ? response.data.recordsFiltered : response.data.recordsTotal;
            resolve(response.data.data);
          })
          .catch((error) => {
            this.$helpers.displayError(error);
            reject(error);
          });
      });
    },
    async usergroupAnalyticsDataManager(sortOrder, paginate) {
      let usergroupAnalyticPagination = paginate;
      this.isLoading();
      if (sortOrder.length > 0) {
        this.orderDirection = sortOrder[0].direction;
        this.orderField = sortOrder[0].sortField;
      }

      if (usergroupAnalyticPagination.current_page > Math.ceil(this.totalUsergroupAnalyticsDataCount / usergroupAnalyticPagination.per_page)) {
        this.usergroupAnalyticPagination.current_page = 1;
        this.usergroupAnalyticPagination.from = 1;
        this.usergroupAnalyticPagination.to = this.totalUsergroupAnalyticsDataCount > (this.usergroupAnalyticPagination.from + this.usergroupAnalyticPagination.per_page - 1) ? this.usergroupAnalyticPagination.from + this.usergroupAnalyticPagination.per_page - 1 : this.totalUsergroupAnalyticsDataCount;
      } else {
        this.usergroupAnalyticPagination.current_page = usergroupAnalyticPagination.current_page;
        this.usergroupAnalyticPagination.from = usergroupAnalyticPagination.from;
        this.usergroupAnalyticPagination.to = this.totalUsergroupAnalyticsDataCount > (this.usergroupAnalyticPagination.from + this.usergroupAnalyticPagination.per_page - 1) ? this.usergroupAnalyticPagination.from + this.usergroupAnalyticPagination.per_page - 1 : this.totalUsergroupAnalyticsDataCount;
      }

      let data;

      await this.usergroupAnalyticsLoadData().then((response) => {
        data = response;
        usergroupAnalyticPagination = this.$refs.usergroupAnalyticsTable.makePagination(
          this.usergroupAnalyticPagination.total,
          this.usergroupAnalyticsPerPage,
        );
      });
      if (usergroupAnalyticPagination.current_page > usergroupAnalyticPagination.last_page) {
        usergroupAnalyticPagination.current_page = 1;
        usergroupAnalyticPagination.from = 1;
        usergroupAnalyticPagination.to = usergroupAnalyticPagination.total > (usergroupAnalyticPagination.from + usergroupAnalyticPagination.per_page - 1) ? usergroupAnalyticPagination.from + usergroupAnalyticPagination.per_page - 1 : usergroupAnalyticPagination.total;
      }
      this.usergroupAnalyticPagination = usergroupAnalyticPagination;

      this.isLoaded();
      return {
        usergroupAnalyticPagination,
        data,
      };
    },
    onUsergroupAnalyticPaginationData(paginationData) {
      this.$refs.usergroupAnalyticPagination.setPaginationData(paginationData);
      this.$refs.usergroupAnalyticPaginationInfo.setPaginationData(paginationData);
    },
    onUsergroupAnalyticChangePage(page) {
      this.$refs.usergroupAnalyticsTable.changePage(page);
    },

    isLoading() {
      this.tableIsLoading = true;
    },
    isLoaded() {
      this.tableIsLoading = false;
    },

    dropdownAction(value) {
      this.isDropdown = value;
    },
    hideDropdown() {
      this.isDropdown = null;
    },
    doFilter() {
      this.searchFor = this.filterText;
      this.$refs.usergroupAnalyticsTable.refresh();
    },
    resetFilter() {
      this.searchFor = null;
      this.filterText = '';
      this.$refs.usergroupAnalyticsTable.refresh();
    },

  },

  table: {
    tableWrapper: '',
    tableHeaderClass: '',
    tableClass: 'ui selectable unstackable celled table',
    loadingClass: 'loading',
    ascendingIcon: 'blue chevron up icon',
    descendingIcon: 'blue chevron down icon',
    ascendingClass: 'sorted-asc',
    descendingClass: 'sorted-desc',
    sortableIcon: 'grey sort icon',
    handleIcon: 'grey sidebar icon',
  },
};
</script>

<style lang="scss" scoped>
  .usergroup-analytic--title {
    display: inline-flex;
    font-size: 26px;
    color: #727D92;
    align-items: center;
    font-weight: 500;
    small {
      font-size: 16px;
      margin-top: 5px;
      margin-left: 10px;
    }
    a {
      margin-right: 10px;
      display: inline-block;
      svg {
        transition: transform .2s ease-in;
      }
      &:hover {
        svg {
          transform: translateX(-5px);
        }
      }
    }
  }
  ::v-deep .gradient-back {
  background-image: linear-gradient(to left, #254ffb, #f27299);
  color: #fff;
  background-size: 100% 30px;
  background-repeat: no-repeat;
  background-position: center;

  a {
    color: #fff!important;
    &:hover {
      font-weight: bolder;
    }
  }
}
</style>
