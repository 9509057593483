var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "form-group form-group--cards" },
      _vm._l(_vm.questions, function (item, qindex) {
        return _c(
          "div",
          { key: qindex, staticClass: "quiz-text-item" },
          [
            _vm.editMode
              ? _c(
                  "div",
                  { staticClass: "single-update-block" },
                  [
                    _c(
                      "transition",
                      { attrs: { name: "fade", mode: "in-out" } },
                      [
                        _vm.isDirty(item)
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "button button--alternative text-size-xs size-sm m-0 p-2 float-right",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.isPending,
                                },
                                on: { click: _vm.saveChanges },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("save_changes")) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "transition",
                      { attrs: { name: "fade", mode: "in-out" } },
                      [
                        _vm.isUpdated(item)
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "button button--secondary shadow-none text-size-xs size-sm m-0 p-2 float-right",
                                attrs: { type: "button" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("saved_changes")) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            item.type === "standard"
              ? [
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("div", { staticClass: "col-12" }, [
                        _c("div", { staticClass: "form-group" }, [
                          item.choices[0] &&
                          Object.prototype.hasOwnProperty.call(
                            item?.choices[0],
                            "image"
                          ) &&
                          (item.choices[0]?.image ||
                            item?.choices[0]?.choice_text === "-" ||
                            Object.prototype.hasOwnProperty.call(
                              item?.choices[0],
                              "imagePreview"
                            ))
                            ? _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  attrs: { for: "" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("question")) +
                                      " " +
                                      _vm._s(
                                        qindex +
                                          1 +
                                          ": " +
                                          _vm.$t("quiz_type_image")
                                      )
                                  ),
                                ]
                              )
                            : _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  attrs: { for: "" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("question")) +
                                      " " +
                                      _vm._s(
                                        qindex +
                                          1 +
                                          ": " +
                                          _vm.$t("quiz_type_text")
                                      )
                                  ),
                                ]
                              ),
                          _c("textarea", {
                            directives: [
                              { name: "resizable", rawName: "v-resizable" },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.question,
                                expression: "item.question",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate.disable",
                                value: {
                                  required: true,
                                  min: 5,
                                  minAnswer: item,
                                  oneAnswer: item,
                                },
                                expression:
                                  "{\n                  required: true,\n                  min:5,\n                  minAnswer: item,\n                  oneAnswer: item,\n                }",
                                modifiers: { disable: true },
                              },
                            ],
                            key: qindex,
                            staticClass: "form-control",
                            class: {
                              "is-danger": _vm.errors.has(
                                _vm.veeField(`question${qindex}`)
                              ),
                            },
                            attrs: {
                              disabled: _vm.disabled,
                              rows: "1",
                              placeholder: _vm.$t("question_placeholder"),
                              "data-vv-scope": _vm.asScope,
                              name: "question" + qindex,
                              "data-vv-as": _vm.$t("question_") + (qindex + 1),
                            },
                            domProps: { value: item.question },
                            on: {
                              focus: function ($event) {
                                return _vm.triggerPreviewNextQuestion(qindex)
                              },
                              blur: _vm.checkItemValidate,
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(item, "question", $event.target.value)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.errors.has(_vm.veeField(`question${qindex}`))
                            ? _c(
                                "span",
                                {
                                  key: "error" + qindex,
                                  staticClass: "help is-danger",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first(
                                        _vm.veeField(`question${qindex}`)
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      _vm._l(item.choices, function (choice, aindex) {
                        return _c(
                          "div",
                          { key: aindex, staticClass: "col-12 col-md-6" },
                          [
                            choice &&
                            Object.prototype.hasOwnProperty.call(
                              choice,
                              "image"
                            ) &&
                            (choice?.image ||
                              item?.choices[0]?.choice_text === "-" ||
                              Object.prototype.hasOwnProperty.call(
                                choice,
                                "imagePreview"
                              ))
                              ? _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-column" },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "form-label",
                                              attrs: { for: "" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("quiz_type_image")
                                                ) +
                                                  " " +
                                                  _vm._s(aindex + 1)
                                              ),
                                            ]
                                          ),
                                          _c("thumbnail-container", {
                                            staticClass: "card card--upload",
                                            class: {
                                              "is-danger": _vm.errors.has(
                                                `image${qindex}${aindex}`
                                              ),
                                            },
                                            attrs: {
                                              "thumbnail-image":
                                                _vm.questions[qindex].choices[
                                                  aindex
                                                ]?.imagePreview,
                                              "quiz-indexes": {
                                                qindex,
                                                aindex,
                                              },
                                              disabled: _vm.disabled,
                                              "is-multiple": true,
                                              "vee-rule":
                                                "size:5000|mimes:image/jpeg,image/png",
                                              "vee-scoop-name": "quiz",
                                              "vee-name": `image${qindex}${aindex}`,
                                              "vee-as":
                                                _vm.$t("quiz_type_image"),
                                            },
                                            on: {
                                              update: _vm.imageAdd,
                                              updateMultiple:
                                                _vm.imageAddMultiple,
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.errors.has(
                                                    `image${qindex}${aindex}`
                                                  ),
                                                  expression:
                                                    "errors.has(`image${qindex}${aindex}`)",
                                                },
                                              ],
                                              staticClass: "help is-danger",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.errors.first(
                                                    `image${qindex}${aindex}`
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("div", [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "form-label",
                                            attrs: { for: "" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("choice")) +
                                                " " +
                                                _vm._s(aindex + 1)
                                            ),
                                          ]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: choice.choice_text,
                                              expression: "choice.choice_text",
                                            },
                                          ],
                                          key: `${qindex}${aindex}`,
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            disabled: _vm.disabled,
                                            name: "answer",
                                            placeholder: _vm.$t(
                                              "answer_placeholder_optional"
                                            ),
                                          },
                                          domProps: {
                                            value: choice.choice_text,
                                          },
                                          on: {
                                            blur: _vm.checkItemValidate,
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                choice,
                                                "choice_text",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-between align-items-center",
                                    },
                                    [
                                      item.choices.length > 1
                                        ? _c(
                                            "button",
                                            {
                                              attrs: { disabled: _vm.disabled },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeAnswerItem(
                                                    aindex,
                                                    qindex
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "icon-container",
                                                {
                                                  attrs: {
                                                    width: 15,
                                                    height: 15,
                                                    "view-box": "0 0 25 25",
                                                    name: "trash",
                                                    color: "#727d92",
                                                  },
                                                },
                                                [_c("icon-trash")],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "checkbox",
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: { for: qindex + "-answers" },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: choice.is_correct,
                                                expression: "choice.is_correct",
                                              },
                                            ],
                                            key: qindex + "-answers",
                                            attrs: {
                                              type: "checkbox",
                                              id: aindex,
                                              disabled: _vm.disabled,
                                              name: qindex + "-answers",
                                              "true-value": 1,
                                              "false-value": 0,
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                choice.is_correct
                                              )
                                                ? _vm._i(
                                                    choice.is_correct,
                                                    null
                                                  ) > -1
                                                : _vm._q(choice.is_correct, 1),
                                            },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  var $$a = choice.is_correct,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked ? 1 : 0
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          choice,
                                                          "is_correct",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          choice,
                                                          "is_correct",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      choice,
                                                      "is_correct",
                                                      $$c
                                                    )
                                                  }
                                                },
                                                _vm.checkItemValidate,
                                              ],
                                            },
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("correct_answer")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-label",
                                      attrs: { for: "" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("choice")) +
                                          " " +
                                          _vm._s(aindex + 1)
                                      ),
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: choice.choice_text,
                                        expression: "choice.choice_text",
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    key: `${qindex}${aindex}`,
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      disabled: _vm.disabled,
                                      name: "answer",
                                      placeholder: _vm.$t("answer_placeholder"),
                                      "data-vv-scope": _vm.asScope,
                                      "data-vv-name": `answer${qindex}${aindex}`,
                                      "data-vv-as": _vm.$t("answer"),
                                    },
                                    domProps: { value: choice.choice_text },
                                    on: {
                                      blur: _vm.checkItemValidate,
                                      focus: () => {
                                        _vm.addAnswerItem(
                                          "text",
                                          qindex,
                                          aindex
                                        )
                                        _vm.triggerPreviewNextQuestion(qindex)
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          choice,
                                          "choice_text",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm.errors.has(
                                    _vm.veeField(`answer${qindex}${aindex}`)
                                  )
                                    ? _c(
                                        "span",
                                        { staticClass: "help is-danger" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                _vm.veeField(
                                                  `answer${qindex}${aindex}`
                                                )
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-between align-items-center",
                                    },
                                    [
                                      item.choices.length > 1
                                        ? _c(
                                            "button",
                                            {
                                              attrs: { disabled: _vm.disabled },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeAnswerItem(
                                                    aindex,
                                                    qindex
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "icon-container",
                                                {
                                                  attrs: {
                                                    width: 15,
                                                    height: 15,
                                                    "view-box": "0 0 25 25",
                                                    name: "trash",
                                                    color: "#727d92",
                                                  },
                                                },
                                                [_c("icon-trash")],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "checkbox",
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: { for: qindex + "-answers" },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: choice.is_correct,
                                                expression: "choice.is_correct",
                                              },
                                            ],
                                            key: qindex + "-answers",
                                            attrs: {
                                              type: "checkbox",
                                              id: aindex,
                                              disabled: _vm.disabled,
                                              name: qindex + "-answers",
                                              "true-value": 1,
                                              "false-value": 0,
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                choice.is_correct
                                              )
                                                ? _vm._i(
                                                    choice.is_correct,
                                                    null
                                                  ) > -1
                                                : _vm._q(choice.is_correct, 1),
                                            },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  var $$a = choice.is_correct,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked ? 1 : 0
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          choice,
                                                          "is_correct",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          choice,
                                                          "is_correct",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      choice,
                                                      "is_correct",
                                                      $$c
                                                    )
                                                  }
                                                },
                                                _vm.checkItemValidate,
                                              ],
                                            },
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("correct_answer")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                          ]
                        )
                      }),
                      _vm.checkNeedForAddAnswer(item.choices)
                        ? _c(
                            "div",
                            {
                              staticClass: "align-items-center d-flex",
                              staticStyle: { margin: "0 auto" },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "button button--alternative button--icon text-size-xs",
                                  attrs: {
                                    type: "button",
                                    disabled: _vm.disabled,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.addAnswerSmart(
                                        item.choices,
                                        qindex
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "icon-container",
                                    {
                                      staticClass: "icon-svg",
                                      attrs: {
                                        name: "circleplusfull",
                                        "view-box": "0 0 512 512",
                                        color: "#fff",
                                        "is-icon-class": false,
                                      },
                                    },
                                    [_c("icon-circleplusfull")],
                                    1
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("add_answer_button")) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      item
                        ? _c(
                            "button",
                            {
                              staticClass: "button-remove",
                              attrs: { disabled: _vm.disabled },
                              on: {
                                click: function ($event) {
                                  return _vm.removeQuestionItem(qindex)
                                },
                              },
                            },
                            [
                              _c(
                                "icon-container",
                                {
                                  attrs: {
                                    width: 15,
                                    height: 15,
                                    "view-box": "0 0 25 25",
                                    name: "trash",
                                    color: "#727d92",
                                  },
                                },
                                [_c("icon-trash")],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              : _vm._e(),
            item.type === "pairs"
              ? [
                  _c(
                    "div",
                    { staticClass: "row", attrs: { set: (_vm.pairIndex = 0) } },
                    [
                      _c("div", { staticClass: "col-12" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { staticClass: "form-label", attrs: { for: "" } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("question")) +
                                  " " +
                                  _vm._s(
                                    qindex +
                                      1 +
                                      ": " +
                                      _vm.$t("quiz_type_pairs")
                                  )
                              ),
                            ]
                          ),
                          _c("textarea", {
                            directives: [
                              { name: "resizable", rawName: "v-resizable" },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.question,
                                expression: "item.question",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate.disable",
                                value: {
                                  required: true,
                                  min: 5,
                                  minAnswer: item,
                                  oneAnswer: item,
                                },
                                expression:
                                  "{\n                  required: true,\n                  min:5,\n                  minAnswer: item,\n                  oneAnswer: item,\n                }",
                                modifiers: { disable: true },
                              },
                            ],
                            key: qindex,
                            staticClass: "form-control",
                            class: {
                              "is-danger": _vm.errors.has(
                                _vm.veeField(`question${qindex}`)
                              ),
                            },
                            attrs: {
                              disabled: _vm.disabled,
                              rows: "1",
                              placeholder: _vm.$t("question_placeholder"),
                              "data-vv-scope": _vm.asScope,
                              name: "question" + qindex,
                              "data-vv-as": _vm.$t("question_") + (qindex + 1),
                            },
                            domProps: { value: item.question },
                            on: {
                              focus: function ($event) {
                                return _vm.triggerPreviewNextQuestion(qindex)
                              },
                              blur: _vm.checkItemValidate,
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(item, "question", $event.target.value)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.errors.has(_vm.veeField(`question${qindex}`))
                            ? _c(
                                "span",
                                {
                                  key: "error" + qindex,
                                  staticClass: "help is-danger",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first(
                                        _vm.veeField(`question${qindex}`)
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      _vm._l(item.choices, function (choice, aindex) {
                        return _c(
                          "div",
                          { key: aindex, staticClass: "col-12 col-md-6" },
                          [
                            _c(
                              "div",
                              {
                                class:
                                  aindex % 2 === 0
                                    ? "form-group first-pair"
                                    : "form-group",
                                attrs: {
                                  set:
                                    aindex % 2 === 0 ? ++_vm.pairIndex : null,
                                },
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    class:
                                      aindex % 2 === 0
                                        ? "form-label"
                                        : "form-label  second-pair",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          aindex % 2 === 0
                                            ? `${_vm.$t("pair")} ${
                                                _vm.pairIndex
                                              }`
                                            : _vm.$t(
                                                "pair_second_item_placehoder"
                                              )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: choice.choice_text,
                                      expression: "choice.choice_text",
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  key: `${qindex}${aindex}`,
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    disabled: _vm.disabled,
                                    name: "answer",
                                    placeholder:
                                      aindex % 2 === 0
                                        ? _vm.$t(
                                            "answer_placeholder_quiz_pairs_first"
                                          )
                                        : _vm.$t(
                                            "answer_placeholder_quiz_pairs_second"
                                          ),
                                    "data-vv-scope": _vm.asScope,
                                    "data-vv-name": `answer${qindex}${aindex}`,
                                    "data-vv-as": _vm.$t("answer"),
                                  },
                                  domProps: { value: choice.choice_text },
                                  on: {
                                    blur: _vm.checkItemValidate,
                                    focus: function ($event) {
                                      _vm.addAnswerItem("pairs", qindex, aindex)
                                      _vm.triggerPreviewNextQuestion(qindex)
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        choice,
                                        "choice_text",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm.errors.has(
                                  _vm.veeField(`answer${qindex}${aindex}`)
                                )
                                  ? _c(
                                      "span",
                                      { staticClass: "help is-danger" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.errors.first(
                                              _vm.veeField(
                                                `answer${qindex}${aindex}`
                                              )
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-between align-items-center",
                                  },
                                  [
                                    item.choices.length > 2 &&
                                    aindex % 2 === 0 &&
                                    _vm.pairIndex > 2
                                      ? _c(
                                          "button",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeAnswerItem(
                                                  aindex,
                                                  qindex
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "icon-container",
                                              {
                                                attrs: {
                                                  width: 15,
                                                  height: 15,
                                                  "view-box": "0 0 25 25",
                                                  name: "trash",
                                                  color: "#727d92",
                                                },
                                              },
                                              [_c("icon-trash")],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      item
                        ? _c(
                            "button",
                            {
                              staticClass: "button-remove",
                              attrs: { disabled: _vm.disabled },
                              on: {
                                click: function ($event) {
                                  return _vm.removeQuestionItem(qindex)
                                },
                              },
                            },
                            [
                              _c(
                                "icon-container",
                                {
                                  attrs: {
                                    width: 15,
                                    height: 15,
                                    "view-box": "0 0 25 25",
                                    name: "trash",
                                    color: "#727d92",
                                  },
                                },
                                [_c("icon-trash")],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              : _vm._e(),
          ],
          2
        )
      }),
      0
    ),
    _c("div", { staticClass: "modal-footer" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-md-4" }, [
          _c(
            "button",
            {
              staticClass:
                "button button--alternative button--icon u-full text-size-xs size-sm",
              attrs: { type: "button", disabled: _vm.disabled },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.addQuestion("text")
                },
              },
            },
            [
              _c(
                "icon-container",
                {
                  staticClass: "icon-svg",
                  attrs: {
                    name: "circleplusfull",
                    "view-box": "0 0 512 512",
                    color: "#fff",
                    "is-icon-class": false,
                  },
                },
                [_c("icon-circleplusfull")],
                1
              ),
              _vm._v(" " + _vm._s(_vm.$t("add_button_quiz_text")) + " "),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "col-12 col-md-4" }, [
          _c(
            "button",
            {
              staticClass:
                "button button--alternative button--icon u-full text-size-xs size-sm",
              attrs: { type: "button", disabled: _vm.disabled },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.addQuestion("image")
                },
              },
            },
            [
              _c(
                "icon-container",
                {
                  staticClass: "icon-svg",
                  attrs: {
                    name: "circleplusfull",
                    "view-box": "0 0 512 512",
                    color: "#fff",
                    "is-icon-class": false,
                  },
                },
                [_c("icon-circleplusfull")],
                1
              ),
              _vm._v(" " + _vm._s(_vm.$t("add_button_quiz_image")) + " "),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "col-12 col-md-4" }, [
          _c(
            "button",
            {
              staticClass:
                "button button--alternative button--icon u-full text-size-xs size-sm",
              attrs: { type: "button", disabled: _vm.disabled },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.addQuestion("pairs")
                },
              },
            },
            [
              _c(
                "icon-container",
                {
                  staticClass: "icon-svg",
                  attrs: {
                    name: "circleplusfull",
                    "view-box": "0 0 512 512",
                    color: "#fff",
                    "is-icon-class": false,
                  },
                },
                [_c("icon-circleplusfull")],
                1
              ),
              _vm._v(" " + _vm._s(_vm.$t("add_button_quiz_pairs")) + " "),
            ],
            1
          ),
        ]),
      ]),
      _vm.errors.has(_vm.veeField("question"))
        ? _c("span", { staticClass: "help is-danger" }, [
            _vm._v(_vm._s(_vm.errors.first(_vm.veeField("question")))),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }