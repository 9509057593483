<template>
  <div
    class="modal-container"
  >
    <div
      class="modal"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ $t('survey_stats') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal()"
              v-html="'&times;'"
            />
          </header>
          <div class="modal-body">
            <div class="form form--horizontal form--strong">
              <div class="form form--horizontal">
                <div
                  class="d-flex flex-row justify-content-between align-items-center w-100"
                  v-for="(stat, index) in stats"
                  :key="index"
                >
                  <figure class="highcharts-figure w-auto">
                    <highcharts
                      class="chart-container"
                      :options="surveyStatsOptions(stat)"
                      ref="completionGauge"
                    />
                  </figure>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="button button--primary"
                  @click="closeModal()"
                >
                  <span>
                    {{ $t('close') }}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/services/';
import {
  API_SURVEY_V2_STATS,
} from '@/helpers/config';

export default {
  name: 'ModalSurveyStats',
  inject: ['$validator'],

  data() {
    return {
      stats: null,
    };
  },

  watch: {
  },

  computed: {
    modalData() {
      return this.$store.state.modal.modalData;
    },

  },
  created() {
    this.getSurveyStats();
  },
  methods: {
    surveyStatsOptions(item) {
      const title = item?.question;

      let dataValues = [];
      if (item?.type === 'multiple-choice' || item?.type === 'single-choice') {
        dataValues = item.answer_data && item.answer_data.map(x => ({
          name: x.choice_text,
          y: x.count,
        }));
      }
      if (item?.type === 'rating' && item?.options) {
        const totalSteps = item?.options?.step || 0;
        const minValue = item?.options?.min_value || '';
        const maxValue = item?.options?.max_value || '';
        console.log('totalSteps:', totalSteps, minValue, maxValue);
        // eslint-disable-next-line no-restricted-syntax
        for (let i = 1; i <= totalSteps; i += 1) {
          const choice = item?.answer_data?.find(x => x?.value && x?.value === i);
          let value = 0;
          if (choice) {
            value = choice?.count || 0;
          }
          let name = i;

          if (totalSteps > 0) {
            if (i === 1) {
              name = `${minValue} - ${i}`;
            }
            if (i === totalSteps) {
              name = `${maxValue} - ${totalSteps}`;
            }
          }
          const p = {
            name,
            y: value,
          };
          console.log('p:', p);
          dataValues.push(p);
        }
      }

      return {
        title: {
          text: `${title}`,
          style: {
            fontSize: '16px',
            color: 'black',
            margin: '20px',
          },
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
          },
        },
        credits: {
          enabled: false,
        },
        series: [{
          type: 'column',
          name: '',
          data: dataValues,
          // size: 120,
          // innerSize: '80%',
          showInLegend: true,
          dataLabels: {
            enabled: true,
          },
        }],
      };
    },
    closeModal() {
      this.$store.commit('modal/toggleModal');
    },
    async getSurveyStats() {
      this.loading = true;
      let response = null;
      try {
        if (this.modalData?.journey2_content_id) {
          response = await API.get(`${API_SURVEY_V2_STATS}${this.modalData?.journey2_content_id}`);
          if (response) {
            this.stats = response.data.data;
            // console.log('response:', response);
            // this.nps = Number(response?.data?.data?.nps) || 0;
            // this.total = Number(response?.data?.data?.total) || 0;
            // this.detractors_total = Number(response?.data?.data?.detractors_total) || 0;
            // this.promoters_total = Number(response?.data?.data?.promoters_total) || 0;
            // this.passives_total = this.total - (this.detractors_total + this.promoters_total) || 0;
            console.log('response:', response.data.data);
          }
        }
      } catch (error) {
        this.$helpers.displayError(error);
      } finally {
        this.loading = false;
      }
    },

  },
};
</script>
<style lang="scss" scoped>
  .highcharts-figure .chart-container {
    width: 100%;
    height: 100%;
    min-height: 300px;
    margin: 16px auto;
}
.w-100 {
    width: 100% !important;
    background-color: '#f8f8f803';
}
.modal .modal-body {
    max-width: 980px;
}
</style>
