<template>
  <div class="page dashboard">
    <app-header
      child-header-class="container-fluid"
      :child-header-navigation="dashboardSubNavigation"
      is-child-header-left-actions
      :child-title="childTitle"
    />
    <!-- <div class="dboard-container-full">
      <div class="row dboard-top-row">
        <div
          class="col-12 col-md-6 dboard-top-box"
          id="dboard-top-box-left"
        >
          <div class="col dboard-bg-white">
            <div style="width:20px;">
              <span
                class="vtooltip info-icon-left"
                v-tooltip.right="$t('info_user_completion_rate')"
              >
                <icon-container
                  name="infocircle"
                  view-box="0 0 512 512"
                  :is-icon-class="false"
                >
                  <icon-infocircle />
                </icon-container>
              </span>
            </div>
            <h5>{{ $t('completion_rate').toUpperCase() }}</h5>
            <div class="dboard-top-box-inside dboard-top-box-inside-inner">
              <div class="dboard-box-vertical">
                <div class="row dboard-top-row">
                  <div class="col d-flex justify-content-center">
                    <span class="percent">{{ this.completionRateDisplay }}</span>
                    <span
                      v-if="completionRateArrow === 'arrow-up'"
                      class="percent d-flex align-items-center"
                    >
                      <span
                        id="arrowup"
                      >
                        <icon-container
                          name="arrowup"
                          :width="25"
                          :height="25"
                          color="#2dcf85"
                          view-box="0 0 448 512"
                          :is-icon-class="false"
                        >
                          <icon-arrowup />
                        </icon-container>
                      </span>
                    </span>
                    <span
                      v-else-if="completionRateArrow === 'arrow-down'"
                      class="percent d-flex align-items-center"
                    >
                      <span
                        id="arrowdown"
                      >
                        <icon-container
                          name="arrowdown"
                          :width="25"
                          :height="25"
                          color="#ea0505"
                          view-box="0 0 448 512"
                          :is-icon-class="false"
                        >
                          <icon-arrowdown />
                        </icon-container>
                      </span>
                    </span>
                    <span
                      v-else
                      class="percent d-flex align-items-center"
                    >
                      <span id="dash">
                        <icon-container
                          name="arrowhorizontal"
                          :width="25"
                          :height="25"
                          color="#727D92"
                          view-box="0 0 512 512"
                          :is-icon-class="false"
                        >
                          <icon-arrowhorizontal />
                        </icon-container>
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  class="row"
                  style="margin-top:15px"
                >
                  <p v-if="completionRateArrow === 'arrow-up'">
                    {{ $t('completion_rate_up_sub_msg', {rate: this.completionRateCompareToPrevPercent}) }}
                  </p>
                  <p v-else-if="completionRateArrow === 'arrow-down'">
                    {{ $t('completion_rate_down_sub_msg', {rate: this.completionRateCompareToPrevPercent}) }}
                  </p>
                  <p v-else>
                    {{ $t('completion_rate_none_sub_msg', {rate: this.completionRateCompareToPrevPercent}) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-md-6 dboard-top-box"
          id="dboard-top-box-right"
        >
          <div class="col dboard-bg-white">
            <h5>{{ $t('actionable_analytics').toUpperCase() }}</h5>
            <div class="dboard-top-box-inside dboard-top-box-inside-inner">
              <div class="dboard-top-row">
                <div class="col-9 text-left">
                  <a
                    href="javascript:void(0)"
                    v-tooltip.right="$t('info_user_notification_enabled')"
                    class="info-icon-inside d-inline-flex"
                  >
                    <h6>{{ $t('notification_enabled') }}</h6>
                    <icon-container
                      name="infocircle"
                      view-box="0 0 512 512"
                      :is-icon-class="false"
                    >
                      <icon-infocircle />
                    </icon-container>
                  </a>
                </div>
                <div class="col-3">
                  <p v-bind="{ class: this.notificationEnabled === 0 ? $t('no') : $t('yes') }">
                    {{ notificationEnabled === 0 ? $t('no') : $t('yes') }}
                  </p>
                </div>
              </div>
              <div class="dboard-top-row">
                <div class="col-9 text-left">
                  <a
                    href="javascript:void(0)"
                    v-tooltip.right="$t('info_user_mobile_download_penetration')"
                    class="info-icon-inside d-inline-flex"
                  >
                    <h6>{{ $t('mobile_download_penetration') }}</h6>
                    <icon-container
                      name="infocircle"
                      view-box="0 0 512 512"
                      :is-icon-class="false"
                    >
                      <icon-infocircle />
                    </icon-container>
                  </a>
                </div>
                <div class="col-3">
                  <p v-bind="{ class: this.mobileDownloadPenetration === 0 ? $t('no') : $t('yes') }">
                    {{ mobileDownloadPenetration === 0 ? $t('no') : $t('yes') }}
                  </p>
                </div>
              </div>
              <div class="dboard-top-row">
                <div class="col-9 text-left">
                  <a
                    href="javascript:void(0)"
                    v-tooltip.right="$t('info_user_active_users')"
                    class="info-icon-inside d-inline-flex"
                  >
                    <h6>{{ $t('active_user') }}</h6>
                    <icon-container
                      name="infocircle"
                      view-box="0 0 512 512"
                      :is-icon-class="false"
                    >
                      <icon-infocircle />
                    </icon-container>
                  </a>
                </div>
                <div class="col-3">
                  <p v-bind="{ class: this.activeUsers === 0 ? $t('no'): $t('yes') }">
                    {{ activeUsers === 0 ? $t('no'): $t('yes') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <main class="page__body mb-4 pb-4">
      <div
        class="vuetable-wrapper"
      >
        <h5 class="sub-title">
          {{ $t('user_journeys') }}
        </h5>
        <ReportJourneyUserJourneys
          :prop-user-id="userId"
          :prop-user-email="$route.query.email"
          :prop-user-name="$route.query.first_name"
          :prop-user-surname="$route.query.last_name"
        />
      </div>
      <div class="vuetable-wrapper">
        <div class="button-group">
          <!--region Search-->
          <div class="button-group__item dropdown">
            <label
              class="button button--shadow button--type-icon size-sm d-flex align-items-center"
              @click="dropdownAction('showFilters')"
            >
              <icon-container
                name="search"
                :width="14"
                :height="14"
                color="#727d92"
                view-box="0 0 14 14"
                :is-icon-class="false"
              >
                <icon-search />
              </icon-container>
            </label>
            <div
              class="dropdown__menu dropdown__menu--filter"
              v-if="isDropdown === 'showFilters'"
              v-on-clickaway="hideDropdown"
            >
              <div class="form-group form-group--filter">
                <!--suppress HtmlFormInputWithoutLabel-->
                <input
                  type="text"
                  v-model="filterText"
                  class="form-control"
                  v-sanitize
                  @keyup.enter="doFilter"
                  :placeholder="$t('filter_by_name')"
                >
                <button
                  v-if="filterText"
                  class="icon button-reset"
                  @click="resetFilter"
                >
                  <icon-container
                    name="reset"
                    view-box="0 0 20 20"
                  >
                    <icon-reset />
                  </icon-container>
                </button>
              </div>
            </div>
          </div>
          <!--endregion-->
          <!--region Paging Count-->
          <div class="button-group__item dropdown">
            <button
              class="button button--shadow button__dropdown size-sm"
              :class="{'active': isDropdown === 'perPageDropdown' }"
              @click="dropdownAction('perPageDropdown')"
            >
              <!--eslint-disable-next-line vue-i18n/no-raw-text-->
              {{ $t('view') }} {{ perPage }}
              <icon-container
                name="down"
                view-box="0 0 11 8"
                :is-icon-class="false"
              >
                <icon-down />
              </icon-container>
            </button>
            <div
              class="dropdown__menu dropdown__menu--per-page"
              v-if="isDropdown === 'perPageDropdown'"
              v-on-clickaway="hideDropdown"
            >
              <div
                class="dropdown__menu-item"
                v-for="option in pageOptions"
                :key="option.value"
                @click="pageOptionsSelected(option.value)"
              >
                {{ option.text }}
              </div>
            </div>
          </div>
          <!--endregion-->
        </div>
        <div
          v-if="tableIsLoading"
          class="lds-dual-ring"
        />
        <vue-table
          :class="{ 'table-fade': tableIsLoading }"
          ref="vuetable"
          :api-mode="false"
          :fields="fields"
          :track-by="data.options"
          wrapper-class="vuetable-wrapper"
          :data-total="totalDataCount"
          :data-manager="dataManager"
          :no-data-template="$t('no_data_info')"
          pagination-path="pagination"
          :per-page="perPage"
          :css="css.table"
          @vuetable:pagination-data="onPaginationData"
        >
          <template
            slot="date"
            slot-scope="props"
          >
            {{ getCurrentLocaleDate(props.rowData.date) }}
          </template>
          <template
            slot="type-slot"
            slot-scope="props"
          >
            <div class="text-center">
              <span
                class="icon-item"
                v-html="$helpers.iconType(props.rowData.type)"
              />
            </div>
          </template>
          <template
            slot="action-slot"
            slot-scope="props"
          >
            <div class="text-center">
              <icon-container
                v-if="props.rowData.action === 'not_completed'"
                name="eye"
                view-box="0 0 576 512"
                :is-icon-class="false"
              >
                <icon-eye />
              </icon-container>
              <icon-container
                v-if="props.rowData.action === 'completed'"
                name="check"
                view-box="0 0 512 512"
                :is-icon-class="false"
              >
                <icon-check />
              </icon-container>
            </div>
          </template>
          <template
            slot="duration-slot"
            slot-scope="props"
          >
            <div class="text-center">
              <span>{{ props.rowData.duration }}</span>
            </div>
          </template>
          <template
            slot="device-slot"
            slot-scope="props"
          >
            <div class="text-center">
              <icon-container
                v-if="props.rowData.device === 'mobile'"
                name="mobile"
                view-box="0 0 320 512"
                :is-icon-class="false"
              >
                <icon-mobile />
              </icon-container>
              <icon-container
                v-if="props.rowData.device === 'desktop'"
                name="desktop"
                view-box="0 0 576 512"
                :is-icon-class="false"
              >
                <icon-desktop />
              </icon-container>
            </div>
          </template>
          <template
            slot="channel-slot"
            slot-scope="props"
          >
            <div class="text-center">
              <icon-container
                v-if="props.rowData.channel === 'notification'"
                name="mobile"
                view-box="0 0 320 512"
                :is-icon-class="false"
              >
                <icon-mobile />
              </icon-container>
              <icon-container
                v-else-if="props.rowData.channel === 'email'"
                name="envelope"
                view-box="0 0 512 512"
                :is-icon-class="false"
              >
                <icon-envelope />
              </icon-container>
              <icon-container
                v-else-if="props.rowData.channel === 'user'"
                name="user"
                view-box="0 0 12 14"
                :is-icon-class="false"
              >
                <icon-user />
              </icon-container>
            </div>
          </template>
        </vue-table>
        <!--region Pagination-->
        <div class="pagination__info">
          <vuetable-pagination-info
            :class="{ 'table-fade': tableIsLoading }"
            ref="paginationInfo"
            :info-template="$t('pagination_info')"
            :no-data-template="$t('no_data_info')"
          />
          <div class="pagination__items">
            <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage"
            />
          </div>
        </div>
        <!--endregion-->
      </div>
    </main>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import Vuetable from 'vuetable-2';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import API from '@/services/';
import { API_REPORTS_JOURNEY_USER_ACTIVITY } from '@/helpers/config';
import IconContainer from '@/components/elements/Icon.vue';
import AppHeader from '@/components/AppHeader.vue';
import IconReset from '@/components/icons/Reset.vue';
import IconSearch from '@/components/icons/Search.vue';
import IconDown from '@/components/icons/Down.vue';
// import IconInfoCircle from '@/components/icons/InfoCircle.vue';
// import IconArrowUp from '@/components/icons/ArrowUp.vue';
// import IconArrowDown from '@/components/icons/ArrowDown.vue';
// import IconArrowHorizontal from '@/components/icons/ArrowHorizontal.vue';
import IconCheck from '@/components/icons/Check.vue';
import IconUser from '@/components/icons/User.vue';
import IconEye from '@/components/icons/Eye.vue';
import IconEnvelope from '@/components/icons/Envelope.vue';
import IconDesktop from '@/components/icons/Desktop.vue';
import IconMobile from '@/components/icons/Mobile.vue';
import VuetablePagination from '../../components/CustomVuetable/VuetablePagination.vue';
import ReportJourneyUserJourneys from './ReportJourneyUserJourneys.vue';

export default {
  name: 'DashboardUserDetail',
  components: {
    'app-header': AppHeader,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    'icon-container': IconContainer,
    'icon-reset': IconReset,
    'icon-search': IconSearch,
    'icon-down': IconDown,
    // 'icon-infocircle': IconInfoCircle,
    // 'icon-arrowup': IconArrowUp,
    // 'icon-arrowdown': IconArrowDown,
    // 'icon-arrowhorizontal': IconArrowHorizontal,
    ReportJourneyUserJourneys,
    'icon-eye': IconEye,
    'icon-desktop': IconDesktop,
    'icon-mobile': IconMobile,
    'icon-user': IconUser,
    'icon-envelope': IconEnvelope,
    'icon-check': IconCheck,
  },
  data() {
    return {
      isDropdown: null,
      tableIsLoading: false,
      totalDataCount: 0,
      orderDirection: null,
      orderField: null,
      filterText: '',
      showFilters: false,
      searchFor: null,
      perPage: 10,
      dropdownIndex: null,
      pagination: {
        total: null,
        per_page: 10,
        current_page: 1,
        last_page: 1,
        next_page_url: '?per_page=10&page=2',
        prev_page_url: null,
        from: 1,
        to: 10,
      },
      data: [],
      css: {
        table: {
          tableWrapper: 'vuetable-wrappered',
          tableHeaderClass: 'mb-0',
          tableBodyClass: 'mb-0',
          tableClass: '',
          loadingClass: 'loading',
          ascendingIcon: 'fa fa-angle-up',
          descendingIcon: 'fa fa-angle-down',
          ascendingClass: 'sorted-asc',
          descendingClass: 'sorted-desc',
          sortableIcon: '',
          detailRowClass: 'vuetable-detail-row',
          handleIcon: 'fa-bars text-secondary',
          // eslint-disable-next-line no-shadow
          renderIcon(classes, options) {
            return `<i class="${classes.join(' ')}" ${options}></span>`;
          },
        },
        pagination: {
          wrapperClass: 'page-items-list',
          activeClass: 'active',
          disabledClass: 'disabled',
          pageClass: 'page-item',
          linkClass: 'page-link',
          paginationClass: 'pagination',
          paginationInfoClass: 'float-left',
          dropdownClass: 'form-control',
          icons: {
            first: 'fa fa-angle-double-left',
            prev: 'fa fa-angle-left',
            next: 'fa fa-angle-right',
            last: 'fa fa-angle-double-right',
          },
        },
      },
      // region Completion Rate
      completionRate: 0,
      completionRateIncreasing: 0,
      completionRateDisplay: '',
      completionRateArrow: '',
      completionInterval: false,
      // endregion
      // region Compare To Prev Percents
      completionRateCompareToPrevPercent: 0,
      completionRateCompareToPrevPercentDisplay: 0,
      // endregion
      // region Actionable Analytics
      notificationEnabled: 0,
      mobileDownloadPenetration: 0,
      activeUsers: 0,
      // endregion
    };
  },
  mounted() {
    // this.getUserWeeklyReport();

    console.log('**********.....qerusys data:', this.$route.query);
  },
  activated() {
    // this.getUserWeeklyReport();
    this.$refs.vuetable.refresh();
  },
  watch: {
    // region Completion Rate
    completionRate() {
      clearInterval(this.completionInterval);
      if (this.completionRate !== this.completionRateIncreasing) {
        this.completionInterval = window.setInterval(() => {
          if (this.completionRateIncreasing !== this.completionRate) {
            let change = (this.completionRate - this.completionRateIncreasing) / 10;
            change = change >= 0 ? Math.ceil(change) : Math.floor(change);
            this.completionRateIncreasing += change;
          }
        }, 50);
      }
    },
    completionRateIncreasing: {
      handler(val) {
        this.completionRateDisplay = this.increaseNumberPercent(val);
      },
      immediate: true,
    },
    data() {
      this.$refs.vuetable.refresh();
    },
    totalDataCount() {
      this.pagination.total = this.totalDataCount;
    },
    perPage() {
      this.pagination.per_page = this.perPage;
    },
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'DashboardUser') {
        this.$refs.vuetable.refresh();
        this.refreshPage = false;
        this.pageToRefresh = null;
      }
    },
  },
  computed: {
    childTitle() {
      if (this.$store.state.modal.modalData && this.$store.state.modal.modalData.first_name) {
        return `${this.$store.state.modal.modalData.first_name} ${this.$store.state.modal.modalData.last_name}`;
      }
      if (this.$store.state.modal.modalData && this.$store.state.modal.modalData.name) {
        return `${this.$store.state.modal.modalData.name}`;
      }
      return '';
    },
    userLang() {
      if (this.$store.state.auth.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },
    userId() {
      return this.$route.params.userId;
    },
    isVibonsAdmin() {
      if (this.$store.state && this.$store.state.auth) {
        return this.$store.state.auth.isVibonsAdmin;
      }
      return false;
    },
    isCustomerAdmin() {
      if (this.$store?.state?.auth?.userInfo?.role) return this.$store?.state?.auth?.userInfo?.role === 'customer-admin';
      return false;
    },
    dashboardSubNavigation() {
      let submenu = [
        {
          text: this.$t('overview'),
          url: '/dashboard/overview',
          order: 1,
        },
        {
          text: this.$t('journeys'),
          url: '/dashboard/journeys',
          order: 2,
        },
        {
          text: this.$t('users'),
          url: '/dashboard/users',
          order: 4,
        },
        {
          text: this.$t('quiz_survey'),
          url: '/dashboard/quiz-survey',
          order: 5,
        },
        {
          text: this.$t('report_demands'),
          url: '/dashboard/reportdemands',
          order: 7,
        },
      ];
      if (this.isVibonsAdmin) {
        submenu = submenu.concat([
          {
            text: this.$t('journeys_v2'),
            url: '/dashboard/journeys_v2',
            order: 3,
          },
          {
            text: this.$t('customers_sub'),
            url: '/dashboard/customers',
            order: 6,
          },

        ]);
      }
      if (this.isCustomerAdmin && this.$store.state.auth?.userInfo?.customer?.customizations?.journeyv2) {
        submenu = submenu.concat({
          text: this.$t('journeys_v2'),
          url: '/dashboard/journeys_v2',
          order: 3,
        });
      }

      submenu.sort((a, b) => a.order - b.order);
      return submenu;
    },

    formData() {
      const postData = new FormData();
      let orderByColumnName;
      postData.append('length', this.pagination.per_page);
      postData.append('start', this.pagination.from - 1);
      if (!this.orderField) {
        postData.append('order[0][column]', 0);
      }
      if (!this.orderDirection) {
        postData.append('order[0][dir]', 'desc');
      }
      if (this.orderDirection) {
        postData.append('order[0][dir]', this.orderDirection);
      }
      if (this.orderField) {
        if (this.orderField === 'date') {
          orderByColumnName = 0;
        }
        if (this.orderField === 'content_name') {
          orderByColumnName = 1;
        }
        if (this.orderField === 'journey_name') {
          orderByColumnName = 2;
        }
        if (this.orderField === 'duration') {
          orderByColumnName = 5;
        }
        postData.append('order[0][column]', orderByColumnName);
      }
      if (this.searchFor) {
        postData.append('search[value]', this.searchFor);
      }
      return postData;
    },
    pageOptions() {
      return [
        { text: `${this.$t('view')} 10`, value: 10 },
        { text: `${this.$t('view')} 20`, value: 20 },
        { text: `${this.$t('view')} 50`, value: 50 },
        { text: `${this.$t('view')} 100`, value: 100 },
      ];
    },
    fields() {
      return [
        {
          name: 'date',
          title: this.$t('date'),
          width: '13%',
          sortField: 'date',
          titleClass: 'center',
          dataClass: 'center',
        },
        {
          name: 'content_name',
          title: this.$t('content_name'),
          width: '42%',
          sortField: 'content_name',
        },
        {
          name: 'journey_name',
          title: this.$t('journey_name'),
          width: '20%',
          sortField: 'journey_name',
        },
        {
          name: 'type-slot',
          title: this.$t('type'),
          width: '5%',
          titleClass: 'center',
          dataClass: 'center',
        },
        {
          name: 'action-slot',
          title: this.$t('action_'),
          width: '5%',
          titleClass: 'center',
          dataClass: 'center',
        },
        {
          name: 'duration-slot',
          title: this.$t('duration'),
          width: '5%',
          sortField: 'duration',
          titleClass: 'center',
          dataClass: 'center',
        },
        {
          name: 'device-slot',
          title: this.$t('device'),
          width: '5%',
          titleClass: 'center',
          dataClass: 'center',
        },
        {
          name: 'channel-slot',
          title: this.$t('channel'),
          width: '5%',
          titleClass: 'center',
          dataClass: 'center',
        },
      ];
    },
    refreshPage: {
      get() {
        return this.$store.state.modal.refreshPage;
      },
      set(newValue) {
        this.$store.state.modal.refreshPage = newValue;
      },
    },
    pageToRefresh: {
      get() {
        return this.$store.state.modal.pageToRefresh;
      },
      set(newValue) {
        this.$store.state.modal.pageToRefresh = newValue;
      },
    },
  },

  methods: {
    getCurrentLocaleDate(dateVal) {
      if (this.userLang) {
        return this.$helpers.getUserLangBasedDateTimeFormat(dateVal, this.userLang, 'userdetail');
      }
      return dateVal;
    },
    hideDropdown() {
      this.isDropdown = null;
      this.dropdownIndex = null;
    },
    dropdownAction(value) {
      this.isDropdown = value;
    },
    pageOptionsSelected(value) {
      this.perPage = value;
      this.hideDropdown();
    },
    loadData() {
      return new Promise((resolve, reject) => {
        this.isLoading();
        API.post(`${API_REPORTS_JOURNEY_USER_ACTIVITY}/${this.userId}`, this.formData).then((response) => {
          // noinspection JSUnresolvedVariable
          this.totalDataCount = this.searchFor ? response.data.recordsFiltered : response.data.recordsTotal;
          resolve(response.data.data);
        }).catch((error) => {
          this.$helpers.displayError(error);
          reject(error);
        });
      });
    },
    async dataManager(sortOrder, paginate) {
      let pagination = paginate;

      if (sortOrder && sortOrder.length > 0) {
        this.orderDirection = sortOrder[0].direction;
        this.orderField = sortOrder[0].sortField;
      }
      if (pagination) {
        if (pagination.current_page > Math.ceil(this.totalDataCount / pagination.per_page)) {
          this.pagination.current_page = 1;
          this.pagination.from = 1;
          this.pagination.to = this.totalDataCount > (this.pagination.from + this.pagination.per_page - 1)
            ? this.pagination.from + this.pagination.per_page - 1 : this.totalDataCount;
        } else {
          this.pagination.current_page = pagination.current_page;
          this.pagination.from = pagination.from;
          this.pagination.to = this.totalDataCount > (this.pagination.from + this.pagination.per_page - 1)
            ? this.pagination.from + this.pagination.per_page - 1 : this.totalDataCount;
        }
      }


      let data;

      await this.loadData().then((response) => {
        data = response;
        /* eslint-disable no-param-reassign */
        pagination = this.$refs.vuetable.makePagination(
          this.pagination.total,
          this.perPage,
        );
      });
      if (pagination.current_page > pagination.last_page) {
        pagination.current_page = 1;
        pagination.from = 1;
        pagination.to = pagination.total > (pagination.from + pagination.per_page - 1) ? pagination.from + pagination.per_page - 1 : pagination.total;
      }
      this.pagination = pagination;
      this.isLoaded();
      return {
        pagination,
        data,
      };
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.$refs.paginationInfo.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    doFilter() {
      this.searchFor = this.filterText;
      this.$refs.vuetable.refresh();
    },
    resetFilter() {
      this.searchFor = null;
      this.filterText = '';
      this.$refs.vuetable.refresh();
    },
    isLoading() {
      this.tableIsLoading = true;
    },
    isLoaded() {
      this.tableIsLoading = false;
    },
    dropdownToggle(index) {
      this.dropdownIndex = index;
    },
    increaseNumberPercent(val) {
      return this.dash.lowerCase(this.userLang) === 'en' ? `${val}%` : `%${val}`;
    },

    // getUserWeeklyReport() {
    //   return new Promise(async (resolve, reject) => {
    //     await API.get(`${API_REPORTS_WEEKLY_USER}/${this.userId}`).then((response) => {
    //     // region Data
    //       let data;

    //       if (!this.dash.isEmpty(response, true)) {
    //         if (this.dash.has(response, 'data')) {
    //           if (!this.dash.isEmpty(response.data, true)) {
    //           // eslint-disable-next-line prefer-destructuring
    //             data = response.data;

    //             // noinspection JSUnresolvedVariable
    //             if (this.dash.every(data, this.dash.isObject)) {
    //             // region Vars
    //             // region Last
    //               const lastI = this.dash.last(data);

    //               let completionRate = 0;
    //               let notificationEnabled = 0;
    //               let mobileDownloadPenetration = 0;
    //               let activeUsers = 0;
    //               // endregion

    //               // region Last Previous
    //               let lastPrev;
    //               let lastPreviousCompletionRate = 0;

    //               // region Set / Manipulation
    //               // region Last
    //               if (!this.dash.isEmpty(lastI, true)) {
    //               // region Completion Rate
    //                 if (this.dash.has(lastI, 'completion_rate')) {
    //                 // noinspection JSUnresolvedVariable
    //                   completionRate = parseInt(lastI.completion_rate, 10);
    //                 }
    //                 // endregion

    //                 // region Notification Enabled
    //                 if (this.dash.has(lastI, 'has_notification_enabled')) {
    //                 // noinspection JSUnresolvedVariable
    //                   notificationEnabled = parseInt(lastI.has_notification_enabled, 10);
    //                 }
    //                 // endregion

    //                 // region Mobile Download Penetration
    //                 if (this.dash.has(lastI, 'has_mobile_device_registered')) {
    //                 // noinspection JSUnresolvedVariable
    //                   mobileDownloadPenetration = parseInt(lastI.has_mobile_device_registered, 10);
    //                 }
    //                 // endregion

    //                 // region Active Users
    //                 if (this.dash.has(lastI, 'is_active')) {
    //                 // noinspection JSUnresolvedVariable
    //                   activeUsers = parseInt(lastI.is_active, 10);
    //                 }
    //               // endregion
    //               }
    //               // endregion

    //               // region Last Prev
    //               // eslint-disable-next-line prefer-const
    //               const len = this.dash.size(data);

    //               if (len > 1) {
    //                 lastPrev = data[len - 2];
    //               }

    //               if (!this.dash.isEmpty(lastPrev, true)) {
    //               // region Completion Rate
    //                 if (this.dash.has(lastPrev, 'completion_rate')) {
    //                 // noinspection JSUnresolvedVariable
    //                   lastPreviousCompletionRate = parseInt(lastPrev.completion_rate, 10);
    //                 }
    //               // endregion
    //               }

    //               let weekSlugList = this.dash.map(data, 'week_slug');
    //               if (this.userLang && weekSlugList) {
    //                 weekSlugList = weekSlugList.map(date => this.$helpers.getUserLangBasedDateTimeFormat(date, this.userLang, 'weekslug'));
    //               }

    //               this.notificationEnabled = notificationEnabled;
    //               this.mobileDownloadPenetration = mobileDownloadPenetration;
    //               this.activeUsers = activeUsers;
    //               // endregion

    //               // region Completion Rates
    //               this.completionRate = completionRate;
    //               // endregion

    //               // region Completion Rate Compare to Prev Percent
    //               const a2b10 = (completionRate - lastPreviousCompletionRate) / completionRate;
    //               this.completionRateCompareToPrevPercent = Number.isNaN(a2b10) ? 0 : parseInt(a2b10 * 100, 10);
    //               // endregion

    //               // region Completion Rate Arrow
    //               if (completionRate !== lastPreviousCompletionRate) {
    //                 if (completionRate > lastPreviousCompletionRate) {
    //                   this.completionRateArrow = 'arrow-up';
    //                 } else if (completionRate < lastPreviousCompletionRate) {
    //                   this.completionRateArrow = 'arrow-down';
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       }
    //       resolve();
    //     // endregion
    //     }).catch((error) => {
    //       this.$helpers.displayError(error);
    //       return reject(error);
    //     });
    //   });
    // },
  },
  directives: {
    'on-clickaway':
    onClickaway,
  },
};
// endregion
</script>

<!--suppress HtmlUnknownAttribute, CssInvalidPseudoSelector-->
<style lang="scss" scoped>
  .chart {
    height: 240px;
  }

  /*#region Top Box Right*/
  #dboard-top-box-right .dboard-bg-white {
    background-size: 86px 59px !important;
  }

  #dboard-top-box-right .dboard-bg-white .dboard-top-box-inside-inner .dboard-top-row {
    width: 100%;
    display: table-row;
    height: 33%;
  }

  #dboard-top-box-right .dboard-bg-white .dboard-top-box-inside-inner .dboard-top-row .col-9,
  #dboard-top-box-right .dboard-bg-white .dboard-top-box-inside-inner .dboard-top-row .col-3 {
    vertical-align: middle;
    display: table-cell;
    padding: 0;
    margin: 0;
  }

  #dboard-top-box-right .dboard-bg-white .dboard-top-box-inside-inner .dboard-top-row::after {
    content: none;
  }

  #dboard-top-box-right .dboard-bg-white .dboard-top-box-inside-inner .dboard-top-row:nth-child(2)::after,
  #dboard-top-box-right .dboard-bg-white .dboard-top-box-inside-inner .dboard-top-row:nth-child(3)::after {
    display: inline-list-item;
    opacity: 0.2;
    background-color: #B9BEC9;
    content: '';
    height: 1px;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0);
    width: calc(100% - 50px) !important;
    margin-top: -6px;
  }

  #dboard-top-box-right .dboard-bg-white .dboard-top-box-inside-inner .dboard-top-row .col-3 p {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
  }

  #dboard-top-box-right .dboard-bg-white .dboard-top-box-inside-inner .dboard-top-row .col-3 p.yes {
    opacity: 0.5;
    color: #A86CFC;
    letter-spacing: 0;
  }

  #dboard-top-box-right .dboard-bg-white .dboard-top-box-inside-inner .dboard-top-row .col-3 p.no {
    opacity: 0.5;
    color: #EA0505;
    letter-spacing: 0;
  }

  #dboard-top-box-right div.col.dboard-bg-white div.dboard-top-box-inside.dboard-top-box-inside-inner {
    position: relative;
    margin-top: -15px !important;
  }

  /*#endregion*/

  /*#region Search/Filter */
  .button-group {
    margin-top: 50px;
    margin-bottom: 25px;
  }

  button.ui.button {
    padding: 8px 3px 8px 10px;
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .dropdown__menu.dropdown__menu--filter {
    background: transparent;
    border-radius: 0;
    box-shadow: none;
    border: 0;
    margin-left: -1px;
    margin-top: 0;
  }

  .filter-bar {
    display: inline-block;
    vertical-align: middle;
  }

  .filterform {
    display: inline-block;
  }

  .filtericon {
    font-size: 1.5rem;
    vertical-align: middle;
    margin: 2px;
    cursor: pointer;
    display: inline-block;
    border-radius: 2px;
    background-color: #F7F8FC;
    border: 0;
    justify-content: center;
    height: 30px;
    color: rgba(60, 61, 63, 0.952);
    box-shadow: 0 0.25rem 0.125rem 0 rgba(0, 0, 0, 0.05);

    svg {
      margin: 2px;
    }
  }

  .filter-box {
    display: inline-block;
  }

  .inputbox {
    border-radius: 2px;
    background-color: #F7F8FC;
    border: 0;
    height: 30px;
    color: rgba(60, 61, 63, 0.952);
    box-shadow: 0 0.25rem 0.125rem 0 rgba(0, 0, 0, 0.05);
  }

  .selectbox {
    border-radius: 2px;
    background-color: #F7F8FC;
    border: 0;
    height: 30px;
    color: rgba(60, 61, 63, 0.952);
    box-shadow: 0 0.25rem 0.125rem 0 rgba(0, 0, 0, 0.05);
  }

  .select-perpage {
    vertical-align: middle;
    display: inline-block;
  }

  /*#endregion*/

  /*#region Vue Table*/
  .vuetable-wrapper {
    width: 80%;
    margin: 0 auto;
    position: relative;
    opacity: 1;
  }

  .vuetable-wrapper .enter {
    width: 0
  }

  .vuetable-wrapper .enter-to {
    width: 100%
  }

  .vuetable-wrapper .slide-enter-active {
    position: absolute
  }

  .vuetable-wrapper .leave {
    width: 100%
  }

  .vuetable-wrapper .leave-to {
    width: 0
  }

  .vuetable-wrapper .slide-enter-active,
  .vuetable-wrapper .slide-leave-active {
    transition: all 50ms ease-in-out
  }

  /*#region Thead Th*/
  table.vuetable.table.table-bordered.table-hover.mb-0 thead tr th {
    text-align: center;
  }

  table.vuetable.table.table-bordered.table-hover.mb-0 thead tr th:nth-child(1),
  table.vuetable.table.table-bordered.table-hover.mb-0 thead tr th:nth-child(2) {
    text-align: left;
  }

  /*#endregion*/
  /*#endregion*/

  /*#region Pagination*/
  .pagination__items {
    width: 100%;
    text-align: center;
  }

  /*#endregion*/

  .blur {
    filter: blur(3px);
  }

  ::v-deep .center {
    text-align: center;
    justify-content: center;
  }

::v-deep .vuetable {
    thead {
      tr {
        th {
          font-weight: bold;
          &:last-child {
            text-align: center;
          }
        }
      }
    }
    tbody {
      tr {
        height: 60px;
        td {
          span {
            display: flex;
            align-items: center;
          }
          .button {
            &.ui {
              padding: 0;
              margin: 0;
              width: 20px;
              height: 20px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              margin-right: 0.5rem;
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
  /*#region Top Box Media Layout Sizing*/
  @media (max-width: 1256px) {
    div#app div.page.dashboard {
      #dboard-top-box-left {
        margin-bottom: 30px !important;
      }
      #dboard-top-box-right {
        margin-bottom: 30px !important;
      }

      #dboard-top-box-center {
        margin-right: 30px !important;
      }
    }
  }

  @media (max-width: 741px) {
    div#app div.page.dashboard {
      #dboard-top-box-right {
        margin-top: 30px !important;
        margin-left: 0 !important;
      }

      #dboard-top-box-center {
        margin-left: 30px !important;
        margin-top: 30px !important;
        margin-right: 0 !important;
      }
    }
  }

  @media (max-width: 634px) {
    div#app div.page.dashboard {
      #dboard-top-box-center {
        width: calc(100% - 60px) !important;
        max-width: calc(100% - 60px) !important;
        flex: 0 0 calc(100% - 60px) !important;
      }
    }

    main.page__body {
      div.vuetable-wrapper:nth-child(1) {
        width: unset !important;
        margin-left: 25px !important;
        margin-right: 25px !important;
      }

      div.vuetable-wrappered {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
      }
    }
  }

  /*#endregion*/

  /*#region Dashboard*/
  div#app div.page.dashboard * {
    outline: 0;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /*#endregion*/

  div#app div.page.dashboard {
    /*#region Generic*/
    :focus {
      outline: 0
    }

    .chart {
      margin: 0 auto;
    }

    .dboard-container-full {
      margin: 0 auto;
      width: 100%;
    }

    .dboard-bg-white {
      background-color: #FFF !important;
      border-radius: 8px;
    }

    .dboard-box-vertical {
      display: table-cell;
      vertical-align: top;
    }

    .dboard-top-row {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    /*#endregion*/
    /*#region Progress Bar*/
    .vue-simple-progress,
    .vue-simple-progress-bar {
      border-radius: 5px !important;
    }

    /*#endregion*/
    /*#region Highcharts Credits*/
    svg.highcharts-root text.highcharts-credits {
      display: none !important;
      visibility: hidden !important;
    }

    /*#endregion*/
    /*#region Top Box Row*/
    .dboard-top-row h5 {
      font-size: 16px;
      color: #727D92;
      text-align: center;
      font-weight: bold;
      margin-bottom: 25px;
      opacity: 0.5
    }

    .dboard-top-row .col-9 h6 {
      opacity: 0.5;
      font-size: 14px;
      font-weight: bold;
      color: #394C71;
      letter-spacing: 0;
      text-align: left;
    }

    .dboard-top-row .col-9 h6 .svg-inline--fa {
      height: 13px;
      width: 13px;
    }

    .dboard-top-row .col, .dboard-top-row .col-9 {
      height: auto;
    }

    /*#endregion*/
    /*#region Top Box*/
    .dboard-top-box {
      padding: 0 25px 0 25px !important;
      display: flex;
    }

    .dboard-top-box-inside {
      padding: 25px;
    }

    .dboard-top-box-inside-inner {
      display: table;
      height: 100%;
      width: 100%;
      text-align: center;
    }

    /*#endregion*/
    /*#region Top Box Left*/
    // noinspection CssUnknownTarget
    #dboard-top-box-left .dboard-bg-white {
      background: url(~@/assets/img/dashboard-top-bg-02.png) right 25px top 25px no-repeat,
      url(~@/assets/img/dashboard-top-bg-01.png) left bottom no-repeat;
      padding: 25px;
    }

    #dboard-top-box-left .dboard-bg-white p {
      width: 100%;
      text-align: center;
      font-size: 14px;
      color: #394C71;
      letter-spacing: 0;
      opacity: 0.5;
    }

    /*#endregion*/
    /*#region Top Box Right*/
    // noinspection CssUnknownTarget
    #dboard-top-box-right .dboard-bg-white {
      background: url(~@/assets/img/dashboard-top-bg-03.png) right top 25px no-repeat;
      padding: 25px;
    }

    /*#endregion*/
    /*#region Info Icon*/
    .info-icon-left .svg-inline--fa,
    .info-icon-inside .svg-inline--fa {
      color: #727D92!important;
    }

    .info-icon-left .svg-inline--fa {
      position: absolute;
      top: 15px;
      left: 15px;
      height: 19px;
      width: 19px;
    }

    /*#endregion*/
    /*#region Percent*/
    .percent {
      font-size: 80px !important;
      color: #394C71 !important;
      font-weight: bold;
      opacity: unset !important;
    }

    .percent .svg-inline--fa {
      position: absolute;
      top: -50%;
      height: 32px;
      width: 32px;
    }

    .percent .svg-inline--fa.fa-arrow-up {
      color: #2DCF85;
    }

    .percent .svg-inline--fa.fa-arrow-down {
      color: #EA0505;
    }

    /*#endregion*/
  }
::v-deep .center, .vuetable-slot.center  {
  text-align: center;
}

// Arrow Animation
@keyframes bouncearrowup {
  0% { top: 0; } 50% { top: -20px; } 100% { top: 0; }
}

// Arrow
#arrowup {
  position: relative;
  margin: 0 auto;
  text-align: center;
  animation: bouncearrowup 3s infinite;
    svg {
      transition: .3s ease-in-out;
  }
}

// Arrow Animation
@keyframes bouncearrowdown {
  0% { top: 0; } 50% { top: 20px; } 100% { top: 0; }
}

// Arrow
#arrowdown {
  position: relative;
  margin: 0 auto;
  text-align: center;
  animation: bouncearrowdown 3s infinite;
    svg {
      transition: .3s ease-in-out;
  }
}
// Arrow Animation
@keyframes bouncedash {
  0% { left: 0; } 50% { left: 20px; } 100% { left: 0; }
}

// Arrow
#dash {
  position: relative;
  margin: 0 auto;
  text-align: center;
  color: #727D92;
  animation: bouncedash 3s infinite;
    svg {
      transition: .3s ease-in-out;
  }
}
.icon-item {
  width: 20px;
  margin: 0 auto;
}
.sub-title {
  font-size: 16px;
  line-height: 18px;
  color: #142b58;
  padding-left: 35px;
}
</style>
