<template>
  <div
    class="modal-container"
  >
    <div
      class="modal"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ $t('nps_score') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal()"
              v-html="'&times;'"
            />
          </header>
          <div class="modal-body">
            <div class="form form--horizontal form--strong">
              <div class="form form--horizontal">
                <div class="d-flex flex-row justify-content-between align-items-center w-100">
                  <figure class="highcharts-figure w-auto">
                    <highcharts
                      class="chart-container"
                      :options="npsChartOptions"
                      ref="completionGauge"
                    />
                  </figure>
                  <div class="d-flex flex-column w-100">
                    <div class="d-flex flex-row w-100 mb-1">
                      <div style="color: #2F373E; background-color:#FFACC1; border-radius:10px; padding:2px 8px; margin-right:10px;font-size:14px;font-weight: bold; width:55%">
                        {{ $t('detractors') }}
                      </div>
                      <div
                        style="color: #fff;background-color:#E63D68; border-radius:50%; min-width:26px;text-align: center; margin-right:10px;font-size:14px; font-weight: bold"
                        class="d-flex justify-content-center align-items-center"
                      >
                        {{ detractors_total }}
                      </div>
                      <div style="color: #2F373E; background-color:#FFACC1; border-radius:10px; padding:2px 8px;font-weight: bold;font-size:14px;width:58px;">
                        {{ (((detractors_total / total) || 0) * 100).toFixed(1) + '%' }}
                      </div>
                    </div>
                    <div class="d-flex flex-row w-100 mb-1">
                      <div style="color: #2F373E; background-color:#FFE1A0; border-radius:10px; padding:2px 8px; margin-right:10px;font-size:14px;font-weight: bold; width:55%">
                        {{ $t('passives') }}
                      </div>
                      <div
                        style="color: #fff;background-color:#F8BA04; border-radius:50%; min-width:26px;text-align: center; font-size:14px;margin-right:10px; font-weight: bold;"
                        class="d-flex justify-content-center align-items-center"
                      >
                        {{ passives_total }}
                      </div>
                      <div style="color: #2F373E; background-color:#FFE1A0; border-radius:10px; padding:2px 8px;font-weight: bold;font-size:14px;width:58px;">
                        {{ (((passives_total / total)|| 0) * 100).toFixed(1) + '%' }}
                      </div>
                    </div>
                    <div class="d-flex flex-row w-100 mb-1">
                      <div style="color: #2F373E; background-color:#87DBC1; border-radius:10px; padding:2px 8px; margin-right:10px;font-size:14px;font-weight: bold; width:55%">
                        {{ $t('promoters') }}
                      </div>
                      <div
                        style="color: #fff;background-color:#3DB785; border-radius:50%;min-width:26px;text-align: center; font-size:14px;margin-right:10px; font-weight: bold;"
                        class="d-flex justify-content-center align-items-center"
                      >
                        {{ promoters_total }}
                      </div>
                      <div style="color: #2F373E; background-color:#87DBC1; border-radius:10px; padding:2px 8px;font-weight: bold;font-size:14px;width:58px;">
                        {{ (((promoters_total / total) || 0) * 100).toFixed(1) + '%' }}
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-column w-auto ml-2 justify-content-center align-items-center">
                    <div style="color: #fff; background-color:#2F373E; border-radius:10px; padding:2px 8px; margin-right:10px;font-size:14px;font-weight: bold;margin-bottom:2px">
                      {{ $t('total') }}
                    </div>
                    <div
                      style="color: #2F373E;background-color:#9B9B9B; border-radius:50%; height: 50px;width:50px;text-align: center; padding-top: 2px; margin-right:10px;font-size:24px; font-weight: bold;"
                      class="d-flex justify-content-center align-items-center"
                    >
                      {{ total }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="button button--primary"
                  @click="closeModal()"
                >
                  <span>
                    {{ $t('close') }}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Highcharts from 'highcharts';
import API from '@/services/';
import {
  API_SHOW_NPS_SCORE,
  API_SHOW_V2_NPS_SCORE,
} from '@/helpers/config';

export default {
  name: 'ModalNpsScore',
  inject: ['$validator'],

  data() {
    return {
      nps: 0,
      total: 0,
      promoters_total: 0,
      detractors_total: 0,
      passives_total: 0,
    };
  },

  watch: {
  },

  computed: {
    modalData() {
      return this.$store.state.modal.modalData;
    },
    npsChartOptions() {
      return {
        title: {
          text: `${this.nps || 0}`,
          align: 'center',
          verticalAlign: 'middle',
          style: {
            fontSize: '40px',
            color: 'black',
          },
        },
        credits: {
          enabled: false,
        },
        series: [{
          type: 'pie',
          name: '',
          data: [{
            name: this.$t('detractors'),
            y: this.detractors_total,
            color: '#E63D68',
          }, {
            name: this.$t('promoters'),
            y: this.promoters_total,
            color: '#3DB785',
          }, {
            name: this.$t('passives'),
            y: this.passives_total,
            color: '#F8BA04',
          }],
          size: 120,
          innerSize: '80%',
          showInLegend: false,
          dataLabels: {
            enabled: false,
          },
        }],
      };
    },
  },
  created() {
    this.getNpsScore();
  },
  methods: {
    closeModal() {
      this.$store.commit('modal/toggleModal');
    },
    async getNpsScore() {
      this.loading = true;
      let response = null;
      try {
        if (this.modalData?.journey2_content_id) {
          response = await API.get(`${API_SHOW_V2_NPS_SCORE}?as_journey2_content_id=${this.modalData?.journey2_content_id}`);
        } else if (this.modalData?.content_id && this.modalData?.journey_id) {
          response = await API.get(`${API_SHOW_NPS_SCORE}?journey_id=${this.modalData?.journey_id}&content_id=${this.modalData?.content_id}`);
        }

        if (response) {
          this.nps = Number(response?.data?.data?.nps) || 0;
          this.total = Number(response?.data?.data?.total) || 0;
          this.detractors_total = Number(response?.data?.data?.detractors_total) || 0;
          this.promoters_total = Number(response?.data?.data?.promoters_total) || 0;
          this.passives_total = this.total - (this.detractors_total + this.promoters_total) || 0;
          console.log('response:', response.data.data);
        }
      } catch (error) {
        this.$helpers.displayError(error);
      } finally {
        this.loading = false;
      }
    },

  },
};
</script>
<style lang="scss" scoped>
  .highcharts-figure .chart-container {
    width: 180px;
    height: 180px;
    margin: 0 auto;
}
</style>
