<template>
  <div
    class="modal-container"
  >
    <div
      class="modal"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ journeyName + '-' + $t('user_list') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>
          <nav
            class="navigation navigation--secondary"
          >
            <div class="container">
              <ul
                class="navigation__list nav"
                id="contentCreateTab"
                role="tablist"
              >
                <li
                  class="navigation__item"
                >
                  <button
                    class="navigation__link"
                    :class="{ 'active': tabPane === 'assigned_user'}"
                    @click="tabNavigation('assigned_user')"
                  >
                    {{ $t('assigned_user') }}
                  </button>
                </li>
                <li
                  class="navigation__item"
                >
                  <button
                    class="navigation__link"
                    :class="{ 'active': tabPane === 'plan_user_assign'}"
                    @click="tabNavigation('plan_user_assign')"
                  >
                    {{ $t('plan_user_assign') }}
                  </button>
                </li>
              </ul>
            </div>
          </nav>
          <div class="modal-body">
            <div
              class="tab-content"
              v-if="journeyV2Id"
            >
              <div
                class="tab-pane"
                v-show="tabPane === 'assigned_user'"
              >
                <div
                  class="form-group"
                  v-if="tabPane === 'assigned_user'"
                >
                  <journey-users-v2
                    :journey-id="journeyV2Id"
                    :tab-pane="tabPane"
                    @closeModal="closeModal"
                  />
                </div>
              </div>
              <div
                class="tab-pane"
                v-show="tabPane === 'plan_user_assign'"
              >
                <div
                  class="form-group"
                  v-if="tabPane === 'plan_user_assign'"
                >
                  <journey-users-v2
                    :journey-id="journeyV2Id"
                    :tab-pane="tabPane"
                    @closeModal="closeModal"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="button button--primary"
                @click="closeModal"
              >
                <span>
                  {{ $t('close') }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JourneyUsersV2Template from '@/container/JourneyUsersV2.vue';

export default {
  name: 'ModalJourneyItemUsersV2',

  data() {
    return {
      tabPane: 'assigned_user',
    };
  },

  components: {
    'journey-users-v2': JourneyUsersV2Template,
  },

  computed: {
    journeyName: {
      get() {
        return this.$store.state.modal.journeyName;
      },
      set(newValue) {
        this.$store.state.modal.journeyName = newValue;
      },
    },
    journeyV2Id: {
      get() {
        return this.$store.state.modal.journeyDetail.id;
      },
      set(val) {
        this.$store.state.modal.journeyDetail.id = val;
      },
    },
  },

  methods: {
    tabNavigation(value) {
      this.tabPane = value;
    },
    closeModal() {
      this.$store.commit('modal/toggleModal');
      this.$store.commit('modal/REFRESH_PAGE', true);
    },
  },
};
</script>
<style scoped>
.modal .modal-body {
    padding: 15px 15px 30px;
    min-width: 650px;
    width: 80vw;
    max-width: 980px !important;
}
.modal .modal-content {
    width: 100%;
    max-width: 980px !important;
}
.modal .tab-content {
  width: 100%;
  max-width: 950px;
}
</style>
